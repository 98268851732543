program PSistemaswjcWEB;

uses
  Vcl.Forms,
  WEBLib.Forms,
  UBase in 'UBase.pas'{*.html},
  UPrincipal in 'UPrincipal.pas'{*.html},
  UUtilesWEB in '..\..\..\UUtilesWEB.pas',
  UEstaSeguro in 'UEstaSeguro.pas'{*.html},
  UModuloDatos in 'UModuloDatos.pas';

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TForm1, Form1);
  Application.CreateForm(TEstaSeguro, EstaSeguro);
  Application.CreateFormDirect(TModuloDatos, ModuloDatos);
  Application.Run;
end.
