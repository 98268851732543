unit UUtilesWEB;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Modules, WEBLib.json, WEBLib.cds,
  WEBLib.Dialogs, WEBLib.StdCtrls, db, WEBLib.REST, weblib.graphics, WEBLib.ExtCtrls,
  WEBLib.DB, WEBLib.DBCtrls, WEBLib.Controls, WEBLib.Forms;
type

          TGraphType = (gtBitmap, gtIcon, gtMetafile, gtJpeg, gtpng);

          function GetTipoField(TipoJSON:String):TFieldType;
          procedure GetFieldTypeInfo(Field: TField; var FieldType, JsonTyp: string);
          function FieldsToJSON(AFields: TFields; const ADateAsString: boolean = True): string;

          function CreoField(Dataset: TClientDataSet; Indice:Integer; Tipo:TFieldtype):TField;
          function CreoFieldenDataset(Dataset: TClientDataSet; Nombre:String; Tipo:TFieldtype; Tamano:integer; Requerido:boolean=False):TField;

          procedure JsonToDataset(Dataset:TClientDataSet; JSon:string);

          function DataSetToJSON(DataSet: TDataSet; ReturnFields: boolean = True; ReturnData: boolean = True; ReturnOK: Boolean = True): string; overload;
          function DataSetToJSON(DataSet: TDataSet; ReturnFields: boolean = True; Version : integer = 1): string; overload;


          procedure CopioRegistro(Datasetorigen, Datasetdestino: TClientDataSet); overload;
          procedure CopioRegistro2022(Datasetorigen, Datasetdestino: TClientDataSet; CrearCampos : boolean=false); overload;


          procedure CopioTabla2022(TablaOrigen, TablaDestino: TClientDataSet; CrearCampos: Boolean=False);  overload;
          procedure CopioTabla(TablaOrigen, TablaDestino: TClientDataSet); overload;

          procedure CopioEstructuraDataset(Fuente,Destino:TClientDataset; doAdd:Boolean);


          function LeerVista(CVista: TClientDataset; SServidor, SServidorVista: string; DataSource:TDataSource):boolean;

          FUNCTION EsEmailValido(CONST Value: String): boolean;
          function ContarPalabras(Cadena:String):integer;

          function ExtraigoPalabra(cadena: string; posicionpalabra: Integer): string; overload;
          function ExtraigoPalabra(cadena: string; posicionpalabra: Integer; Delimitador:char): string; overload;

          function Zipcadena(Cadena:string):string;
          function UnZipCadena(Cadena:string):string;

          function StringToBase64(Cadena:string):string;
          function Base64toString(Cadena:string):string;

          function PackZip(Cadena:string) : string;
          function UnPackZip(Cadena:string) : string;

          function BlobtoBase64(Blob: TBlobField): string;
          procedure Base64toBlob(Base64: string; Blob: TBlobField);


          function Base64ToArrayBuffer(str: string): TJSArrayBuffer;
          function ArrayBuffertoBase64(Blob:TJSArrayBuffer):string;

          function StringtoArraybuffer(Cadena:string):TJSArrayBuffer;
          function ArrayBufferToString(Blob:TJsArrayBuffer):string;

          function GetClienteFromRequest(Cliente:TClientDataset; StringEntrada : string) : boolean;

          function PackString(Cadena:string) : string;
          function UnPackString(Cadena:string) : string;


          function StringtoJSON(Cadena:String):String;
          function StringtoClientDataSet(Cadena:String):TClientDataset;

          function RellenoString(cadena: string; longitudtotal: Integer; caracterrelleno: char; rellenoizquierdo: boolean): string;

          procedure DibujarLetraImagen(Imagen:TImageControl; Letra:char);

          function ConvertFechaFirebird(Fecha:TDate):string;
          function ConvertHoraFirebird(Hora:TTime):string;


          function EsVersion(Cadena:String):boolean;
          function GetVersionMensaje(Cadena:String):string;
          function GetNumeroVersionMensaje(Cadena:String):integer;
          function SetVersion(Numero:integer; Paquete: String):String;

          function PackJWT(JWT, IDS, Data:String):string;

          function GetClienteFromRequestMVC(Cliente:TClientDataSet; StringEntrada : string) : boolean;
          function GetClienteFromRequestMVCPromise(Cliente:TClientDataSet; StringEntrada : string) : TJSPromise;


          function GetDatosdeMensaje(Cadena:String):String;

          function GetJWT(Cadena:String):String;
          function GetDATA(Cadena:String):String;
          function GetIDS(Cadena:String):String;
          function GetDatoJSON(Dato,Cadena:String):String;

          function PackMensaje(JWT, IDS, IDBD, IDE, Data:String):string; overload;
          function PackMensaje(JWT, IDS, IDBD, IDE, IDEntidad, Data:String):string; overload;
          procedure CopioAnsiStringToStream(Const Astring : String; Stream:TMemoryStream);

          function StringToStream(const AString: string): TStream;
          function StreamToString(Stream: TStream): string;


          function AgregoParValue(Objeto:TJSONObject; Nombre:string; Valor:String):boolean; overload;
          function AgregoParValue(Objeto:TJSONObject; Nombre:string; Valor:Integer):boolean; overload;
          function AgregoParValue(Objeto:TJSONObject; Nombre:string; Valor:Double):boolean; overload;
          function AgregoParValue(Objeto:TJSONObject; Nombre:string; Valor:Boolean):boolean; overload;

          function EditCD(CD:TClientDataSet):boolean;

          function GetClientDatasetUnicos(Fuente:TClientDataSet; CampoClave:string):TClientDataSet;

          function GetFechayyymmdd(Fecha:TDate):string;

          function UbicarClave(Grilla:TDBTableControl; CD:TClientDataSet; ACol,ARow:integer; Campo:String):boolean;

          function SumoCampo(DataSet:TClientDataSet; Campo, Filtro : String ) : Double;
          function ValidateInputType(inputType: string; Achar:char):boolean;

          function ProcesarTXTFloat(TxtFloat:String):string;

          procedure  FormatearCamposFloat(Dataset:TClientDataSet);
          function GetControl(Parent:TForm;  Nombre:string): TControl;

//          function ReemplazoString(Texto, Old, New:String; Flags: TReplaceFlags) : string;

{          procedure ConfigurarConexion(Conexion: TWebHttpRequest; Nivel: integer; Metodo, Data: string);
          procedure Conectar(Conexion: TWebHttpRequest; Nivel: integer; Metodo, Data: string);
}

 const
  JSON_NULL    = 'null';
  JSON_STRING  = 'string';
  JSON_BOOLEAN = 'boolean';
  JSON_DATE    = 'date';
  JSON_FLOAT   = 'float';
  JSON_INT     = 'int';
  JSON_LARGEINT= 'largeint';
  JSON_TIME    = 'time';
  JSON_DATETIME    = 'datetime';
  JSON_BLOB    = 'blob';

  cstFieldType    = 'FieldType'; //< label of json tag FieldType.
  cstFieldName    = 'FieldName'; //< label of json tag FieldName.
  cstDisplayLabel = 'DisplayLabel'; //< label of json tag Displaylabel.
  cstFieldSize    = 'FieldSize'; //< label of json tag FieldSize.
  cstJsonType     = 'JsonType'; //< label of json tag JsonType.
  cstRequired     = 'Required'; //< label of json tag Required.
  cstFieldIndex   = 'FieldIndex'; //< label of json tag FieldIndex.

var
  cstCols: string = 'Fields'; //< label of json tag Fields.
  cstData: string = 'Data'; //< label of json tag Data.
  a : TJSEventRecord;

{  Host:string;
  Puerto:string;
  HTTP:String;
}
    ModuloD: TDataModule;


implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}


function GetClienteFromRequest(Cliente: TClientDataset;  StringEntrada: string): boolean;
var
  SDatos:string;
  Cabecera:string;
  CadUnzip:String;

begin
       result:=False;
       Cabecera:=Copy(StringEntrada,1,10);
       SDatos:=copy(StringEntrada,6,length(StringEntrada)-5);
       if Cabecera='{"Fields":' then
        begin
             SDatos:=StringEntrada;
             if Cliente.Active then Cliente.EmptyDataSet;
              try
                  JSONToDataset(Cliente,SDatos);
                  Result:=True;
              finally

              end;
        end
        else
        begin
             Cabecera:=Copy(StringEntrada,1,5);
             SDatos:=copy(StringEntrada,6,length(StringEntrada)-5);
             if Cliente.Active then Cliente.EmptyDataSet;

             if cabecera='VER00' then /// Es texto plano
                  begin
                        try
                            JSONToDataset(Cliente,SDatos);
                            Result:=True;
                        finally

                        end;
                  end;
             if cabecera='VER01' then /// Es texto zipiado
                  begin
                        try
                            SDatos:=UnPackZip(StringEntrada);
                            JSONToDataset(Cliente,SDatos);
                            Result:=True;
                        finally

                        end;
                  end;
        end;

end;

procedure GetFieldTypeInfo(Field: TField; var FieldType, JsonTyp: string);
begin

   if Field.DataType=ftString then
      begin
        FieldType:='ftstring';
        JsonTyp:='string';
      end
     else
        if (Field.DataType=ftInteger) then
        begin
          FieldType:='ftinteger';
          JsonTyp:='integer';
        end
          else
              if (Field.DataType= ftLargeint) then
              begin
                FieldType:='ftlargeint';
                JsonTyp:='largeint';
              end
              else
                   if (Field.DataType=ftFloat) {or (Field.DataType=ftSingle)}
                   then
                      begin
                          FieldType:='ftfloat';
                          JsonTyp:='float';
                      end
                     else
                         if Field.DataType=ftDateTime then
                            begin
                                FieldType:='ftdatetime';
                                JsonTyp:='datetime';
                            end
                           else
                         if Field.DataType=ftDate then
                            begin
                                FieldType:='ftdate';
                                JsonTyp:='date';
                            end
                           else
                         if Field.DataType=fttime then
                            begin
                                FieldType:='fttime';
                                JsonTyp:='time';
                            end
                            else
                               if Field.DataType=ftboolean then
                                  begin
                                      FieldType:='ftboolean';
                                      JsonTyp:='boolean';
                                  end
                                  else
                                     if Field.DataType=ftBlob then
                                        begin
                                            FieldType:='ftblob';
                                            JsonTyp:='blob';

{                                            FieldType:='ftstring';
                                            JsonTyp:='string';
}
                                        end;


//  GetEnumName(typeinfo(tfieldtype), Ord(Field.DataType));

//  JsonTyp := get(Field.DataType);
end;

{ TDataModule1 }

procedure CopioEstructuraDataset(Fuente, Destino: TClientDataset; doAdd: Boolean);
var
    i,p: integer;
    Fld: TField;
    dFld: string;
    Field: TField;
begin
  if Destino.Active then Destino.Active:=False;
  Destino.DisableControls;
  if not doAdd then
    begin
{        Destino.FieldDefs.Clear;
        Destino.Fields.Clear;
}
    end;
  for i:=0 to Fuente.fielddefs.count-1 do
    begin
        try
            if Destino.FindField(Fuente.FieldDefs[i].Name)=nil then
                begin
                  Destino.FieldDefs.Add(Fuente.FieldDefs[i].Name,Fuente.FieldDefs[i].DataType,Fuente.FieldDefs[i].Size);
                end;
         except

        end;
    end;

  for i:=0 to Fuente.FieldCount-1 do
    begin
        try
          if Destino.FindField(Fuente.fielddefs[i].Name)=nil then
            begin
                if Fuente.FieldDefs[i].DataType=ftInteger then Field:=TIntegerField.Create(Destino)
                  else
                  if Fuente.FieldDefs[i].DataType=ftLargeint then  Field:=TLargeintField.Create(Destino)
                    else
                    if Fuente.FieldDefs[i].DataType=ftString then  Field:=TStringField.Create(Destino)
                      else
                       if Fuente.FieldDefs[i].DataType= ftFloat then Field:=TFloatField.Create(Destino)
                         else
                          if (Fuente.FieldDefs[i].DataType=ftDateTime)  then Field:=TDateTimeField.Create(Destino)
                            else
                              if (Fuente.FieldDefs[i].DataType=ftDate)  then  Field:=TDateField.Create(Destino)
                                else
                                 if (Fuente.FieldDefs[i].DataType=fttime)  then  Field:=TTimeField.Create(Destino)
                                  else
                                    if Fuente.FieldDefs[i].DataType=ftBoolean then Field:=TBooleanField.Create(Destino)
                                      else
                                        if (Fuente.FieldDefs[i].DataType=ftblob)  then  Field:=TStringField.Create(Destino)
                                        else
                                            if Field<>nil then
                                              begin
                                                    Field.FieldName:=Fuente.FieldDefs[i].Name;
                                                    Field.Size:=Fuente.FieldDefs[i].Size;
                                                    Field.Required:=Fuente.FieldDefs[i].Required;
                                                    Destino.Fields.Add(Field);
                                              end
                                              else
                                                ShowMessage(fuente.fielddefs[i].Name+' no existe') ;
            end
            else
            begin
            end;


         except

        end;
    end;
  Destino.EnableControls;

end;

procedure CopioRegistro(Datasetorigen, Datasetdestino: TClientDataSet);
var
  ar: Integer;
  Stream:TMemoryStream;
begin
  if (Datasetorigen <> nil) and (Datasetdestino <> nil) then
  begin
        for ar := 0 to Datasetorigen.FieldDefs.Count- 1 do
              if Datasetdestino.Fields.FindField(Datasetorigen.FieldDefs[ar].Name) = nil then
                begin
                    CreoFieldenDataset(Datasetdestino,Datasetorigen.FieldDefs[ar].Name,Datasetorigen.FieldDefs[ar].DataType,Datasetorigen.FieldDefs[ar].Size,Datasetorigen.FieldDefs[ar].Required);
                    Datasetdestino.Open;
                end;
        Datasetdestino.Append;


        for ar := 0 to Datasetorigen.FieldDefs.Count- 1 do
        begin
              if Datasetdestino.Fields.FindField(Datasetorigen.FieldDefs[ar].Name) <> nil then
                    begin
                        begin
                              if not Datasetorigen.FieldByName(Datasetorigen.Fields[ar].FieldName).IsNull then
                                    Datasetdestino.FieldByName(Datasetorigen.Fields[ar].FieldName).Value := Datasetorigen.FieldByName(Datasetorigen.Fields[ar].FieldName).Value;
//                              Datasetdestino.FieldByName(Datasetorigen.Fields[ar].FieldName).Value := Datasetorigen.FieldByName(Datasetorigen.Fields[ar].FieldName).Value;
                        end;
                    end
                    else
                      MessageDlg('El Field '+Datasetorigen.FieldDefs[ar].Name+' en el destino es nil ',mtInformation,[mbok]);
        end;
        Datasetdestino.post;
  end;
end;
procedure CopioRegistro2022(Datasetorigen, Datasetdestino: TClientDataSet; CrearCampos:boolean=false);
var
  ar: Integer;
  Stream:TMemoryStream;
begin
  if (Datasetorigen <> nil) and (Datasetdestino <> nil) then
  begin

        if CrearCampos  then
          begin
              for ar := 0 to Datasetorigen.FieldDefs.Count- 1 do
                    if Datasetdestino.Fields.FindField(Datasetorigen.FieldDefs[ar].Name) = nil then
                      begin
                          CreoFieldenDataset(Datasetdestino,Datasetorigen.FieldDefs[ar].Name,Datasetorigen.FieldDefs[ar].DataType,Datasetorigen.FieldDefs[ar].Size,Datasetorigen.FieldDefs[ar].Required);
                      end;
          end;

        Datasetdestino.Open;
        Datasetdestino.Append;


        for ar := 0 to Datasetorigen.FieldDefs.Count- 1 do
        begin
              if Datasetdestino.Fields.FindField(Datasetorigen.FieldDefs[ar].Name) <> nil then
                    begin
                        begin
                              if not Datasetorigen.FieldByName(Datasetorigen.Fields[ar].FieldName).IsNull then
                                    Datasetdestino.FieldByName(Datasetorigen.Fields[ar].FieldName).Value := Datasetorigen.FieldByName(Datasetorigen.Fields[ar].FieldName).Value;
                        end;
                    end;
        end;
        Datasetdestino.post;
  end;
end;

procedure CopioTabla(TablaOrigen, TablaDestino: TClientDataSet);
var
  ar: Integer;
//  Link:TTablalink;
begin
  if (TablaOrigen = nil) or (TablaDestino = nil) then
    begin
      Exit;
    end;
   try

    if not TablaDestino.active then
        begin
            TablaDestino.Active:=true;
        end;

    if TablaOrigen.RecordCount>0 then
      begin
          if (TablaOrigen.State<>dsedit) and (TablaOrigen.State<>dsInsert) and (TablaOrigen.RecordCount>1) then TablaOrigen.First;
          TablaOrigen.DisableControls;
          TablaDestino.DisableControls;

            for ar := 1 to TablaOrigen.RecordCount do
            begin
              CopioRegistro(TablaOrigen, TablaDestino);
              if (TablaOrigen.State<>dsedit) and (TablaOrigen.State<>dsInsert) and (TablaOrigen.RecordCount>1) then TablaOrigen.Next;
            end;
      end;

    TablaOrigen.EnableControls;
    TablaDestino.EnableControls;

  finally
  end;

end;
procedure CopioTabla2022(TablaOrigen, TablaDestino: TClientDataSet; CrearCampos:boolean=false);
var
  ar: Integer;
begin
    if tablaorigen=nil then  ShowMessage('CopioTabla - Tablaorigen es nil '); ;
    if tabladestino=nil then  ShowMessage('CopioTabla - Tabladestino es nil '); ;
    if (TablaOrigen = nil) or (TablaDestino = nil) then
                            begin
                              Exit;
                            end;
    try
      if not TablaDestino.active then
          begin
              TablaDestino.Active:=true;
          end;


    if TablaOrigen.RecordCount>0 then
      begin

          if (TablaOrigen.State<>dsedit) and (TablaOrigen.State<>dsInsert) and (TablaOrigen.RecordCount>1) then TablaOrigen.First;
          TablaOrigen.DisableControls;
          TablaDestino.DisableControls;

            for ar := 1 to TablaOrigen.RecordCount do
            begin
              CopioRegistro2022(TablaOrigen, TablaDestino, CrearCampos);
              if (TablaOrigen.State<>dsedit) and (TablaOrigen.State<>dsInsert) and (TablaOrigen.RecordCount>1) then TablaOrigen.Next;
            end;
      end;
      TablaOrigen.EnableControls;
      TablaDestino.EnableControls;

    finally
    end;

end;


function FieldsToJSON(AFields: TFields; const ADateAsString: boolean = True): string;
var
  I: integer;
  VField: TField;
  VJsonTyp,VFieldType, VFieldName: String;
  VJSON: TJSONObject;
  Par:TJSONPair;
  Valor:TJSONValue;
  B64:string;
  Decimal:string;
  FechaString:String;



begin
  try
    Decimal:=FormatSettings.DecimalSeparator;
    FormatSettings.DecimalSeparator:='.';

    VJSON := TJSONOBject.Create;

    Par:=TJSONPair.Create;


    for I := 0 to Pred(AFields.Count) do
    begin
      VField := AFields[I];
      VFieldName := VField.FieldName;
      GetFieldTypeInfo(VField,VJsonTyp , VFieldType);

      if VField.DataType=ftBlob then
         begin
//              B64:=BlobtoBase64(TBlobField(VField));

              B64:=TBLObfield(VField).asstring;
              VJSON.AddPair(VFieldName, B64);
         end
      else
          begin
              if (VFieldName='') or (copy(VFieldName,1,1)='_') then VFieldName:=Format('Field%3.3d', [i]);

              if (VFieldType = JSON_NULL) or VField.IsNull then
              begin
                VJSON.AddPair(VFieldName,'NULL');
                Continue;
              end;
              if VFieldType = JSON_STRING then
                VJSON.AddPair(VFieldName, VField.AsString)
              else
                  if VFieldType = JSON_BOOLEAN then
                    VJSON.AddPair(VFieldName, BoolToStr(VField.AsBoolean))
                      else
                      if (VFieldType = JSON_DATETIME)  then
                      begin
                        if ADateAsString then
                          begin
                                VJSON.AddPair(VFieldName, VField.AsString)
                          end
                        else
                          VJSON.AddPair(VFieldName,FormatFloat('0.00',VField.AsFloat));
                      end
                          else
                      if (VFieldType = JSON_DATE)  then
                      begin
                        if ADateAsString then
                          begin
                                VJSON.AddPair(VFieldName, FormatDateTime ('dd/mm/yyyy', VField.AsDateTime));
                          end
                        else
                          VJSON.AddPair(VFieldName,FormatFloat('0.00',VField.AsFloat));
                      end
                          else
                      if (VFieldType = JSON_TIME)  then
                      begin
                        if ADateAsString then
                          begin
                                VJSON.AddPair(VFieldName, FormatDateTime('hh:nn', VField.AsDateTime))
                          end
                        else
                          VJSON.AddPair(VFieldName,FormatFloat('0.00',VField.AsFloat));
                      end
                          else
                          if (VFieldType = JSON_FLOAT) and (VField.Size > 0) then
                            VJSON.AddPair(VFieldName,FormatFloat('0.00',VField.AsFloat))
                          else
                              if (VFieldType = JSON_FLOAT) and (VField.Size = 0) then
                                VJSON.AddPair(VFieldName, VField.AsString)
                              else
                                  if VFieldType = JSON_INT then
                                    VJSON.AddPair(VFieldName, VField.AsString)
                                  else
                                        VJSON.AddPair(VFieldName, VField.AsString);
          end;
    end;

    Result := VJSON.ToJSON;
  finally
    VJSON.Free;
    Par.Free;
    FormatSettings.DecimalSeparator:=decimal;
  end;
end;


function  DataSetToJSON(DataSet: TDataSet; ReturnFields: boolean = True; ReturnData: boolean = True; ReturnOK: Boolean = True): string;
var
  i: integer;
  sFields, sData, FieldType, JsonTyp: string;
  List: TStringList;
  Requerido, Tamano, sFieldName,sDisplayLabel: string;

  Stream:TMemoryStream;
  WField,WData:String;
begin
  List := TStringList.Create;

  JsonTyp := '';
  FieldType := '';
  sData := '';
  sFields := '';
  Stream:=TMemoryStream.Create;
  try
    List.Sorted := True;

    if ReturnFields then
        begin
              sFields := '"' + cstCols + '":[';
              for i := 0 to DataSet.FieldCount - 1 do
              begin
                GetFieldTypeInfo(DataSet.Fields[i], FieldType, JsonTyp);

                sFieldName:=Dataset.Fields[i].FieldName;
                sDisplayLabel:=Dataset.Fields[i].DisplayLabel;

                if (trim(sFieldName)='') or (copy(trim(sFieldName),1,1)='_') then
                        begin
                          sFieldName:=Format('Field%3.3d', [i]);
                          sDisplayLabel:=sFieldName;
                        end;

                if (DataSet.Fields[i].DataType=ftInteger) or (DataSet.Fields[i].DataType=ftLargeint) then Tamano:='0' else Tamano:=IntToStr(DataSet.Fields[i].Size);
                if DataSet.Fields[i].Required then Requerido:='"True"' else Requerido:='"False"';

                sFields := concat ( string(sFields) , string( format(
                  '{"%s":"%s","%s":%s,"%s":"%s","%s":%s,"%s":"%s","%s":%s,"%s":"%s"}',
                  [cstJsonType, JsonTyp,
                   cstFieldIndex, IntToStr(DataSet.Fields[i].Index),
                   cstFieldType, FieldType,
                   cstFieldSize, tamano,
                   cstFieldName, sFieldName,
                   cstRequired, Requerido,
                   cstDisplayLabel, sDisplayLabel]) ) );


                if i < (dataset.FieldCount - 1) then sFields := sFields + ',';

                List.Add(DataSet.Fields[i].FieldName + '=' + JsonTyp);
              end;

              sFields := sFields + ']';
        end;

    if ReturnData then
            begin
              DataSet.DisableControls;
              DataSet.First;

              sData := '"' + cstData + '":[';

              while not dataset.EOF do
                    begin
                      sData := sData + FieldsToJSON(Dataset.Fields);
                      wData := FieldsToJSON(Dataset.Fields);

                      dataset.Next;

                      if not dataset.EOF then
                        sData := sData + ',';
                    end;

              sData := sData + ']';
            end;

    if returnFields then  Result := sFields ;

    if returndata then
            begin
              if ReturnFields then
                Result := sFields + ', ' + sData
              else
                begin
                  if ReturnOK then  Result := '"Result":"OK",' + sData
                  else Result := sData;

                end;
            end;

    Result := format('{%s}', [Result]);

  finally
    List.Free;
    DataSet.First;
    DataSet.EnableControls;
    Stream.Free;
  end;
end;

function DataSetToJSON(DataSet: TDataSet; ReturnFields: boolean = True; Version : integer = 1): string; overload;

var
  i: integer;
  sVersion, sFields, sData, FieldType, JsonTyp: string;
  List: TStringList;
  Requerido, Tamano, sFieldName,sDisplayLabel: string;
  LJSONObject: TJSONObject;
  LJSONArray: TJSONArray;


  function GetJsonFromDataset : String;
  begin
       Result:='';
       if DataSet is TClientDataSet then
          begin
               Result:= TClientDataSet(DataSet).Rows.toString;
          end;
  end;

  function GetDataVersion1 : String;
  begin
        Result:='';

        DataSet.DisableControls;
        DataSet.First;

        sData := '"' + cstData + '":[';

        while not dataset.EOF do
              begin
                sData := sData + FieldsToJSON(Dataset.Fields);

                dataset.Next;

                if not dataset.EOF then sData := sData + ',';
              end;

        sData := sData + ']';

       Result:=Sdata;
  end;
  function GetDataVersion2 : String;
  begin
       Result:='';
//       sData := '"' + cstData + '":[';
       sData := '"' + cstData + '": ';
       if DataSet is TClientDataSet then
          begin
               sData:=sData+TWebClientDataSet(DataSet).Rows.toString;
          end;
//       sData := sData + ']';
       Result:=Sdata;
  end;
begin
        List := TStringList.Create;

        JsonTyp := '';
        FieldType := '';
        sData := '';
        sFields := '';

        try
          List.Sorted := True;

         if version = 0  then
              begin
                  Result:=GetJsonFromDataset;
              end
              else
              begin
                if ReturnFields then
                     begin
                            sFields := '"' + cstCols + '":[';
                            for i := 0 to DataSet.FieldCount - 1 do
                              begin
                                    GetFieldTypeInfo(DataSet.Fields[i], FieldType, JsonTyp);

                                    sFieldName:=Dataset.Fields[i].FieldName;
                                    sDisplayLabel:=Dataset.Fields[i].DisplayLabel;

                                    if (trim(sFieldName)='') or (copy(trim(sFieldName),1,1)='_') then
                                    begin
                                      sFieldName:=Format('Field%3.3d', [i]);
                                      sDisplayLabel:=sFieldName;
                                    end;

                                    if (DataSet.Fields[i].DataType=ftInteger) or (DataSet.Fields[i].DataType=ftLargeint) then Tamano:='0' else Tamano:=IntToStr(DataSet.Fields[i].Size);
                                    if DataSet.Fields[i].Required then Requerido:='"True"' else Requerido:='"False"';

                                    sFields := sFields + format(
                                      '{"%s":"%s","%s":%s,"%s":"%s","%s":%s,"%s":"%s","%s":%s,"%s":"%s"}',
                                      [cstJsonType, JsonTyp,
                                       cstFieldIndex, IntToStr(DataSet.Fields[i].Index),
                                       cstFieldType, FieldType,
                                       cstFieldSize, tamano,
                                       cstFieldName, sFieldName,
                                       cstRequired, Requerido,
                                       cstDisplayLabel, sDisplayLabel]);

                                    if i < (dataset.FieldCount - 1) then sFields := sFields + ',';

                                    List.Add(DataSet.Fields[i].FieldName + '=' + JsonTyp);
                              end;

                              sFields := sFields + ']';
                             if version = 1  then
                                begin
                                      sVersion:= '"VERSION" : 1,';
                                      sData:=GetDataVersion1;
                                      Result := sVersion + sFields + ', ' + sData;
                                      Result := ( format('{%s}', [Result]) );
                                    end;
                              if Version=2 then
                                    begin
                                         sVersion:= '"VERSION" : 2,';
                                         sdata:=GetDataVersion2;
                                         result:= sVersion + sFields + ', ' + sData;
                                         Result := ( format('{%s}', [Result]) );
                                    end;
                      end
                      else
                      begin
                           if version = 1  then
                                begin
                                    sVersion:= '"VERSION" : 1,';
                                    sdata:=GetDataVersion1;
                                    Result := sVersion + sData;
                                    Result := ( format('{%s}', [Result]) );
                                end;
                           if Version=2 then
                                begin
                                     sVersion:= '"VERSION" : 2,';
                                     sData := GetDataVersion2;
                                     result:= sVersion + sData;
                                     Result := ( format('{%s}', [Result]) );
                                end;
                      end;
              end
        finally
          List.Free;
          DataSet.First;
          DataSet.EnableControls;
        end;
end;


function GetTipoField(TipoJSon: String): TFieldType;
begin
//       Result:=
       if UpperCase(TipoJson)='FTSTRING' then Result:=ftString
        else
         if (UpperCase(TipoJson)='FTFLOAT') then Result:=ftFloat
         else
           if UpperCase(TipoJson)='FTBOOLEAN' then Result:=ftBoolean
           else
               if (UpperCase(TipoJson)='FTDATE') THEN result:=ftDate
               else
               if  (UpperCase(TipoJson)='FTDATETIME') then result:=ftdatetime
                else
                if (UpperCase(TipoJson)='FTTIME') then Result:=fttime
               else
                 if (UpperCase(TipoJson)='FTINTEGER')
                 then Result:=ftInteger
                 else if (UpperCase(TipoJson)='FTLARGEINT')  then Result:=ftLargeint
                 else if (UpperCase(TipoJson)='FTBLOB')  then Result:=ftblob
                   else
                     if UpperCase(TipoJson)='NULL' then Result:=ftUnknown;

end;

function CreoField(Dataset: TClientDataSet; Indice:Integer; Tipo:TFieldtype):TField;
begin
      try
          if (Tipo=ftInteger)
           then
            Result:=TIntegerField.Create(DataSet)
            else
              if Tipo=ftLargeint then
                Result:=TLargeintField.Create(DataSet)
                else
                 if (Tipo=ftString)
                  or (Tipo=ftFixedChar)
                  or (Tipo=ftString)
                  then
                    Result:=TStringField.Create(DataSet)
                    else
                    if Tipo=ftFloat then
                       Result:=TFloatField.Create(DataSet)
                        else
                          if (Tipo=ftDateTime)  then
                            begin
                                Result:=TDateTimeField.Create(DataSet)
                            end
                            else
                              if (Tipo=ftDate)     then
                                begin
                                    Result:=TDateField.Create(DataSet)
                                end
                                else
                              if (Tipo=fttime)  then
                                begin
                                    Result:=TtimeField.Create(DataSet)
                                end
                                else
                                  if Tipo=ftBoolean then
                                    Result:=TBooleanField.Create(DataSet)
                                    else
                                      if Tipo=ftblob then
                                      begin
                                                Result:=TStringField.Create(DataSet);
                                      end
                                        else
                                         result:=nil;

      except

      end;

     if Result<>nil then
        begin
              Result.FieldName:=DataSet.FieldDefs[Indice].Name;
              Result.Size:=DataSet.FieldDefs[Indice].Size;
              Result.Required:=DataSet.FieldDefs[Indice].Required;
              Result.DataSet:=DataSet;
              if Dataset.FindField(DataSet.FieldDefs[Indice].Name)=nil then DataSet.Fields.Add(Result);
        end
        else
        begin
           ShowMessage('DataSet no puede definir el campo '+DataSet.FieldDefs[Indice].Name); ;
        end;

end;
function CreoFieldenDataset(Dataset: TClientDataSet; Nombre:String; Tipo:TFieldtype; Tamano:integer; Requerido:boolean=False):TField;
begin
      try
        if dataset.Active then dataset.Close;
          if (Tipo=ftInteger)
           then
            Result:=TIntegerField.Create(DataSet)
            else
              if Tipo=ftLargeint then
                Result:=TLargeintField.Create(DataSet)
                else
                 if (Tipo=ftString)
                  or (Tipo=ftFixedChar)
                  or (Tipo=ftString)
                  then
                    Result:=TStringField.Create(DataSet)
                    else
                    if Tipo=ftFloat then
                       Result:=TFloatField.Create(DataSet)
                        else
                          if (Tipo=ftDateTime)  then
                            begin
                                Result:=TDateTimeField.Create(DataSet)
                            end
                            else
                              if (Tipo=ftDate)     then
                                begin
                                    Result:=TDateField.Create(DataSet)
                                end
                                else
                              if (Tipo=fttime)  then
                                begin
                                    Result:=TtimeField.Create(DataSet)
                                end
                                else
                                  if Tipo=ftBoolean then
                                    Result:=TBooleanField.Create(DataSet)
                                    else
                                      if Tipo=ftblob then
                                      begin
                                                Result:=TStringField.Create(DataSet);
                                      end
                                        else
                                         result:=nil;
      except

      end;

     if Result<>nil then
        begin
              Result.FieldName:=Nombre;
              Result.Size:=Tamano;
              Result.Required:=Requerido;
              Result.DataSet:=DataSet;

              if Dataset.FindField(Nombre)=nil then DataSet.Fields.Add(Result);
        end
        else
        begin
           ShowMessage('DataSet no puede definir el campo '+Nombre); ;
        end;

end;


{procedure JsonToDataset(Dataset: TWebClientDataSet; JSon: string);
var
  JWSon:TJson;
  JFields, JData, JWArray:TJSONArray;
  JWObject:TJSONObject;
  i, ar:integer;
  Cliente:TWebClientDataSet;
  JFieldsRegistro:TJSONObject;
  NombreCampo:string;
  TipoCampo:TFieldType;
  Tamano:integer;
  Requerido:boolean;
  Field:TField;
  StrFechaDia:String;
  t1: TDateTime;
  ts:TFormatSettings;
  BlobField:TBlobField;
  StringStream:TStringStream;
  WRows :  TJSONArray;




begin
      if Length(json)>0 then
        begin
              JWSon := TJSON.Create;
              try

                JWObject:= JWSon.Parse(json);

                JData:= TJSONArray(JWObject.GetValue('Data'));
                JFields:= TJSONArray(JWObject.GetValue('Fields'));

                Cliente:=TWebClientDataSet.Create(nil );

                Cliente.FieldDefs.Clear;
                Cliente.Fields.Clear;

                if Dataset.Active then Dataset.EmptyDataSet;
                if (JFields=nil) OR (jfields.count=0) then
                  begin
                    try
                          Cliente.Rows:= TJSArray(TJSJSON.parseobject((Json))) ;
                    except

                    end;
                  end
                  else
                     begin
                        if JFields.Count>0 then
                            begin
                                for i := 0 to JFields.Count-1 do
                                  begin
                                      JFieldsRegistro:=TJSONObject(JFields.Items[i]);
                                      NombreCampo:=JFieldsRegistro.GetJSONValue('FieldName');
                                      TipoCampo:=GetTipoField(JFieldsRegistro.GetJSONValue('FieldType'));
                                      Tamano:=StrToInt(JFieldsRegistro.GetJSONValue('FieldSize'));
                                      Requerido:=strtobool (JFieldsRegistro.GetJSONValue('Required'));

                                      Cliente.FieldDefs.Add(NombreCampo,TipoCampo,Tamano,requerido);
                                  end;

                                  for i:=0 to Cliente.FieldDefs.Count-1 do
                                  begin
                                       Field:=CreoField(Cliente, i, Cliente.FieldDefs[i].DataType);
                                  end;
                            end
                            else
                            begin
                                      ShowMessage('No se encontro Fields'); ;
                            end;
                          Cliente.Active:=True;

                          if Cliente.Active then
                            begin
                                  for i := 0 to JData.Count-1 do
                                    begin
                                        JFieldsRegistro:=TJSONObject(JData.Items[i]);
                                        Cliente.Append;
                                        for ar := 0 to Cliente.FieldCount-1 do
                                          begin
                                              NombreCampo:=Cliente.Fields[ar].FieldName;
                                              IF uppercase(JFieldsRegistro.GetJSONValue(NombreCampo))='NULL' then
                                                Cliente.Fields[ar].Value:=NULL
                                                else

                                              if Cliente.Fields[ar].DataType=ftString then
                                                    Cliente.Fields[ar].Value:=JFieldsRegistro.GetJSONValue(NombreCampo)
                                                 else
                                                  if Cliente.Fields[ar].DataType=ftLargeint then
                                                  begin
                                                        Cliente.Fields[ar].value:=StrToInt64(JFieldsRegistro.GetJSONValue(NombreCampo));
                                                  end
                                                     else
                                                  if Cliente.Fields[ar].DataType=ftInteger then
                                                        Cliente.Fields[ar].value:=StrToInt(JFieldsRegistro.GetJSONValue(NombreCampo))
                                                     else
                                                        if Cliente.Fields[ar].DataType=ftFloat then
                                                            Cliente.Fields[ar].value:=StrToFloat(JFieldsRegistro.GetJSONValue(NombreCampo))
                                                            else
                                                                if Cliente.Fields[ar].DataType=ftBoolean then
                                                                    Cliente.Fields[ar].value:=StrToBool(JFieldsRegistro.GetJSONValue(NombreCampo))
                                                                    else
                                                                        if (Cliente.Fields[ar].DataType=ftDateTime) then
                                                                            begin
                                                                              if uppercase(JFieldsRegistro.GetJSONValue(NombreCampo))='NULL' then
                                                                                begin
                                                                                    StrFechaDia:='01/01/1990';
                                                                                    Cliente.Fields[ar].value:= StrToDateTime(StrFechaDia);
                                                                                end
                                                                                else
                                                                                begin
                                                                                        StrFechaDia:= (JFieldsRegistro.GetJSONValue(NombreCampo) );
                                                                                        StrFechaDia:=StringReplace(StrFechaDia,'-','/',[rfReplaceAll]);
                                                                                        StrFechaDia:=StringReplace(StrFechaDia,'\/','/',[rfReplaceAll]);
                                                                                        FormatSettings.ShortDateFormat:='dd/MM/yyyy';
                                                                                        FormatSettings.DateSeparator:='/';
                                                                                        Cliente.Fields[ar].value:= StrToDateTime(StrFechaDia);
                                                                                end;
                                                                            end
                                                                            else
                                                                              if (Cliente.Fields[ar].DataType=ftDate) then
                                                                                  begin
                                                                                    if uppercase(JFieldsRegistro.GetJSONValue(NombreCampo))='NULL' then
                                                                                      begin
                                                                                          StrFechaDia:='01/01/1990';
                                                                                          Cliente.Fields[ar].value:= StrToDateTime(StrFechaDia);
                                                                                      end
                                                                                      else
                                                                                      begin
                                                                                              StrFechaDia:= (JFieldsRegistro.GetJSONValue(NombreCampo) );

                                                                                              StrFechaDia:=StringReplace(StrFechaDia,'-','/',[rfReplaceAll]);
                                                                                              StrFechaDia:=StringReplace(StrFechaDia,'\/','/',[rfReplaceAll]);


                                                                                              FormatSettings.ShortDateFormat:='dd/MM/yyyy';
                                                                                              FormatSettings.DateSeparator:='/';
                                                                                              Cliente.Fields[ar].value:= StrToDate(StrFechaDia);


                                                                                      end;
                                                                                  end
                                                                                  else
                                                                                      if (Cliente.Fields[ar].DataType=ftTime) then
                                                                                          begin
                                                                                            if uppercase(JFieldsRegistro.GetJSONValue(NombreCampo))='NULL' then
                                                                                              begin
                                                                                                  StrFechaDia:='00:00:00';
                                                                                                  FormatSettings.ShortTimeFormat:= 'hh:nn:ss';
                                                                                                  FormatSettings.TimeSeparator:=':';
                                                                                                  Cliente.Fields[ar].value:= StrToTime(StrFechaDia);
                                                                                              end
                                                                                              else
                                                                                              begin
                                                                                                  StrFechaDia:= (JFieldsRegistro.GetJSONValue(NombreCampo) );
                                                                                                  FormatSettings.ShortTimeFormat:= 'hh:nn:ss';
                                                                                                  FormatSettings.TimeSeparator:=':';
                                                                                                  Cliente.Fields[ar].value:= StrToTime(StrFechaDia);
                                                                                              end;
                                                                                          end
                                                                                              else
                                                                                                  begin
                                                                                                          if (Cliente.Fields[ar].DataType=ftblob) then
                                                                                                            begin
                  //                                                                                                Cliente.Fields[ar].value:=base64tostring(JFieldsRegistro.GetJSONValue(NombreCampo));

          //                                                                                                        base( JFieldsRegistro.GetJSONValue(NombreCampo),TBlobField(Cliente.Fields[ar]));
                                                                                                                    Cliente.Fields[ar].AsString:=JFieldsRegistro.GetJSONValue(NombreCampo);

          //                                                                                                        base64toBlob( JFieldsRegistro.GetJSONValue(NombreCampo),TBlobField(Cliente.Fields[ar]));
                                                                                                            end;
                                                                                                  end;
                                          end;
                                        Cliente.Post;
                                    end;
                            end;

                            if cliente.fieldcount>0 then
                              begin

                                  CopioEstructuraDataset(Cliente,Dataset,False);
                                  CopioTabla(Cliente,Dataset);
                              end;
                     end;


                finally
                    Cliente.Free;

                end;
        end;
end;
}

procedure JsonToDataset(Dataset: TClientDataSet; JSon: string);
var
  JWSon:TJson;
  JFields, JData, JWArray:TJSONArray;
  JWObject:TJSONObject;
  i, ar:integer;
  Cliente:TClientDataSet;
  JFieldsRegistro:TJSONObject;
  NombreCampo:string;
  TipoCampo:TFieldType;
  Tamano:integer;
  Requerido:boolean;
  Field:TField;
  StrFechaDia:String;
  t1: TDateTime;
  ts:TFormatSettings;
  BlobField:TBlobField;
  StringStream:TStringStream;
  WRows :  TJSONArray;
  JVersion: TJSONObject;
  STRBlob:String;


     function CrearFields : Boolean;
     var
      i:integer;
     begin
          Result:=False;
          if JFields<>nil then
              if JFields.Count>0 then
                  begin
                      for i := 0 to JFields.Count-1 do
                        begin
                            JFieldsRegistro:=TJSONObject(JFields.Items[i]);
                            NombreCampo:=JFieldsRegistro.GetJSONValue('FieldName');
                            TipoCampo:=GetTipoField(JFieldsRegistro.GetJSONValue('FieldType'));
                            Tamano:=StrToInt(JFieldsRegistro.GetJSONValue('FieldSize'));
                            Requerido:=strtobool (JFieldsRegistro.GetJSONValue('Required'));

                            Cliente.FieldDefs.Add(NombreCampo,TipoCampo,Tamano,requerido);
                        end;
                        for i:=0 to Cliente.FieldDefs.Count-1 do
                        begin
                             Field:=CreoField(Cliente, i, Cliente.FieldDefs[i].DataType);
                        end;
                        Result:=True;
                  end
                  else
                  begin
                       ShowMessage('No se encontro Fields'); ;
                  end;
     end;

     function CopioDatos:Boolean;
     var
      i, ar:integer;
      ValorTXT:STRING;
     begin
         Result:=False;
         if Cliente.Active then
          begin
            for i := 0 to JData.Count-1 do
              begin
                  JFieldsRegistro:=TJSONObject(JData.Items[i]);
                  Cliente.Append;
                  for ar := 0 to Cliente.FieldCount-1 do
                    begin
                      NombreCampo:=Cliente.Fields[ar].FieldName;
                      ValorTXT:=JFieldsRegistro.GetJSONValue(NombreCampo);
                      IF uppercase(JFieldsRegistro.GetJSONValue(NombreCampo))='NULL' then
                        Cliente.Fields[ar].Value:=NULL
                        else
                        if Cliente.Fields[ar].DataType=ftString then
                            Cliente.Fields[ar].Value:=JFieldsRegistro.GetJSONValue(NombreCampo)
                         else
                         if Cliente.Fields[ar].DataType=ftLargeint then
                            Cliente.Fields[ar].value:=StrToInt64(JFieldsRegistro.GetJSONValue(NombreCampo))
                            else
                            if Cliente.Fields[ar].DataType=ftInteger then
                                  Cliente.Fields[ar].value:=StrToInt(JFieldsRegistro.GetJSONValue(NombreCampo))
                               else
                               if Cliente.Fields[ar].DataType=ftFloat then
                                  begin
//                                  Cliente.Fields[ar].value:=StrToFloat(JFieldsRegistro.GetJSONValue(NombreCampo))
                                    if FormatSettings.DecimalSeparator='.' then
                                        begin

                                            ValorTXT:=StringReplace(ValorTXT,',','.',[rfReplaceAll]);
                                        end
                                        else
                                          if FormatSettings.DecimalSeparator=',' then
                                                  ValorTXT:=StringReplace(ValorTXT,'.',',',[rfReplaceAll]);
                                    Cliente.Fields[ar].value:=StrToFloat(ValorTXT);
                                  end
                                  else
                                     if Cliente.Fields[ar].DataType=ftBoolean then
                                        Cliente.Fields[ar].value:=StrToBool(JFieldsRegistro.GetJSONValue(NombreCampo))
                                        else
                                            if (Cliente.Fields[ar].DataType=ftDateTime) then
                                              begin
                                                if uppercase(JFieldsRegistro.GetJSONValue(NombreCampo))='NULL' then
                                                  begin
                                                    StrFechaDia:='01/01/1990';
                                                    Cliente.Fields[ar].value:= StrToDateTime(StrFechaDia);
                                                  end
                                                  else
                                                  begin
                                                    StrFechaDia:= (JFieldsRegistro.GetJSONValue(NombreCampo) );
                                                    StrFechaDia:=StringReplace(StrFechaDia,'-','/',[rfReplaceAll]);
                                                    StrFechaDia:=StringReplace(StrFechaDia,'\/','/',[rfReplaceAll]);
                                                    FormatSettings.ShortDateFormat:='dd/MM/yyyy';
                                                    FormatSettings.DateSeparator:='/';
                                                    Cliente.Fields[ar].value:= StrToDateTime(StrFechaDia);
                                                  end;
                                              end
                                                else
                                                if (Cliente.Fields[ar].DataType=ftDate) then
                                                  begin
                                                    if uppercase(JFieldsRegistro.GetJSONValue(NombreCampo))='NULL' then
                                                      begin
                                                        StrFechaDia:='01/01/1990';
                                                        Cliente.Fields[ar].value:= StrToDateTime(StrFechaDia);
                                                      end
                                                      else
                                                      begin
                                                        StrFechaDia:= (JFieldsRegistro.GetJSONValue(NombreCampo) );

                                                        StrFechaDia:=StringReplace(StrFechaDia,'-','/',[rfReplaceAll]);
                                                        StrFechaDia:=StringReplace(StrFechaDia,'\/','/',[rfReplaceAll]);


                                                        FormatSettings.ShortDateFormat:='dd/MM/yyyy';
                                                        FormatSettings.DateSeparator:='/';
                                                        Cliente.Fields[ar].value:= StrToDate(StrFechaDia);
                                                      end;
                                                  end
                                                    else
                                                      if (Cliente.Fields[ar].DataType=ftTime) then
                                                        begin
                                                          if uppercase(JFieldsRegistro.GetJSONValue(NombreCampo))='NULL' then
                                                            begin
                                                                StrFechaDia:='00:00:00';
                                                                FormatSettings.ShortTimeFormat:= 'hh:nn:ss';
                                                                FormatSettings.TimeSeparator:=':';
                                                                Cliente.Fields[ar].value:= StrToTime(StrFechaDia);
                                                            end
                                                            else
                                                            begin
                                                                StrFechaDia:= (JFieldsRegistro.GetJSONValue(NombreCampo) );
                                                                FormatSettings.ShortTimeFormat:= 'hh:nn:ss';
                                                                FormatSettings.TimeSeparator:=':';
                                                                Cliente.Fields[ar].value:= StrToTime(StrFechaDia);
                                                            end;
                                                        end
                                                          else
                                                            begin
                                                              if (Cliente.Fields[ar].DataType=ftblob) then
                                                                begin
                                                                    STRBlob:=JFieldsRegistro.GetJSONValue(NombreCampo);
//                                                                    STRBlob:=StringReplace(STRBlob,'-','/',[rfReplaceAll]);
                                                                    STRBlob:=StringReplace(STRBlob,'\/','/',[rfReplaceAll]);

                                                                    Cliente.Fields[ar].AsString:=JFieldsRegistro.GetJSONValue(NombreCampo);
                                                                end;
                                                            end;
                    end;
                  Cliente.Post;
              end;
          end;
     end;


begin
      if Length(json)>0 then
        begin
              JWSon := TJSON.Create;
//              JWObject:= TJSONObject(TJSJSON.parseObject(json));// TJSONObject(json);//  JWSon.Parse(json);
              JWobject:= TJSONObject.ParseJSONValue(Json) as TJSONObject;
              console.log(json);
              console.log(jwobject);
               if JWObject<>nil then
                  begin
                      try
//                        JVersion:= TJSONObject(JWObject.GetValue('VERSION'));
//                        JData:= TJSONArray(JWObject.GetValue('Data'));
//                        JFields:= TJSONArray(JWObject.GetValue('Fields'));
{
                        JVersion:= TJSONObject(TJSJSON.parseobject(JWObject.GetJSONValue('VERSION')));
                        JData:= TJSONArray(TJSJSON.parseobject(JWObject.GetJSONValue('Data')));
                        JFields:= TJSONArray(TJSJSON.parseobject(JWObject.GetJSONValue('Fields')));
}
//TJSONObject.ParseJSONValue(Datos) as TJSONObject;
                        Jversion:=nil;
                        Cliente:=nil;
                        if (JWObject.GetJSONValue('VERSION')<>'undefined') then
                            JVersion:=TJSONObject.ParseJsonValue(JWObject.GetJSONValue('VERSION') ) as TJSONObject;

                        JData:= TJSONArray(JWObject.GetValue('Data'));
                        JFields:= TJSONArray(JWObject.GetValue('Fields'));

                        Cliente:=TClientDataSet.Create(nil);
                        Cliente.FieldDefs.Clear;
                        Cliente.Fields.Clear;
                        if Dataset.Active then Dataset.EmptyDataSet;

                        if JVersion<>NIL then
                          BEGIN
                               if JVersion.ToString='0' then
                                begin
                                      Cliente.Rows:= TJSArray(TJSJSON.parseobject((Json))) ;
                                end;
                               if JVersion.ToString='1' then
                                begin
                                      if (JFields<>nil) and (JFields.count>0) then
                                        if CrearFields then
                                          begin
                                            Cliente.Active:=True;
                                            CopioDatos;
                                            if cliente.fieldcount>0 then
                                              begin
                                                  CopioEstructuraDataset(Cliente,Dataset,False);
                                                  CopioTabla(Cliente,Dataset);
                                              end;
                                          end
                                end;
                               if JVersion.ToString='2' then
                                begin

                                end;

                          END
                          else
                          begin
                                if (JFields<>nil) and (JFields.count>0) then
                                  if CrearFields then
                                    begin
                                      Cliente.Active:=True;
                                      CopioDatos;
                                      if cliente.fieldcount>0 then
                                        begin
                                            CopioEstructuraDataset(Cliente,Dataset,False);
                                            CopioTabla(Cliente,Dataset);
                                        end;
                                    end
                          end;
                        finally
                            Cliente.Free;
                        end;
                  end;
        end;
{ jo := js.Parse(AResponse);

    ja  := TJSONArray(jo.GetValue('Records'));


    for i := 0 to ja.Count - 1 do
    begin
      jo := ja.Items[i];
      WebListBox1.Items.Add(jo.GetJSONValue('id'));
    end;
  finally
    js.Free;

    end;
}
end;

function LeerVista(CVista: TClientDataset; SServidor, SServidorVista: string; DataSource:TDataSource):boolean;
begin
end;


function PackString(Cadena: string): string;
begin
      Result:='VER00'+Cadena;
end;

function PackZip(Cadena: string): string;
begin
    Result:='VER01'+ZipCadena(Cadena);
end;

function RellenoString(cadena: string; longitudtotal: Integer;
  caracterrelleno: char; rellenoizquierdo: boolean): string;
var
  cad: string;
begin
      if length(cadena) >= longitudtotal then
      begin
        Result := cadena;
        Exit;
      end;
      cad := cadena;
      if rellenoizquierdo then
      begin
        repeat
          cad := caracterrelleno + cad;
        until length(cad) >= longitudtotal;
      end
      else
      begin
        repeat
          cad := cad + caracterrelleno;
        until length(cad) >= longitudtotal;
      end;
      Result := cad;
end;

function StringtoArraybuffer(Cadena: string): TJSArrayBuffer;
begin
      asm
            var string = btoa(unescape(encodeURIComponent(Cadena))),
                charList = string.split(''),
                uintArray = [];
            for (var i = 0; i < charList.length; i++)
             {
                uintArray.push(charList[i].charCodeAt(0));
            }
            return new Uint8Array(uintArray);

      end;
end;

function StringToBase64(Cadena: string): string;
var
    B64:string;
begin
          asm
                var strData = btoa(Cadena);
                B64 = strData;
          end;
    result:=b64;
end;

function UnPackString(Cadena: string): string;
var
    Cab:string;
    Datos:string;

begin
    Cab:=Copy(Cadena,1,5);
    Result:='';
    if UpperCase(Cab)='VER00' then
      begin
          Datos:=Cadena;
          Delete(Datos,1,5);
          Result:=Datos;
      end;
end;

function UnPackZip(Cadena: string): string;
var
    Cab:string;
    Datos:string;
begin
    Cab:=Copy(Cadena,1,5);

    if UpperCase(Cab)='VER01' then
      begin
          Datos:=Cadena;
          Delete(Datos,1,5);
          Result:=UnZipCadena(Datos);
      end
      else
      begin
          Result:=Cadena;
      end;
end;

function UnZipCadena(Cadena: string): string;
var
    B64:string;
begin
          asm
                var b64Data =   Cadena;
                // Decode base64 (convert ascii to binary)
                var strData     = atob(b64Data);

                // Convert binary string to character-number array
                var charData    = strData.split('').map(function(x){return x.charCodeAt(0);});

                // Turn number array into byte-array
                var binData     = new Uint8Array(charData);
//                var binData     = new Uint32Array(charData);

                // Pako magic
                var data        = pako.inflate(binData);

                // Convert gunzipped byteArray back to ascii string:
                var strData     = String.fromCharCode.apply(null, new Uint8Array(data));
                B64 = strData;
          end;
    result:=b64;
end;

function UnZipCadena64(Cadena: string): string;
var
    B64:string;
begin

          asm
                var b64Data =   Cadena;
                // Decode base64 (convert ascii to binary)
                var strData     = atob(b64Data);

                // Convert binary string to character-number array
                var charData    = strData.split('').map(function(x){return x.charCodeAt(0);});

                // Turn number array into byte-array
                var binData     = new Uint8Array(charData);

                // Pako magic
                var data        = pako.inflate(binData);

                // Convert gunzipped byteArray back to ascii string:
                var strData     = String.fromCharCode.apply(null, new Uint16Array(data));
                B64 = strData;
          end;
//    Delete(b64,1,4);
    result:=b64;
end;
function zipcadena(Cadena: string): string;
var
    B64:string;
    cabeza:string;
    SNro:string;

begin
          snro:=inttostr(length(cadena));
          snro:=snro.PadRight(4,' ');
          cabeza:=snro+cadena;

          cabeza:=cadena;
          asm
                var Cad = cabeza;

//                var binaryString = pako.deflate(JSON.stringify(Cad), { to: 'string' });

                var binaryString = pako.deflate(Cad, { to: 'string' });
                var strData = btoa(binaryString);

                B64 = strData;
          end;
    result:=B64;
end;

function ZipCadena64(Cadena: string): string;
var
    B64:string;
    cabeza:string;
    SNro:string;

begin
          snro:=inttostr(length(cadena));
          snro:=snro.PadRight(8,' ');
          cabeza:=snro+cadena;

          cabeza:=cadena;
          asm
                var Cad = cabeza;

//                var binaryString = pako.deflate(JSON.stringify(Cad), { to: 'string' });

                var binaryString = pako.deflate(Cad, { to: 'string' });
                var strData = btoa(binaryString);

                B64 = strData;
          end;
    result:=B64;
end;

function Base64toString(Cadena: string): string;
var
    B64:string;
begin
          asm
                var strData     = atob(Cadena);
                B64 = strData;
          end;
    result:=b64;
end;

FUNCTION EsEmailValido(CONST Value: String): boolean;

  FUNCTION CheckAllowed(CONST s: String): boolean;
  VAR i: Integer;
  BEGIN
      Result:= False;
      FOR i:= 1 TO Length(s) DO // illegal char in s -> no valid address
      IF NOT (s[i] IN ['a'..'z','A'..'Z','0'..'9','_','-','.']) THEN Exit;
      Result:= true;
  END;

VAR
  i,len: Integer;
  namePart, serverPart: String;
BEGIN // of IsValidEmail
  Result:= False;
  i:= Pos('@', Value);
  IF (i=0) OR (Pos('..',Value) > 0) THEN Exit;
  namePart:= Copy(Value, 1, i - 1);
  serverPart:= Copy(Value,i+1,Length(Value));
  len:=Length(serverPart);
  // must have dot and at least 3 places from end, 2 places from begin
  IF (len<4) OR
     (Pos('.',serverPart)=0) OR
     (serverPart[1]='.') OR
     (serverPart[len]='.') OR
     (serverPart[len-1]='.') THEN Exit;
  Result:= CheckAllowed(namePart) AND CheckAllowed(serverPart);
END;

function ContarPalabras(Cadena:String):integer;
var
  Lista:TStringList;
begin
  Lista:=TStringList.Create;
  try
    lista.DelimitedText := cadena;
    lista.Delimiter := ' ';
    if Lista.Count>0 then result:=Lista.Count else Result:=-1;
  finally
    Lista.Free;
  end;
end;

function ExtraigoPalabra(cadena: string; posicionpalabra: Integer): string;
var
  Lista: TStringList;
begin
  Lista := TStringList.Create;
  try
    Lista.DelimitedText := cadena;
    Lista.Delimiter := ' ';
    if posicionpalabra <= lista.Count then
      Result := lista.Strings[posicionpalabra - 1]
    else
      Result := '';
  finally
    Lista.Free;
  end;
end;

function ExtraigoPalabra(cadena: string; posicionpalabra: Integer; Delimitador:char): string;
var
  Lista: TStringList;
begin
  Lista := TStringList.Create;
  try
    Lista.DelimitedText := cadena;
    Lista.Delimiter := Delimitador;
    if posicionpalabra <= lista.Count then
      Result := lista.Strings[posicionpalabra - 1]
    else
      Result := '';
  finally
    Lista.Free;
  end;
end;


function BlobtoBase64(Blob: TBlobField): string;
{var
  Input: TBytesStream;
  Output: TStringStream;
  Encoding: TBase64Encoding;
}
begin
{  Input := TBytesStream.Create;
  try
    blob.SaveToStream(Input);
    Input.Position := 0;
    Output := TStringStream.Create('', TEncoding.ASCII);
    try
      Encoding := TBase64Encoding.Create(0);
      try
        Encoding.Encode(Input, Output);
        Result := Output.DataString;
      finally
        Encoding.Free;
      end;
    finally
      Output.Free;
    end;
  finally
    Input.Free;
  end;
}

       Result:=StringToBase64(Blob.AsString);
       if Result='' then result:='NULL';

end;

procedure Base64toBlob(Base64: string; Blob: TBlobField);
{var
  Input: TStringStream;
  Output: TBytesStream;
  Encoding: TBase64Encoding;
}
begin
{  Input := TStringStream.Create(Base64, TEncoding.ASCII);
  try
    Output := TBytesStream.Create;
    try
      Encoding := TBase64Encoding.Create(0);
      try
        Encoding.Decode(Input, Output);
        Output.Position := 0;
        blob.LoadFromStream(Output);
      finally
        Encoding.Free;
      end;
    finally
      Output.Free;
    end;
  finally
    Input.Free;
  end;
}
   IF Base64='NULL' then Blob.value:='NULL' else Blob.AsString:=Base64toString(Base64);


end;


procedure Mostrarimagen(CampoBLOB: TBlobField; Imagen: TImageControl);
var
  stream: TMemoryStream;
  Jpg: TBitmap;
  GraphType: byte;
begin
  if not Assigned(Imagen) then  Exit;

  Jpg := nil;
  stream := nil;
  stream := TMemoryStream.Create;
  try
      try
//            CampoBLOB.SaveToStream(stream);
            if stream.Size > 0 then
            begin
                  stream.Position := 0;
//                  stream.Read( GraphType, 1);
{                  case GraphType of
                    gtBitmap:  Imagen.Picture.LoadFromStream(stream);
                    gtIcon:    Imagen.Icon.LoadFromStream(stream);
                    gtMetafile: Imagen.Metafile.LoadFromStream(stream);
                    gtJpeg:
                      begin
                        Jpg := TJpegImage.Create;
                        Jpg.LoadFromStream(stream);
                        Imagen.Assign(Jpg);
                        Jpg.Free;
                      end
                    else
                      Imagen.Assign(nil); // Limpiar la imagen
                  end;
}            end
            else
              Imagen.Assign(nil);
      except
        Imagen.Assign(nil);
      end;
  finally
      stream.Free;
  end;
end;

function ArrayBufferToString(Blob: TJsArrayBuffer): string;
begin

end;

function Base64ToArrayBuffer(str: string): TJSArrayBuffer;
  var
    BufView: TJSUInt8Array;
    BinaryString: string;
    I: Integer;
  begin
    BinaryString := window.atob(str);
    Result := TJSArrayBuffer.new(Length(BinaryString));
    BufView := TJSUInt8Array.new(Result);
    for I := 0 to Length(BinaryString) - 1 do
      BufView[I] := TJSString(BinaryString).charCodeAt(I);
  end;

function ArrayBuffertoBase64(Blob:TJSArrayBuffer):string;
  var
    BufView: TJSUInt8Array;
    BinaryString: string;
    I: Integer;
  begin
          asm
              var binary = '';
              var bytes = new Uint8Array( Blob );
              var len = bytes.byteLength;
              for (var i = 0; i < len; i++) {
                  binary += String.fromCharCode( bytes[ i ] );
              }
              return window.btoa( binary );

          end;

  end;


function StringtoClientDataSet(Cadena:String):TClientDataset;
begin

end;

function StringtoJSON(Cadena: String): String;
var
    i, ar:integer;
    Lista:TStringList;
    NombreCampo:string;
    Valor:string;
    JSONCliente: TJSONObject;
    Campo: TJSONObject;
    JSonString:STring;
    Cliente:TClientDataset;

    TipoCampo:TFieldType;
    Tamano:integer;
    Requerido:boolean;
    Field:TField;
    Dia:TDate;
    Hora:TTime;
    Fecha:TDateTime;
    Numero:integer;
    NroDecimal:double;




begin

     Lista:=TStringList.Create;
     JSONCliente := TJSONObject.Create;
     Cliente:=TClientDataset.Create(nil);
//     JSONCliente := TJSONArray.Create;
     try
         Lista.Delimiter:='^';
         lista.StrictDelimiter :=true ;
         Lista.DelimitedText:=Cadena;

         if Lista.Count > 0 then
            begin
                  for ar:=0 to Lista.Count-1 do
                      begin
                        NombreCampo:=Copy(Lista[ar],1,Pos('=',Lista[ar]));
                        NombreCampo:=Trim( StringReplace(NombreCampo,'=','',[rfReplaceAll]) );
                        Valor:=copy( Lista[ar],Pos('=',Lista[ar])+1, length(Lista[ar])-Pos('=',Lista[ar]));
                        Campo := TJSONObject.Create;
                        JSONCliente.addpair(NombreCampo, Valor);
                        if Length(valor)>10 then TipoCampo:=ftstring
                          else
                           if TryStrToInt (Valor, Numero ) then TipoCampo:=ftInteger
                              else
                                if TryStrToDate(Valor,Fecha) then TipoCampo:=ftDate
                                  else
                                   if TryStrToTime(Valor,Fecha ) then TipoCampo:=ftTime
                                     else
                                       if TryStrToDateTime(Valor,Fecha ) then TipoCampo:=ftDateTime
                                          else
                                             if TryStrToFloat (Valor, NroDecimal ) then TipoCampo:=ftFloat
                                                else
                                                   TipoCampo:=ftString;

                        if TipoCampo=ftString then Tamano:=Length(Valor)+10 else Tamano:=0;
                        Requerido:=False;
                        Cliente.FieldDefs.Add(NombreCampo,TipoCampo,Tamano,requerido);
                      end;

                  for i:=0 to Cliente.FieldDefs.Count-1 do
                      begin
                           Field:=CreoField(Cliente, i, Cliente.FieldDefs[i].DataType);
                      end;
                  Cliente.Active:=True;
                  Cliente.Append;


                  //console.log(Cliente.Fields);
                  for ar:=0 to Lista.Count-1 do
                      begin
                        NombreCampo:=Copy(Lista[ar],1,Pos('=',Lista[ar]));
                        NombreCampo:=Trim( StringReplace(NombreCampo,'=','',[rfReplaceAll]) );
                        Valor:=copy( Lista[ar],Pos('=',Lista[ar])+1, length(Lista[ar])-Pos('=',Lista[ar]));


                        case Cliente.FieldByName(NombreCampo).DataType of
                             ftInteger : Cliente.FieldByName(NombreCampo).AsInteger:=StrToInt(Valor);
                             ftFloat : Cliente.FieldByName(NombreCampo).AsFloat:=StrToFloat(Valor);
                             ftDate,ftTime, ftDateTime : Cliente.FieldByName(NombreCampo).AsDateTime:=StrToDateTime(Valor);
                             else   Cliente.FieldByName(NombreCampo).asstring:= valor  ;
                        end;

                      end;
                  Cliente.Post;
                  JSonString := JSONCliente.ToString;

                  try
//                      JsonToDataset(Cliente,JSonString);
                      Result :=DataSetToJSON(Cliente, true, true, false);
                  finally
                  end;
            end
            ELSE result:='';

     finally
         Lista.Free;
         JSONCliente.Free;
         Cliente.Free;
     end;

end;


procedure DibujarLetraImagen(Imagen:TImageControl; Letra:char);
begin
    if imagen<>nil then
        begin
{               Imagen.Picture.Canvas.FillRect(Imagen.BoundsRect);
               Imagen.Picture.Bitmap.Canvas.Pen.Color:=clBlack;
               Imagen.Picture.Bitmap.Canvas.TextOut(10,10, Letra);
}
        end;
end;

function ConvertFechaFirebird(Fecha:TDate):string;
begin
        Result:=formatdatetime('yyyy/mm/dd',fecha);
end;


function ConvertHoraFirebird(Hora:TTime):string;
begin
        result:=formatdatetime('hh:mm:ss',Hora);
end;


function GetVersionMensaje(Cadena:String):string;
begin
    Result:='';
    if Cadena<>'' then
      if Length(Cadena)>=7 then
        begin
            Result:=Copy(Cadena,1,5);
        end;
end;

function EsVersion(Cadena:String):boolean;
begin
    Result:=False;
    if Cadena<>'' then
      if Length(Cadena)>=7 then
        begin
            if Copy(Cadena,1,3)='VER' Then Result:=True;
        end;
end;

function GetNumeroVersionMensaje(Cadena:String):integer;
begin
    Result:=-1;
    if Cadena<>'' then
      if Length(Cadena)>=7 then
        begin
            Result:=StrToInt(Copy(Cadena,4,4));
        end;
end;

function SetVersion(Numero:integer; Paquete: String):String;
begin
    case numero  of
        0 : result := 'VER'+RellenoString(IntToStr(Numero),4,'0',True)+Paquete;
        1 : result := 'VER'+RellenoString(IntToStr(Numero),4,'0',True)+Zipcadena(Paquete);
        5 : result := 'VER'+RellenoString(IntToStr(Numero),4,'0',True)+ZipCadena(Paquete);
        10..100 : result := 'VER'+RellenoString(IntToStr(Numero),4,'0',True)+ZipCadena(Paquete);
    end;

end;

function PackJWT(JWT, IDS, Data:String):string;
var
   JObj1,JObj2:TJSONObject;
   JparIDS, JParJWT, JParData:TJSONPair;
   JObject:TJSONObject;
begin
       JParJWT:=TJSONPair.Create('JWT',JWT);
       JParIDS:=TJSONPair.Create('IDS',IDS);

       JParData:=TJSONPair.Create('DATA',Zipcadena(DATA) );

       JObject:=TJSONObject.Create;
       JObject.AddPair(JparJWT);
       JObject.AddPair(JparIDS);
       JObject.AddPair(JParData);

       result:=JObject.value;
       if result='' then result:=JObject.ToString;
       JObject.Free;

end;

function GetClienteFromRequestMVC(Cliente:TClientDataSet; StringEntrada : string) : boolean;
var
  SDatos, Data:string;
  Cabecera:string;
  CadUnzip:String;
  NumeroVersion:integer;
  ll:string;
begin
       result:=False;
       if StringEntrada='' then exit;

       Cabecera:=Copy(StringEntrada,1,10);
       if EsVersion(Cabecera) then
        begin
             Cabecera:=Copy(StringEntrada,1,7);
             SDatos:=copy(StringEntrada,8,length(StringEntrada)-7);
             if Cliente.Active then Cliente.EmptyDataSet;
             NumeroVersion:=GetNumeroVersionMensaje(Cabecera);
             if NumeroVersion=0 then
                  begin
                        try
                            JSONToDataset(Cliente,SDatos);
                            Result:=True;
                        finally

                        end;
                  end
                  else
                     if NumeroVersion=1 then
                          begin
                                try
//                                    SDatos:=UnZipCadena(SDatos);
                                    Data:=GetDATA(StringEntrada);
                                    JSONToDataset(Cliente,Data);
                                    Result:=True;
                                finally

                                end;
                          end
                          else
                           if NumeroVersion>=5 then
                                begin
                                      try
                                          if SDatos<>'' then
                                            begin
//                                              SDatos:=UnZipCadena(SDatos);
                                              ll:='a';
                                              Data:=GetDATA(StringEntrada);
                                              ll:='b';
                                              JSONToDataset(Cliente,Data);
                                              ll:='c';
                                              Result:=True;
                                            end
                                            else
                                                begin
                                                  ShowMessage('SDatos es vacio');
                                                  Result:=False;
                                                end;
                                      except
                                          ShowMessage(' No pudo leer los datos ');


                                      end;
                                end;
{                                        else
                                ShowMessage(Sdatos);}
        end
        else
        begin
           if Cabecera='{"Fields":' then
            begin
//                 ShowMessage('SDatos es vacio');
                 SDatos:=StringEntrada;
                 if Cliente.Active then Cliente.EmptyDataSet;
                  try
                      JSONToDataset(Cliente,SDatos);
                      Result:=True;
                  finally

                  end;
            end
        end;
end;

function GetDatosdeMensaje(Cadena:String):String;
var
  SDatos:string;
  Cabecera:string;
  CadUnzip:String;
  Data:String;
begin
       result:='';
       if Cadena='' then exit;
       Cabecera:=Copy(Cadena,1,10);
       Data:=Cadena;
       Delete(Data,1,7);

       if EsVersion(Cabecera) then
        begin
             Cabecera:=Copy(Cadena,1,7);
             Case GetNumeroVersionMensaje(Cadena) of
//                 0 : Result:=copy(Cadena,8,length(Cadena)-7);
                 0 : Result:=Data;
                 1 : Result:=UnZipCadena(Data);
                 5..100 : Result:=UnZipCadena(Data);
             End;
        end;
end;

function GetJWT(Cadena:String):String;
var
    Datos:String;
    JWObject:TJSONObject;
    JJWT:TjsonObject;
begin
       Result:='';
       if EsVersion(Cadena) then
        begin
             if GetNumeroVersionMensaje(Cadena)>=1 then
                begin
                     Datos:=GetDatosdeMensaje(Cadena);
                     JWObject:=TJSONObject.ParseJSONValue(Datos) as TJSONObject;
                     if JWObject<>nil then
                      begin
                        Result:= JWObject.GetValue('JWT').value;
                        JWObject.Free;
                      end;
                end;
        end;
end;

function GetDATA(Cadena:String):String;
var
    Datos:String;
    JWObject:TJSONObject;
    JJWT:TjsonObject;
begin
       Result:='';
       if EsVersion(Cadena) then
        begin
             if GetNumeroVersionMensaje(Cadena)>=1 then
                begin
                     Datos:=GetDatosdeMensaje(Cadena);
                     JWObject:=TJSONObject.ParseJSONValue(Datos) as TJSONObject ;
                     if JWObject<>nil then
                      begin
                        Result:= UnZipCadena(JWObject.GetValue('DATA').value);
                        JWObject.Free;
                      end;
                end;
        end;
end;

function GetIDS(Cadena:String):String;
var
    Datos:String;
    JWObject:TJSONObject;
    JJWT:TjsonObject;
begin
       Result:='';
       if EsVersion(Cadena) then
        begin
             if GetNumeroVersionMensaje(Cadena)>=1 then
                begin
                     Datos:=GetDatosdeMensaje(Cadena);
                     JWObject:=TJSONObject.ParseJSONValue(Datos) as TJSONObject ;
                     if JWObject<>nil then
                      begin
                        Result:= JWObject.GetValue('IDS').value;
                        JWObject.Free;
                      end;
                end;
        end;
end;

function GetDatoJSON(Dato,Cadena:String):String;
var
    Datos:String;
    JWObject:TJSONObject;
    JJWT:TjsonObject;
begin
       Result:='';
       if EsVersion(Cadena) then
        begin
             if GetNumeroVersionMensaje(Cadena)>=1 then
                begin
                     Datos:=GetDatosdeMensaje(Cadena);
                     JWObject:=TJSONObject.ParseJSONValue(Datos) as TJSONObject ;
                     if JWObject<>nil then
                        begin
                            if dato='DATA' then
                                Result:= UnZipCadena(JWObject.GetValue(Dato).value)
                                else
                                Result:= JWObject.GetValue(Dato).value;
                            JWObject.Free;
                        end;
                end;
        end;
end;

function PackMensaje(JWT, IDS, IDBD, IDE, Data:String):string;
var
   JObj1,JObj2:TJSONObject;
   JparIDE, JParIDBD, JparIDS, JParJWT, JParData:TJSONPair;
   JObject:TJSONObject;
begin
       if JWT='' then JWT:='0';
       if IDS='' then IDS:='0';
       if IDBD='' then IDBD:='0';
       if IDE='' then IDE:='0';
       if Data='' then Data:='0';

       JParJWT:=TJSONPair.Create('JWT',JWT);
       JParIDS:=TJSONPair.Create('IDS',IDS);
       JparIDE:=TJSONPair.Create('IDE',IDE);
       JParIDBD:=TJSONPair.Create('IDBD',IDBD);
//       JParData:=TJSONPair.Create('DATA',DATA);

       JParData:= TJSONPair.Create('DATA',Zipcadena(Data));

       JObject:=TJSONObject.Create;
       JObject.AddPair(JparJWT);
       JObject.AddPair(JparIDS);
       JObject.AddPair(JparIDE);
       JObject.AddPair(JParIDBD);
       JObject.AddPair(JParData);

       result:=JObject.ToJSON;
       JObject.Free;
end;
function PackMensaje(JWT, IDS, IDBD, IDE, IDEntidad, Data:String):string;
var
   JObj1,JObj2:TJSONObject;
   JParIDEntidad, JparIDE, JParIDBD, JparIDS, JParJWT, JParData:TJSONPair;
   JObject:TJSONObject;
   Cad:String;
   Stream:TMemoryStream;
   J:TJson;

begin

       if JWT='' then JWT:='0';
       if IDS='' then IDS:='0';
       if IDBD='' then IDBD:='0';
       if IDE='' then IDE:='0';
       if IDEntidad='' then IDEntidad:='0';
       if Data='' then Data:='0';

       JParJWT:=TJSONPair.Create('JWT',JWT);
       JParIDS:=TJSONPair.Create('IDS',IDS);
       JparIDE:=TJSONPair.Create('IDE',IDE);
       JParIDBD:=TJSONPair.Create('IDBD',IDBD);
       JParIDEntidad:=TJSONPair.Create('IDENTIDAD',IDENTIDAD);

       JParData:= TJSONPair.Create('DATA',Zipcadena(Data));

       JObject:=TJSONObject.Create;

       JObject.AddPair(JparJWT);
       JObject.AddPair(JparIDS);
       JObject.AddPair(JparIDE);
       JObject.AddPair(JParIDBD);
       JObject.AddPair(JParIDEntidad);
       JObject.AddPair(JParData);

       result:=JObject.ToJSON;
       JObject.Free;
end;


procedure CopioAnsiStringToStream(Const Astring : String; Stream:TMemoryStream);
var
  ar:integer;
begin
  if Length(Astring)>0 then
    begin
        for ar:=1 to Length(AString) do
              Stream.WriteData(Astring[ar]);
    end;
end;

function StringToStream(const AString: string): TStream;
begin
  Result := TStringStream.Create(AString);
end;

function StreamToString(Stream: TStream): string;
var
  ms: TStringStream;
begin
  Result := '';
  ms := TStringStream.Create;
  try
    ms.LoadFromStream(Stream);
    Result:=ms.DataString;
  finally
    ms.Free;
  end;
end;


function AgregoParValue(Objeto:TJSONObject; Nombre:string; Valor:String):boolean;
begin
        Result:=False;
        if Objeto<>nil then
            begin
                 if Objeto.GetValue(Nombre)<>nil  then
                  begin
                     Objeto.AddPair(Nombre,Valor);
                     Result:=True;
                  end;
            end;
end;
function AgregoParValue(Objeto:TJSONObject; Nombre:string; Valor:Integer):boolean;
begin
        Result:=False;
        if Objeto<>nil then
            begin
                 if Objeto.GetValue(Nombre)<>nil then
                  begin
                     Objeto.AddPair(Nombre,Valor);
                     Result:=True;
                  end;
            end;
end;
function AgregoParValue(Objeto:TJSONObject; Nombre:string; Valor:Double):boolean;
begin
        Result:=False;
        if Objeto<>nil then
            begin
                 if Objeto.GetValue(Nombre)<>nil then
                  begin
                     Objeto.AddPair(Nombre,FloatToStr(Valor));
                     Result:=True;
                  end;
            end;
end;
function AgregoParValue(Objeto:TJSONObject; Nombre:string; Valor:Boolean):boolean;
begin
          Result:=False;
        if Objeto<>nil then
            begin
                 if Objeto.GetValue(Nombre)<>nil then
                  begin
                     if Valor then
                          Objeto.AddPair(Nombre,'TRUE')
                          else
                          Objeto.AddPair(Nombre,'FALSE');
                     Result:=True;
                  end;
            end;
end;

function EditCD(CD:TClientDataSet):boolean;
begin
      Result:=False;
      if CD<>Nil then
        if (CD.State=dsBrowse) then
          begin
              try
                  CD.Edit;
                  result:=True;
              except
                  result:=False;
              end;
          end
          else
            if (CD.State=dsEdit) or (CD.State=dsInsert)  then
              Result:=True;
        
end;



function GetClientDatasetUnicos(Fuente:TClientDataSet; CampoClave:string):TClientDataSet;
var
  i, ar,ab:integer;
  CDFiltro, CDTemp:TClientDataSet;
  Filtro, FiltroFinal:String;
  ListaCamposClave:TStringList;
  Field:TField;
  ValorString:string; ////Agregado el 05-08-2021 despues que cambie por Delphi Comunity 10.4 al momento de pasar el string a value, eliminaba el ultimo caracter de espacio en la cadena
//  CD2021: TFDMemTable;
   procedure CreoCDFiltro;
   begin
         CDFiltro:=TClientDataSet.Create(nil);
//         CopioEstructuraDataset(Fuente, CDFiltro, False);
         CDFiltro.Open;
         CDFiltro.Filter:='';
         CDFiltro.Filtered:=False;
   end;
begin
      ListaCamposClave:=TStringList.Create;
      try
           ListaCamposClave.Delimiter:=';';
           ListaCamposClave.DelimitedText:=CampoClave;

           Result:=TClientDataSet.Create(nil);

//           CopioEstructuraDataset(Fuente, Result, False);
           Result.Open;



           if (Fuente<>nil) then
                begin
                      CDTemp:=TClientDataSet.Create(nil);
//                      CopioEstructuraDataset(Fuente, CDTemp, False);
                      CDTemp.Open;
//                      CopioTabla(Fuente,CDTemp,True);
                      CreoCDFiltro;
                      try

                      ShowMessage('cantidad '+IntToStr(cdtemp.RecordCount));
                           CDTemp.First;
                      ShowMessage('2');
                           for ar := 1 to CDTEMP.RecordCount do
                            begin
                                FiltroFinal:='';
                                for I := 0 to ListaCamposClave.Count-1 do
                                  begin
                                       Filtro:='';
                                       if Fuente.FindField(ListaCamposClave[i])<>nil then
                                       begin
                                           if ( (CDTemp.FieldDefs[CDTemp.FieldDefs.Find(ListaCamposClave[i]).Index].DataType=ftString) or
                                                (CDTemp.FieldDefs[CDTemp.FieldDefs.Find(ListaCamposClave[i]).Index].DataType=ftwideString) )
                                             then
                                                  begin
                                                    ValorString:=CDTemp.FieldByName(ListaCamposClave[i]).AsString;
                                                    if ValorString[length(ValorString)]=' ' then
                                                      begin
                                                          ValorString:=TrimRight(ValorString);
                                                          Filtro:=ListaCamposClave[i] + ' LIKE '+QuotedStr(ValorString+'%')
                                                      end
                                                      else
                                                      Filtro:=ListaCamposClave[i] + ' = '+QuotedStr(ValorString)


                                                  end
                                                  else
                                                      Filtro:=QuotedStr(ListaCamposClave[i]) + '  = '+  CDTemp.FieldByName(ListaCamposClave[i]).AsString;
                                           if i<listacamposclave.Count-1 then
                                              begin
                                                  FiltroFinal:=FiltroFinal+Filtro +' AND ';
                                              end
                                              else
                                              begin
                                                  FiltroFinal:=FiltroFinal+Filtro;
                                              end;

                                        end;
                                  end;

                                Result.Filter:='';
                                Result.Filtered:=True;

                                Result.Filter:=FiltroFinal;
                                Result.Filtered:=True;
                                CDFiltro.Filter:=FiltroFinal;
                                CDFiltro.Filtered:=True;
//                                if CDFiltro.RecordCount=0 then
                                if Result.RecordCount=0 then
                                    begin
                                         CDFiltro.Filter:='';
                                         CDFiltro.Filtered:=False;
                                         Result.Append;
                                         try
                                             for ab:=0 to Result.FieldCount-1 do
                                              begin
                                                   if ( (CDTemp.Fields[ab].DataType=ftString) or
                                                        (CDTemp.Fields[ab].DataType=ftwideString) )
                                                        then
                                                          begin
                                                              ValorString:=CDTemp.FieldByName(CDTemp.Fields[ab].FieldName).asstring;

                                                             Result.FieldByName(CDTemp.Fields[ab].FieldName).asstring:=TrimRight(CDTemp.FieldByName(CDTemp.Fields[ab].FieldName).asstring)
                                                          end
                                                           else
                                                           Result.FieldByName(CDTemp.Fields[ab].FieldName).Value:=CDTemp.FieldByName(CDTemp.Fields[ab].FieldName).Value;
                                              end;
                                            for I := 0 to ListaCamposClave.Count-1 do
                                              begin
                                                   if Fuente.FindField(ListaCamposClave[i])<>nil then
                                                     begin
                                                           if ( (CDTemp.FieldDefs[CDTemp.FieldDefs.Find(ListaCamposClave[i]).Index].DataType=ftString) or
                                                                (CDTemp.FieldDefs[CDTemp.FieldDefs.Find(ListaCamposClave[i]).Index].DataType=ftwideString) )
                                                             then
                                                              begin
                                                                  ValorString:=CDTemp.FieldByName(ListaCamposClave[i]).AsString;
                                                                  ValorString:=TrimRight(ValorString);

                                                                  Result.FieldByName(ListaCamposClave[i]).asstring:=ValorString;
                                                              end
                                                                  else
                                                                  Result.FieldByName(ListaCamposClave[i]).Value:=CDTemp.FieldByName(ListaCamposClave[i]).value;
                                                     end;
                                              end;
                                              Result.Post;
                                              ShowMessage('Cantidad: '+IntToStr(Result.RecordCount)+ ' ID Agregado= '+CDTemp.FindField('id_entidad').AsString);
                                              CDFiltro.Free;
                                              CreoCDFiltro;
                                              CopioTabla(Result,CDFiltro);
                                              ShowMessage('Cantidad de cdfiltro: '+IntToStr(CDFiltro.RecordCount));


                                         except

                                         end;

                                    end;
                                  CDTemp.Next;
                                Result.Filtered:=False;
                            end;
                      finally
                        CDTemp.Free;
                        CDFiltro.Free;
                        Result.Filter:='';
                        Result.Filtered:=False;
                      end;
                end;
      finally
           ListaCamposClave.Free;
//           CD2021.FREE;
      end;
end;

function GetFechayyymmdd(Fecha:TDate):string;
var
  dia,mes,anio:integer;
  resultado:string;
begin
        asm
              var dateObj = new Date(Fecha);
              var month = dateObj.getUTCMonth() + 1; //months from 1-12
              var day = dateObj.getUTCDate();
              var year = dateObj.getUTCFullYear();

              resultado = year + "/" + month + "/" + day;

        end;
end;

function ResolveAfter2Seconds: TJSPromise;
// returns immediately with a Promise,
// which after 2 seconds gets resolved
begin
  Result:=TJSPromise.new(
  procedure(resolve, reject : TJSPromiseResolver)
    begin
    window.setTimeout(procedure
      begin
      resolve('resolved');
      end,
      2000); // wait 2 seconds
    end

    );
end;

function GetClienteFromRequestMVCPromise(Cliente:TClientDataSet; StringEntrada : string) : TJSPromise;
var
  SDatos, Data:string;
  Cabecera:string;
  CadUnzip:String;
  NumeroVersion:integer;
  ll:string;
begin
       result:=
       TJSPromise.new(
                  procedure(resolve, reject: TJsPromiseResolver)
                  begin
                         if StringEntrada<>'' then
                              begin
                                   Cabecera:=Copy(StringEntrada,1,10);
                                   if EsVersion(Cabecera) then
                                        begin
                                             Cabecera:=Copy(StringEntrada,1,7);
                                             SDatos:=copy(StringEntrada,8,length(StringEntrada)-7);
                                             if Cliente.Active then Cliente.EmptyDataSet;
                                             NumeroVersion:=GetNumeroVersionMensaje(Cabecera);
                                             if NumeroVersion=0 then
                                                  begin
                                                        try
                                                            JSONToDataset(Cliente,SDatos);
                                                            resolve(True);
                                                        finally

                                                        end;
                                                  end
                                                  else
                                                     if NumeroVersion=1 then
                                                          begin
                                                                try
                                                                    Data:=GetDATA(StringEntrada);
                                                                    JSONToDataset(Cliente,Data);
                                                                    resolve(True);
                                                                finally

                                                                end;
                                                          end
                                                          else
                                                           if NumeroVersion>=5 then
                                                                begin
                                                                      try
                                                                          if SDatos<>'' then
                                                                            begin
                                                                              ll:='walter aaaa';
                                                                              Data:=GetDATA(StringEntrada);
                                                                              ll:='b';
                                                                              JSONToDataset(Cliente,Data);
                                                                              ll:='c';
                                                                              resolve(True);
                                                                            end
                                                                            else
                                                                                begin
                                                                                  ShowMessage('SDatos es vacio');
                                                                                  resolve(False);
                                                                                end;
                                                                      except
                                                                          ShowMessage('No pudo cargar los datos ');
//                                                                          ShowMessage(StringEntrada);
                                                                      end;
                                                                end;

                                        end
                                    else
                                          begin
                                             if Cabecera='{"Fields":' then
                                              begin
                                                   SDatos:=StringEntrada;
                                                   if Cliente.Active then Cliente.EmptyDataSet;
                                                    try
                                                        JSONToDataset(Cliente,SDatos);
                                                        resolve(True);
                                                    finally

                                                    end;
                                              end
                                          end;

                              end
                          else
                            resolve(False);
                  end
                   );


end;

function UbicarClave(Grilla:TDBTableControl; CD:TClientDataSet; ACol,ARow:integer; Campo:String):boolean;
var
    CDTemp:TClientDataSet;
    Valor:String;
begin
      if Grilla.CellElements[ACol,ARow].childElementCount>0 then
        begin
               Valor:=Grilla.CellElements[ACol,ARow].firstElementChild.innerHTML;
        end
        else valor:=Grilla.Cells[ACol,ARow];
      if CD.Locate(Campo,valor, [ ] ) then
          begin
            Result:=True;
          end
              else
              Result:=False;


{        CDTemp:=TWebClientDataSet.Create(nil);
        try
            CDTemp.Open;
            CopioRegistro2022(CD,CDTemp);

            CDTemp.Filter:= Campo +  ' = '+ Grilla.Cells[0,ARow];
            CDTemp.Filtered:=True;
            if CDTemp.RecordCount>0 then
              Result:=true
              else
                begin
                    Result:=False;
                end;
        finally
            CDTemp.Free;
        end;
}
end;


function SumoCampo(DataSet:TClientDataSet; Campo, Filtro : String ) : Double;
var
    ar:integer;
    Valor:Double;
begin
     if (DataSet<>nil) and (DataSet.Active) and (dataset.RecordCount>0) then
      begin
         if Filtro<>'' then
          begin
            DataSet.Filter:=filtro;
            DataSet.filtered:=true;
          end;
         Valor:=0;
         DataSet.First;
         if assigned(DataSet) and (DataSet.FindField(campo)<>nil) then
           begin
              for ar:=1 to DataSet.RecordCount do
                begin
                    if DataSet.FindField(campo)<>nil then
                    begin
                      valor:=valor+DataSet.fieldbyname(campo).AsFloat;
                    end;
                    DataSet.Next;
                end;
           end;
         Result:=valor;
         DataSet.Filter:='';
         DataSet.Filtered:=false;
      end
        else
          Result:=-1;
end;



function ValidateInputType(inputType: string; Achar:char):boolean;
var
    Fresult: boolean;
begin
    // default to true
    Fresult := true;
    asm
        console.log(Achar);
    end;
    if inputType = 'jdFloat' then
        begin
            if not( Achar in ['0'..'9','n'] )
                then Fresult := false;
        end
        Else
            if inputType = 'onlyletters' then
                  if not (Achar in ['a'..'z','A'..'Z'] )
                  then Fresult := false;
    result := Fresult;
end;


function ProcesarTXTFloat(TxtFloat:String):string;
var
    ar:integer;
    Cad:string;
    PuntoDecimal:Boolean;
begin
     PuntoDecimal:=False;
     Cad:='';
     for ar := 1 to Length(TxtFloat) do
          begin
              if (TxtFloat[ar]='.') and not PuntoDecimal then
                begin
//                    Cad:=Cad+',';
                      if FormatSettings.DecimalSeparator='.' then
                          Cad:=Cad+'.'
                          else
                            if FormatSettings.DecimalSeparator=',' then
                                Cad:=Cad+',';
                      PuntoDecimal:=True;
                end
                else
                  Cad:=   cad+TxtFloat[ar];
          end;
     Result:=Cad;
end;

procedure  FormatearCamposFloat(Dataset:TClientDataSet);
var
  Mask:string;
  ar:integer;

begin
    if Dataset<>nil then
      begin
          if FormatSettings.DecimalSeparator=',' then
              Mask:='###,###,##0.00'
              else
                if FormatSettings.DecimalSeparator='.' then
                      Mask:='###.###.##0,00';

          for ar := 0 to Dataset.FieldCount-1  do
              begin
                  if Dataset.Fields[ar].DataType=ftFloat then
                    begin
                        TFloatField(Dataset.Fields[ar]).DisplayFormat:=Mask;
                    end;
              end;



      end;

end;

function GetControl(Parent:TForm;  Nombre:string):Tcontrol;
var
  i:integer;
begin
    Result:=nil;
    if Parent<>nil then
      if Parent.ControlCount>0 then
        begin
            for i := 0 to Parent.ControlCount-1 do
              begin
                      if UpperCase(Parent.Controls[i].Name)=UpperCase(Nombre) then
                            Result:= TControl(Parent.Controls[i]);
              end;
        end;
end;


{procedure ConfigurarConexion(Conexion: TWebHttpRequest; Nivel: integer; Metodo, Data: string);
var
    Cad:String;
    Mensaje, Mensajej:String;
begin

      if pos('/',Metodo)=1 then
          Conexion.url:=http+host+puerto+Metodo
          else
          Conexion.url:=http+host+puerto+'/'+Metodo;

      Conexion.Command:=httpPOST;

      MensajeJ:=PackMensaje(FJWT,IntToStr(FIDS),IntToStr(FIDE),IntToStr(FIDENTIDAD),IntToStr(FIDEntidad),Data) ;
      Mensaje:=SetVersion(Nivel,MensajeJ);

      Conexion.Headers.Clear;
      Conexion.Headers.AddPair('Content-Type', 'application/json');
      Conexion.postdata:= Mensaje;


end;

procedure Conectar(Conexion: TWebHttpRequest; Nivel: integer; Metodo, Data: string);
var
    Cad:String;
    Mensaje, Mensajej:String;
begin

      if pos('/',Metodo)=1 then
          Conexion.url:=http+host+puerto+Metodo
          else
          Conexion.url:=http+host+puerto+'/'+Metodo;

      Conexion.Command:=httpPOST;

      MensajeJ:=PackMensaje(FJWT,IntToStr(FIDS),IntToStr(FIDE),IntToStr(FIDENTIDAD),IntToStr(FIDEntidad),Data) ;
      Mensaje:=SetVersion(Nivel,MensajeJ);

      Conexion.Headers.Clear;
      Conexion.Headers.AddPair('Content-Type', 'application/json');
      Conexion.postdata:= Mensaje;
      Conexion.execute;
end;
}
end.
