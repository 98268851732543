unit UBase;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Imaging.GIFImg,  WEBLib.REST,
  Data.DB, WEBLib.DB, WEBLib.CDS, Vcl.Forms, WEBLib.Actions, WEBLib.ExtCtrls,
  Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls;

type
  TBase = class(TForm)
    CDContacto: TClientDataSet;
    CDContactoNombre: TStringField;
    CDContactoEMail: TStringField;
    CDContactoMensaje: TStringField;
    CDContactoAsunto: TStringField;
    DTContacto: TDataSource;
    ConexionEnviar: THttpRequest;
    Conexion: THttpRequest;
    MensajeEspera: TWaitMessage;
    PanelEfectos: TPanel;
    BotonEfectos: TButton;
    procedure WebFormCreate(Sender: TObject);
    procedure ConexionError(Sender: TObject; ARequest: TJSXMLHttpRequest;
      Event: TEventListenerEvent; var Handled: Boolean);
    procedure ConexionEnviarResponse(Sender: TObject; AResponse: string);
    procedure ConexionEnviarError(Sender: TObject;
      ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord;
      var Handled: Boolean);
    procedure ConexionRequestResponse(Sender: TObject;
      ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure BotonEfectosMouseDown(Sender: TObject; Button: TMouseButton;
      Shift: TShiftState; X, Y: Integer);
    procedure BotonEfectosMouseUp(Sender: TObject; Button: TMouseButton;
      Shift: TShiftState; X, Y: Integer);
    procedure PanelEfectosMouseEnter(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
    procedure PanelEfectosMouseMove(Sender: TObject; Shift: TShiftState; X,
      Y: Integer);
    procedure PanelEfectosMouseLeave(Sender: TObject);
  private
    FHost: string;
    FPuerto: string;
    FHTTP: String;
{    FID: integer;
    FIDE: integer;
    FIDG: integer;
}    { Private declarations }
  public
    { Public declarations }

{    property ID : integer read FID write FID;
    property IDE : integer read FIDE write FIDE;
    property IDG : integer read FIDG write FIDG;
}
    procedure BootstrapWindowShow; overload;
    procedure BootstrapWindowShow(Proc:TModalResultProc);  overload;
    procedure BootstrapWindowHide;

    property Host:string read FHost write FHost;
    property Puerto:string read FPuerto write FPuerto;
    property HTTP:String read FHTTP write FHTTP;

    procedure MostrarVentanaBoot(IDVentana, Titulo:sTRING; ok,cancel:TModalResultProc);
    procedure MostrarInfo(Mensaje: string);

    procedure EnviarMail;

    procedure ArreglarClientDatasets;

    procedure LimpiarAnimancion;
    procedure Animar(Elemento:TJSHTMLElement; TipoAni:String);

//    procedure BootStrapEstaSeguro(Mensaje: string; Botonok,BotonCancelar:String; ProcAceptar,ProcCancelar:TModalResultProc);
    procedure BootStrapEstaSeguro(Mensaje: string; Botonok,BotonCancelar:String; ProcAceptar,ProcCancelar:TNotifyEvent);
    procedure EstaSeguro(Titulo, Mensaje, Botonok,BotonCancelar:String; ProcAceptar,ProcCancelar:TNotifyEvent);
    procedure BootstrapInfo;


    procedure MostrarVentana;
    procedure OcultarVentana;


    procedure EstaSeguroAceptarClick(Sender: TObject); virtual;
    procedure EstaSeguroCancelarClick(Sender: TObject); virtual;

    procedure MostrarMensaje(Mensaje:string);
    protected procedure LoadDFMValues; override; end;

var
  Base: TBase;
implementation
USES UModuloDatos, UEstaSeguro, UUtilesWEB;

procedure TBase.Animar(Elemento: TJSHTMLElement; TipoAni:String);

      procedure TerminarAnimacion;
      begin
            Elemento.classlist.remove('animate__animated', Tipoani);
            Elemento.removeEventListener('animationend',@TerminarAnimacion);
      end;
begin
      if not Elemento.classList.contains('animate__animated') then
        begin
            Elemento.classlist.add('animate__animated',TipoAni);
            Elemento.addEventListener('animationend',@TerminarAnimacion);
        end;
end;

procedure TBase.ArreglarClientDatasets;
begin
end;


procedure TBase.MostrarVentana;
begin
   asm
         $("#formplace").modal("show");
   end;
end;

procedure TBase.OcultarVentana;
begin
   asm
         $("#formplace").modal("hide");
   end;
end;

procedure TBase.BootStrapEstaSeguro(Mensaje: string; Botonok,BotonCancelar:String; ProcAceptar,
  ProcCancelar: TNotifyEvent);

 var
  Eform: TEstaSeguro;

  procedure AfterShowModal(AValue: TModalResult);
  begin
     ocultarventana;
  end;

  // async called OnCreate for TForm2
  procedure AfterCreate(AForm: TObject);
  begin
       EForm.Mensaje.Caption:=Mensaje;
//       Eform.Mensaje.Lines.Text:=Mensaje;
       EForm.BotonAceptar.OnClick:=procaceptar;
       EForm.BotonCancelar.OnClick:=ProcCancelar;
       mostrarventana;
  end;

begin
       EForm := TEstaSeguro.CreateNew('contenido', @AfterCreate);

end;

procedure TBase.BootstrapInfo;
begin
    asm
    BootstrapDialog.show({
            title: 'Say-hello dialog',
            message: 'WJC!'
        });

    end;

end;

procedure TBase.BootstrapWindowHide;
begin
//          $.modal.close();
   asm
         $("#formplace").modal("hide");
   end;
end;

procedure TBase.BootstrapWindowShow(Proc: TModalResultProc);
begin
//       $("#formplace").modal();
{          $("#formplace").modal({
                        keyboard: true,
                        show: true);
}
   asm
          $("#formplace").on('hidden.bs.modal', Proc);
          $("#formplace").modal("show");
   end;
end;

procedure TBase.EnviarMail;
begin
     if cdcontacto.state=dsedit then CDContacto.Post;
     mensajeespera.show;
     ModuloDatos.Conectar(conexionenviar,1,'api/mail',datasettojson(cdcontacto,true,true,false));
end;

procedure TBase.EstaSeguro(Titulo, Mensaje, Botonok, BotonCancelar: String; ProcAceptar,
  ProcCancelar: TNotifyEvent);
 var
  Eform: TEstaSeguro;

  procedure AfterShowModal(AValue: TModalResult);
  begin
     ocultarventana;
  end;

  // async called OnCreate for TForm2
  procedure AfterCreate(AForm: TObject);
  begin
       EForm.Mensaje.Caption:=Mensaje;
//       EForm.Mensaje.Text:=Mensaje;
       if Titulo<>'' then Eform.Titulo.Caption:=Titulo else EForm.Titulo.Visible:=False;

       if Botonok<>'' then
          begin
               Eform.BotonAceptar.Caption:=Botonok;
               EForm.BotonAceptar.OnClick:=procaceptar;

          end;

       if BotonCancelar<>'' then
          begin
            EForm.BotonCancelar.Caption:=BotonCancelar;
            EForm.BotonCancelar.OnClick:=ProcCancelar;
          end
          else
              Eform.BotonCancelar.Visible:=False;


       mostrarventana;
  end;

begin
       EForm := TEstaSeguro.CreateNew('contenido', @AfterCreate);

end;

procedure TBase.EstaSeguroAceptarClick(Sender: TObject);
begin

end;

procedure TBase.EstaSeguroCancelarClick(Sender: TObject);
begin

end;

procedure TBase.LimpiarAnimancion;
begin

end;

procedure TBase.MostrarInfo(Mensaje: string);
begin
  asm
        const str1 = ' <h4  <p>';
        alertify.alert('Aviso',str1.concat(Mensaje,'</p></h4>'));
//          const str1 = '<h4 <p>';
//          bootbox.dialog({
//                      title: 'Aviso',
//                      message: str1.concat(Mensaje,'</p></h4>'),
//                      size: 'small',
 //                     buttons: {
 //                         cancel: {
 //                            label: "Aceptar",
 //                             className: 'btn-danger',
 //                             callback: function(){
 //                                           console.log('Custom cancel clicked');
 //                                                 }
 //                               }
 //                             }
 //                         }
 //                   );
  end;

end;

procedure TBase.MostrarMensaje(Mensaje: string);
begin
      EstaSeguro('Información',Mensaje,'Aceptar','',@EstaSeguroAceptarClick,@EstaSeguroCancelarClick);
end;

procedure TBase.MostrarVentanaBoot(IDVentana, Titulo: sTRING; ok,
  cancel: TModalResultProc);
begin

          asm
                const str1 = '#';
                bootbox.dialog({

                            message: $(str1.concat(IDVentana)),
                            buttons: {
                                      confirm: {
                                          label: 'Aceptar',
                                          className: 'btn-success',callback: function(){
                console.log('Custom OK clicked');
            }

                                                },
                                      cancel: {
                                          label: 'Cancelar',
                                          className: 'btn-danger',
                                          callback: function(){
                console.log('Custom cancel clicked');
            }
                                              }
}
}
                               );
          end;

end;


procedure TBase.PanelEfectosMouseEnter(Sender: TObject);
begin
       TJSHTMLElement(TPanel(Sender).ElementHandle).style.setProperty('display','inline-block');
       Animar(TJSHTMLElement(TPanel(Sender).ElementHandle),'animate__bounceIn');
end;

procedure TBase.PanelEfectosMouseLeave(Sender: TObject);
begin
//      TWebPanel(Sender).ElementHandle.classlist.add('animate__animated');
end;

procedure TBase.PanelEfectosMouseMove(Sender: TObject; Shift: TShiftState; X,
  Y: Integer);
begin
      TPanel(Sender).ElementHandle.classlist.add('animate__animated');
      TPanel(Sender).ElementHandle.classlist.add('animate__rubberBand');
      TPanel(Sender).ElementHandle.classlist.add('animate__bounceIn');

end;

procedure TBase.WebFormCreate(Sender: TObject);
begin
     if UpperCase(name)<>'PRINCIPAL' then
        begin
          ElementClassName:='rounded';
        end;

    FHost:=Modulodatos.Host;
    FPuerto:=Modulodatos.Puerto;
    FHttp:=Modulodatos.Http;

{    FID:=Modulodatos.ID;
    FIDE:=Modulodatos.IDE;
}
end;

procedure TBase.WebFormDestroy(Sender: TObject);
begin
//    mostrarinfo('Destruyendo '+name);
{
      ElementHandle.classlist.remove('animate__animated');
      ElementHandle.classlist.remove('animate__bounceInRight');
}
end;

procedure TBase.BootstrapWindowShow;
begin
   asm
         $("#formplace").modal("show");
   end;
end;

procedure TBase.BotonEfectosMouseDown(Sender: TObject; Button: TMouseButton;
  Shift: TShiftState; X, Y: Integer);
begin
      TPanel(Sender).ElementHandle.classlist.add('animate__pulse');

end;

procedure TBase.BotonEfectosMouseUp(Sender: TObject; Button: TMouseButton;
  Shift: TShiftState; X, Y: Integer);
begin
      TPanel(Sender).ElementHandle.classlist.remove('animate__pulse');
end;

procedure TBase.ConexionEnviarError(Sender: TObject;
  ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord;
  var Handled: Boolean);
begin
    mensajeespera.hide;
    mostrarinfo('Se presentó algún problema...');
    handled:=true;
end;

procedure TBase.ConexionEnviarResponse(Sender: TObject; AResponse: string);
begin
        mensajeespera.hide;
        if AResponse='IOK' then
          begin
              MostrarInfo('Gracias por contactarse con nosotros');
          end;
end;

procedure TBase.ConexionError(Sender: TObject; ARequest: TJSXMLHttpRequest;
  Event: TEventListenerEvent; var Handled: Boolean);
begin
            MensajeEspera.hide;
            Handled:=true;
{          if Sender = conexiondocumento  then
                  begin
                      fbuscandodocumento:=False;
                      mostrarinfo('Hubo inconvenientes en la conexion');
                  end;
}

{          if sender= conexionenviar then
            begin
                MostrarInfo('Lamentablemente no se pudo enviar el mail. Intente luego ');
            end;
}

{          if (sender = conexionactualizarpersona) then
            begin
              MostrarInfo('Lamentablemente no se pudo actualizar la informacion de la persona ');
              ocultopaneles;
            end;

          if (sender = conexionactualizarpersona) then
            begin
              MostrarInfo('Lamentablemente no se pudo actualizar la informacion de la persona ');
              ocultopaneles;
            end;
           if sender = conexionaltapersona then
            begin
              MostrarInfo('Lamentablemente no se pudo dar de alta la persona ');
              ocultopaneles;
            end;

            if sender = conexionentidades then
              begin
                  MostrarInfo('Lamentablemente no se pudo leer desde el servidor ');
                  ocultopaneles;
              end;
}

end;

procedure TBase.ConexionRequestResponse(Sender: TObject;
  ARequest: TJSXMLHttpRequestRecord; AResponse: string);
begin

end;

{$R *.dfm}

procedure TBase.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MensajeEspera := TWaitMessage.Create(Self);
  PanelEfectos := TPanel.Create(Self);
  BotonEfectos := TButton.Create(Self);
  CDContacto := TClientDataSet.Create(Self);
  CDContactoNombre := TStringField.Create(Self);
  CDContactoEMail := TStringField.Create(Self);
  CDContactoMensaje := TStringField.Create(Self);
  CDContactoAsunto := TStringField.Create(Self);
  DTContacto := TDataSource.Create(Self);
  ConexionEnviar := THttpRequest.Create(Self);
  Conexion := THttpRequest.Create(Self);

  MensajeEspera.BeforeLoadDFMValues;
  PanelEfectos.BeforeLoadDFMValues;
  BotonEfectos.BeforeLoadDFMValues;
  CDContacto.BeforeLoadDFMValues;
  CDContactoNombre.BeforeLoadDFMValues;
  CDContactoEMail.BeforeLoadDFMValues;
  CDContactoMensaje.BeforeLoadDFMValues;
  CDContactoAsunto.BeforeLoadDFMValues;
  DTContacto.BeforeLoadDFMValues;
  ConexionEnviar.BeforeLoadDFMValues;
  Conexion.BeforeLoadDFMValues;
  try
    Name := 'Base';
    Width := 1203;
    Height := 602;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    MensajeEspera.SetParentComponent(Self);
    MensajeEspera.Name := 'MensajeEspera';
    MensajeEspera.Left := 160;
    MensajeEspera.Top := 114;
    MensajeEspera.Width := 24;
    MensajeEspera.Height := 24;
    MensajeEspera.Opacity := 0.200000000000000000;
    MensajeEspera.Picture.LoadFromFile('UBase.MensajeEspera.Picture.gif');
    PanelEfectos.SetParentComponent(Self);
    PanelEfectos.Name := 'PanelEfectos';
    PanelEfectos.Left := 332;
    PanelEfectos.Top := 256;
    PanelEfectos.Width := 150;
    PanelEfectos.Height := 60;
    PanelEfectos.Caption := 'PanelEfectos';
    PanelEfectos.ChildOrder := 1;
    PanelEfectos.Visible := False;
    SetEvent(PanelEfectos, Self, 'OnMouseEnter', 'PanelEfectosMouseEnter');
    SetEvent(PanelEfectos, Self, 'OnMouseLeave', 'PanelEfectosMouseLeave');
    SetEvent(PanelEfectos, Self, 'OnMouseMove', 'PanelEfectosMouseMove');
    BotonEfectos.SetParentComponent(Self);
    BotonEfectos.Name := 'BotonEfectos';
    BotonEfectos.Left := 248;
    BotonEfectos.Top := 364;
    BotonEfectos.Width := 96;
    BotonEfectos.Height := 25;
    BotonEfectos.Caption := 'BotonEfectos';
    BotonEfectos.ChildOrder := 8;
    BotonEfectos.HeightPercent := 100.000000000000000000;
    BotonEfectos.Visible := False;
    BotonEfectos.WidthPercent := 100.000000000000000000;
    SetEvent(BotonEfectos, Self, 'OnMouseDown', 'BotonEfectosMouseDown');
    SetEvent(BotonEfectos, Self, 'OnMouseUp', 'BotonEfectosMouseUp');
    CDContacto.SetParentComponent(Self);
    CDContacto.Name := 'CDContacto';
    CDContacto.Left := 236;
    CDContacto.Top := 67;
    CDContactoNombre.SetParentComponent(CDContacto);
    CDContactoNombre.Name := 'CDContactoNombre';
    CDContactoNombre.FieldName := 'Nombre';
    CDContactoNombre.Size := 50;
    CDContactoEMail.SetParentComponent(CDContacto);
    CDContactoEMail.Name := 'CDContactoEMail';
    CDContactoEMail.FieldName := 'EMail';
    CDContactoEMail.Size := 50;
    CDContactoMensaje.SetParentComponent(CDContacto);
    CDContactoMensaje.Name := 'CDContactoMensaje';
    CDContactoMensaje.FieldName := 'Mensaje';
    CDContactoMensaje.Size := 1000;
    CDContactoAsunto.SetParentComponent(CDContacto);
    CDContactoAsunto.Name := 'CDContactoAsunto';
    CDContactoAsunto.FieldName := 'Asunto';
    CDContactoAsunto.Size := 100;
    DTContacto.SetParentComponent(Self);
    DTContacto.Name := 'DTContacto';
    DTContacto.DataSet := CDContacto;
    DTContacto.Left := 331;
    DTContacto.Top := 67;
    ConexionEnviar.SetParentComponent(Self);
    ConexionEnviar.Name := 'ConexionEnviar';
    ConexionEnviar.Command := httpPOST;
    ConexionEnviar.Headers.BeginUpdate;
    try
      ConexionEnviar.Headers.Clear;
      ConexionEnviar.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
      ConexionEnviar.Headers.Add('Content-Type=application/json');
    finally
      ConexionEnviar.Headers.EndUpdate;
    end;
    SetEvent(ConexionEnviar, Self, 'OnError', 'ConexionEnviarError');
    SetEvent(ConexionEnviar, Self, 'OnResponse', 'ConexionEnviarResponse');
    ConexionEnviar.Left := 418;
    ConexionEnviar.Top := 68;
    Conexion.SetParentComponent(Self);
    Conexion.Name := 'Conexion';
    Conexion.Headers.BeginUpdate;
    try
      Conexion.Headers.Clear;
      Conexion.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      Conexion.Headers.EndUpdate;
    end;
    SetEvent(Conexion, Self, 'OnRequestResponse', 'ConexionRequestResponse');
    Conexion.Left := 88;
    Conexion.Top := 226;
  finally
    MensajeEspera.AfterLoadDFMValues;
    PanelEfectos.AfterLoadDFMValues;
    BotonEfectos.AfterLoadDFMValues;
    CDContacto.AfterLoadDFMValues;
    CDContactoNombre.AfterLoadDFMValues;
    CDContactoEMail.AfterLoadDFMValues;
    CDContactoMensaje.AfterLoadDFMValues;
    CDContactoAsunto.AfterLoadDFMValues;
    DTContacto.AfterLoadDFMValues;
    ConexionEnviar.AfterLoadDFMValues;
    Conexion.AfterLoadDFMValues;
  end;
end;

end.
