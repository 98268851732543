{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2024                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.ComCtrls;

{$DEFINE NOPP}

interface

uses
  Classes, SysUtils, Web, WEBLib.Controls, WEBLib.Graphics, WEBLib.ExtCtrls,
  WEBLib.TreeNodes, WebLib.Menus;

type
  TCustomRichEdit = class;

  TTextAttributes = class(TPersistent)
  private
    RichEdit: TCustomRichEdit;
    FAlignment: TAlignment;
    FIsBold: Boolean;
    FIsItalic: Boolean;
    FIsUnderline: Boolean;
    FIsStrikeOut: Boolean;
    FBackColor: TColor;
    FColor: TColor;
    FHeight: Integer;
    FName: String;
    FOrderedList: Boolean;
    FSize: Integer;
    FStyle: TFontStyles;
    FUnOrderedList: Boolean;
    procedure SetAlignment(Value: TAlignment);
    procedure SetBackColor(Value: TColor);
    procedure SetColor(Value: TColor);
    procedure SetHeight(Value: Integer);
    procedure SetName(Value: String);
    procedure SetOrderedList(Value: Boolean);
    procedure SetSize(Value: Integer);
    procedure SetStyle(Value: TFontStyles);
    procedure SetUnOrderedList(Value: Boolean);
  protected
    procedure AssignTo(Dest: TPersistent); override;
    function FontSizeToEM(const Value: integer): string;
  public
    constructor Create(AOwner: TCustomRichEdit); reintroduce;
    procedure Assign(Source: TPersistent); override;
    procedure Cut;
    procedure Copy;
    procedure Paste;
    procedure Image;
    procedure Link;
    property Alignment: TAlignment read FAlignment write SetAlignment;
    property BackColor: TColor read FBackColor write SetBackColor;
    property Color: TColor read FColor write SetColor;
    property Height: Integer read FHeight write SetHeight;
    property Name: String read FName write SetName;
    property OrderedList: Boolean read FOrderedList write SetOrderedList;
    property Size: Integer read FSize write SetSize;
    property Style: TFontStyles read FStyle write SetStyle;
    property UnOrderedList: Boolean read FUnOrderedList write SetUnOrderedList;
  end;

  TSelAttributes = record
    FontName: string;
    FontSize: string;
    FontColor: string;
    BkColor: string;
    IsBold: boolean;
    IsItalic: boolean;
    IsStrikeThrough: boolean;
    IsUnderline: boolean;
    IsLeft: boolean;
    IsRight: boolean;
    IsCenter: boolean;
  end;

  TAttributesChangeEvent = procedure(Sender: TObject; Attributes: TSelAttributes) of object;

  TCustomRichEdit = class(TWebCustomControl)
  private
    FOwner: TCustomControl;
    FLines: TStrings;
    FSelAttributes: TTextAttributes;
    FAutoSize: Boolean;
    FOnSelectionChange: TNotifyEvent;
    FShowFocus: boolean;
    FInsertLineBreaks: boolean;
    FWantTabs: boolean;
    FReadOnly: boolean;
    FOnChange: TNotifyEvent;
    FOnAttributesChanged: TAttributesChangeEvent;
    FWantImages: boolean;
    function GetText: String;
    function GetPlainText: String;
    procedure SetText(const Value: String);
    procedure SetSelAttributes(Value: TTextAttributes);
    procedure SetAutoSize(const Value: Boolean);
    procedure SetShowFocus(const Value: boolean); reintroduce;
    procedure SetReadOnly(const Value: boolean);
    function GetLines: TStrings;
    function GetSelStart: integer;
    procedure SetSelStart(const Value: integer);
  protected
    function CreateElement: TJSElement; override;
//    function ElementIFrameHandle: TJSWindow;
    function ElementIFrameHandle: TJSDocument;
    procedure UpdateElement; override;
    procedure Loaded; override;
    function CanShowFocus: boolean; override;
    procedure DoHandleInput(Event: TJSEvent); virtual;
    procedure DoHandlePaste(Event: TJSEvent); virtual;
    function HandleDoSelectionChange(Event: TEventListenerEvent): Boolean; virtual;
    function HandleDoKeyDown(Event: TJSKeyBoardEvent): Boolean; override;
    function HandleDoKeyUp(Event: TJSKeyBoardEvent): Boolean; override;
    procedure SetLines(ALines: TStrings);
    procedure DoLinesChange(Sender: TObject);
    property Text: String read GetText write SetText;
    property Font;
    property Color;
    property BorderStyle;
    property AutoSize: Boolean read FAutoSize write SetAutoSize default false;
    property Lines: TStrings read GetLines write SetLines;
    property OnClick;
    property OnDblClick;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnSelectionChange: TNotifyEvent read FOnSelectionChange write FOnSelectionChange;
    property PlainText: string read GetPlainText;
    procedure DoChange; virtual;
    procedure BindEvents; override;
    procedure GetSelectionAttributes(selnode:TJSNode);
    property OnAttributesChanged: TAttributesChangeEvent read FOnAttributesChanged write FOnAttributesChanged;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    function GetContent: string;
    function GetSelection: string;
    procedure DoSelectAction(StartPosition, EndPosition: integer);
    procedure DoEditAction(ActionString: string); overload;
    procedure DoEditAction(ActionString: string; Data: string); overload;
    procedure SelectText(StartPosition, EndPosition: integer);
    procedure SetSelectionSpacing(ASpacing: double);
    procedure DoImageAction;
    procedure DoLinkAction;
    procedure AppendHTML(HTML: string);
    procedure AppendLineBreak;
    procedure InsertHTML(HTML: String);
    procedure InsertLineBreak;
    procedure InsertImage(DataURL: string);
    property CursorPosition: integer read GetSelStart write SetSelStart;
    property InsertLineBreaks: boolean read FInsertLineBreaks write FInsertLineBreaks default false;
    property SelAttributes: TTextAttributes read FSelAttributes write SetSelAttributes;
    property ShowFocus: boolean read FShowFocus write SetShowFocus default true;
    property Owner: TCustomControl read FOwner write FOwner;
    property ReadOnly: boolean read FReadOnly write SetReadOnly default false;
    property WantImages: boolean read FWantImages write FWantImages default true;
    property WantTabs: boolean read FWantTabs write FWantTabs default false;
  end;

  TRichEdit = class(TCustomRichEdit)
  public
    property Owner;
    property PlainText;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property AutoSize;
    property ElementClassName;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property Font;
    property Color;
    property DragMode;
    property BorderStyle;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property InsertLinebreaks;
    property Lines;
    property Margins;
    property ReadOnly;
    property PopupMenu;
    property WantTabs;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnChange;
    property OnClick;
    property OnDblClick;
    property OnSelectionChange;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebRichEdit = class(TRichEdit);

  TTabSet = class(TWebCustomControl)
  private
    FItems: TStringList;
    FItemIndex: integer;
    FSelectedColor: TColor;
    FOnChange: TNotifyEvent;
    FSelectedTextColor: TColor;
    FElementTabActiveClassName: TElementClassName;
    FElementTabClassName: TElementClassName;
    FElementTabItemClassName: TElementClassName;
    procedure SetSelectedColor(const Value: TColor);
    procedure SetSelectedTextColor(const Value: TColor);
  protected
    function CreateElement: TJSElement; override;
    function HandleDoClick(Event: TJSMouseEvent): Boolean; override;
    function HandleDoChange(Event: TEventListenerEvent): Boolean; virtual;
    function HandleDoItemMouseOut(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoItemMouseOver(Event: TJSMouseEvent): Boolean; virtual;
    procedure BindEvents; override;
    procedure SetItems(AItems: TStringList);
    procedure SetItemIndex(AIndex: integer);
    procedure DoUpdateList; virtual;
    procedure Loaded; override;
    procedure ItemsChanged(Sender: TObject);
    procedure EnableDrag; override;
    procedure DisableDrag; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure EndUpdate; override;
    procedure Clear;
    procedure SelectNextTab(GoForward: boolean);
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Color;
    property DragMode;
    property ElementClassName;
    property ElementTabClassName: TElementClassName read FElementTabClassName write FElementTabClassName;
    property ElementTabActiveClassName: TElementClassName read FElementTabActiveClassName write FElementTabActiveClassName;
    property ElementTabItemClassName: TElementClassName read FElementTabItemClassName write FElementTabItemClassName;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property ItemIndex: integer read FItemIndex write SetItemIndex;
    property Items: TStringList read FItems write SetItems;
    property Left;
    property Margins;
    property ParentFont;
    property PopupMenu;
    property ShowHint;
    property SelectedColor: TColor read FSelectedColor write SetSelectedColor default clSilver;
    property SelectedTextColor: TColor read FSelectedTextColor write SetSelectedTextColor default clBlack;
    property TabOrder;
    property TabStop;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebTabSet = class(TTabSet);

  TTabSheet = class(TCustomPanel)
  private
    FTabVisible: Boolean;
    FPosUpdating: Boolean;
    FOnShow: TNotifyEvent;
    FOnHide: TNotifyEvent;
    FMaterialGlyph: TMaterialGlyph;
    FMaterialGlyphColor: TColor;
    FMaterialGlyphType: TMaterialGlyphType;
    FMaterialGlyphSize: integer;
    procedure SetTabVisible(const Value: Boolean);
    function GetPageIndex: integer;
    procedure SetPageIndex(const Value: integer);
    procedure SetMaterialGlyph(const Value: TMaterialGlyph);
    procedure SetMaterialGlyphColor(const Value: TColor);
    procedure SetMaterialGlyphSize(const Value: integer);
    procedure SetMaterialGlyphType(const Value: TMaterialGlyphType);
  protected
    procedure SetCaption(const AValue: string); override;
    procedure SetParent(AValue: TControl); override;
    procedure ResetUpdate;
  public
    procedure CreateInitialize; override;
    procedure EndUpdate; override;
    procedure SetBounds(X, Y, AWidth, AHeight: Integer); override;
    destructor Destroy; override;
    property ShowCaption;
  published
    property Alignment;
    property AlignWithMargins;
    property BorderColor;
    property BorderStyle;
    property Caption;
    property Color;
    property DragMode;
    property ElementBodyClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Font;
    property HeightPercent;
    property HeightStyle;
    property Margins;
    property MaterialGlyph: TMaterialGlyph read FMaterialGlyph write SetMaterialGlyph;
    property MaterialGlyphColor: TColor read FMaterialGlyphColor write SetMaterialGlyphColor default clNone;
    property MaterialGlyphSize: integer read FMaterialGlyphSize write SetMaterialGlyphSize default 18;
    property MaterialGlyphType: TMaterialGlyphType read FMaterialGlyphType write SetMaterialGlyphType default mgNormal;
    property Padding;
    property PageIndex: integer read GetPageIndex write SetPageIndex stored false;
    property ParentFont;
    property Role;
    property WidthPercent;
    property WidthStyle;
    property TabVisible: Boolean read FTabVisible write SetTabVisible default True;
    property Visible;
    property OnClick;
    property OnDblClick;
    property OnHide: TNotifyEvent read FOnHide write FOnHide;
    property OnMouseDown;
    property OnMouseMove;
    property OnMouseUp;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnShow: TNotifyEvent read FOnShow write FOnShow;
    property OnStartDrag;
  end;

  TWebTabSheet = class(TTabSheet);

  TPageControl = class(TWebCustomControl)
  private
    FDesignTime: boolean;
    FPrevTabIndex: Integer;
    FItems: TStringList;
    FTabIndex: Integer;
    FSelectedColor: TColor;
    FOnChange: TNotifyEvent;
    FSelectedTextColor: TColor;
    FShowTabs: boolean;
    FOldVisible: boolean;
    FElementTabActiveClassName: TElementClassName;
    FElementTabClassName: TElementClassName;
    FElementTabItemClassName: TElementClassName;
    procedure SetSelectedColor(const AValue: TColor);
    procedure SetItems(AItems: TStringList);
    procedure SetTabIndex(AIndex: integer);
    function GetActivePage: TTabsheet;
    function GetActivePageIndex: integer;
    procedure SetActivePageIndex(const Value: integer);
    procedure SetSelectedTextColor(const Value: TColor);
    procedure SetActivePage(const Value: TTabsheet);
    procedure SetShowTabs(const Value: boolean);
  protected
    function GetPage(Index: integer): TTabSheet;
    function CreateElement: TJSElement; override;
    function HandleDoClick(Event: TJSMouseEvent): Boolean; override;
    function HandleDoChange(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoItemMouseOut(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoItemMouseOver(Event: TJSMouseEvent): Boolean; virtual;
    function CanAcceptChild(AValue: TControl): TControl; override;
    procedure BindEvents; override;
    procedure GetChildren(Proc: TGetChildProc; Root: TComponent); override;
    procedure UpdateElement; override;
    procedure DoUpdateList; virtual;
    procedure DoUpdateResize; virtual;
    procedure Loaded; override;
    procedure VisibleChanged; override;
    procedure ItemsChanged(Sender: TObject); virtual;
    procedure SetParent(AValue: TControl); override;
    function GetUniqueName: string;
    procedure EnableDrag; override;
    procedure DisableDrag; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure Clear;
    procedure EndUpdate; override;
    procedure SelectNextPage(GoForward: boolean);
    function PageCount: integer;
    function PageIndexFromElement(AElement: TJSElement): Integer;
    property Pages[index: integer]: TTabSheet read GetPage;
    property ActivePageIndex: integer read GetActivePageIndex write SetActivePageIndex;
    property ActivePage: TTabsheet read GetActivePage write SetActivePage;
    property Items: TStringList read FItems write SetItems;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BorderStyle default bsNone;
    property ChildOrder;
    property DragMode;
    property ElementClassName;
    property ElementTabClassName: TElementClassName read FElementTabClassName write FElementTabClassName;
    property ElementTabActiveClassName: TElementClassName read FElementTabActiveClassName write FElementTabActiveClassName;
    property ElementTabItemClassName: TElementClassName read FElementTabItemClassName write FElementTabItemClassName;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property TabIndex: integer read FTabIndex write SetTabIndex;
    property Left;
    property Margins;
    property ParentFont;
    property PopupMenu;
    property ShowHint;
    property ShowTabs: boolean read FShowTabs write SetShowTabs default true;
    property SelectedColor: TColor read FSelectedColor write SetSelectedColor default clSilver;
    property SelectedTextColor: TColor read FSelectedTextColor write SetSelectedTextColor default clBlack;
    property TabOrder;
    property TabStop;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebPageControl = class(TPageControl);

  TProgressBarStyle = (pbstNormal, pbstMarquee, pbstDiv);
  TProgressBarValue = (pbvNone, pbvPercentage, pbvAbsolute);

  TProgressBar = class(TWebCustomControl)
  private
    FMax: integer;
    FMin: integer;
    FPosition: integer;
    FStyle: TProgressBarStyle;
    FElementBarClassName: string;
    FValue: TProgressBarValue;
    FValueColor: TColor;
    procedure SetStyle(const Value: TProgressBarStyle);
    procedure SetElementBarClassName(const Value: string);
    procedure SetValue(const Value: TProgressBarValue);
    procedure SetValueColor(const Value: TColor);
  protected
    function CreateElement: TJSElement; override;
    procedure SetMax(AValue: integer);
    procedure SetMin(AValue: integer);
    procedure SetPosition(AValue: integer);
    procedure DoUpdate; virtual;
    procedure UpdateElementVisual; override;
    procedure CreateProgressElement(AElement: TJSElement);
  public
    procedure CreateInitialize; override;
    procedure AfterLoadDFMValues; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property DragMode;
    property ElementBarClassName: string read FElementBarClassName write SetElementBarClassName;
    property ElementPosition;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Left;
    property Margins;
    property Max: integer read FMax write SetMax;
    property Min: integer read FMin write SetMin;
    property PopupMenu;
    property Position: integer read FPosition write SetPosition;
    property Style: TProgressBarStyle read FStyle write SetStyle;
    property Top;
    property Value: TProgressBarValue read FValue write SetValue;
    property ValueColor: TColor read FValueColor write SetValueColor default clNone;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebProgressBar = class(TProgressBar);

  TTreeViewNodeEvent = procedure(Sender: TObject; Node: TTreeNode) of object;
  TTreeViewNodeStateEvent = procedure(Sender: TObject; Node: TTreeNode; AState: boolean) of object;
  TTreeViewNodeAllowEvent = procedure(Sender: TObject; Node: TTreeNode; var Allow: boolean) of object;

  TTreeViewNodeRenderEvent = procedure(Sender: TObject; Node: TTreeNode; AElement: TJSHTMLElementRecord) of object;

  TTreeView = class(TWebCustomControl)
  private
    FItems: TTreeNodes;
    FSelected: TTreeNode;
    FOnCollapsing: TTreeViewNodeAllowEvent;
    FOnExpanding: TTreeViewNodeAllowEvent;
    FOnCollapsed: TTreeViewNodeEvent;
    FOnExpanded: TTreeViewNodeEvent;
    FOnClickNode: TTreeViewNodeEvent;
    FOnDblClickNode: TTreeViewNodeEvent;
    FOnRenderNode: TTreeViewNodeRenderEvent;
    FAutoExpand: boolean;
    FOnChange: TTreeViewNodeEvent;
    FOnChanging: TTreeViewNodeAllowEvent;
    FElementNodeSelectedClassName: TElementClassName;
    FElementNodeClassName: TElementClassName;
    FOnClickRadio: TTreeViewNodeStateEvent;
    FOnClickCheckBox: TTreeViewNodeStateEvent;
    procedure SetItems(const Value: TTreeNodes);
    function GetSelected: TTreeNode;
    procedure SetSelected(const Value: TTreeNode);
    procedure SetElementNodeClassName(const Value: TElementClassName);
    procedure SetElementNodeSelectedClassName(const Value: TElementClassName);
    function GetTopItem: TTreeNode;
    procedure SetTopItem(const Value: TTreeNode);
  protected
    procedure DoRenderNode(ANode: TTreeNode; AElement: TJSHTMLElement);
    function RenderControl(ANode: TTreeNode; AElementName: string): string;
    function RenderNode(ANode: TTreeNode; AElementName: string): TJSHTMLElement;
    procedure RenderNodes;
    function CreateNodes: TTreeNodes; virtual;
    function CreateElement: TJSElement; override;
    procedure DoNodesChanged(Sender: TObject);
    procedure DoExpandedChanged(Sender: TObject; ANode: TTreeNode);
    procedure DoSelectionChanged(Sender: TObject; ANode: TTreeNode; IsSelected: boolean);
    function HandleNode(Event: TJSEvent): Boolean; virtual;
    function HandleClick(Event: TJSEvent): Boolean; virtual;
    function HandleDblClick(Event: TJSEvent): Boolean; virtual;
    procedure ClickCheck(ANode: TTreeNode; AState: boolean); virtual;
    procedure ClickRadio(ANode: TTreeNode; AState: boolean); virtual;
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
    procedure DoChangeSelection(NewNode: TTreeNode); virtual;
    procedure EnableDrag; override;
    procedure DisableDrag; override;
    function FormPrefix: string;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    function GetNodeFromID(AId: string): TTreeNode;
    function GetNodeElement(ANode: TTreeNode): TJSHTMLElement;
    property Selected: TTreeNode read GetSelected write SetSelected;
    procedure Select(const ANode: TTreeNode);
    procedure FullExpand;
    procedure FullCollapse;
    property TopItem: TTreeNode read GetTopItem write SetTopItem;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;

    property AutoExpand: boolean read FAutoExpand write FAutoExpand;
    property DragMode;
    property ElementNodeClassName: TElementClassName read FElementNodeClassName write SetElementNodeClassName;
    property ElementNodeSelectedClassName: TElementClassName read FElementNodeSelectedClassName write SetElementNodeSelectedClassName;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;

    property Font;
    property HeightPercent;
    property HeightStyle;

    property Items: TTreeNodes read FItems write SetItems;
    property Margins;
    property ParentFont;
    property PopupMenu;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnClickCheckBox: TTreeViewNodeStateEvent read FOnClickCheckBox write FOnClickCheckBox;
    property OnClickRadio: TTreeViewNodeStateEvent read FOnClickRadio write FOnClickRadio;
    property OnDblClick;
    property OnClickNode: TTreeViewNodeEvent read FOnClickNode write FOnClickNode;
    property OnDblClickNode: TTreeViewNodeEvent read FOnDblClickNode write FOnDblClickNode;
    property OnRenderNode: TTreeViewNodeRenderEvent read FOnRenderNode write FOnRenderNode;
    property OnChange: TTreeViewNodeEvent read FOnChange write FOnChange;
    property OnChanging: TTreeViewNodeAllowEvent read FOnChanging write FOnChanging;

    property OnCollapsed: TTreeViewNodeEvent read FOnCollapsed write FOnCollapsed;
    property OnCollapsing: TTreeViewNodeAllowEvent read FOnCollapsing write FOnCollapsing;
    property OnExpanded: TTreeViewNodeEvent read FOnExpanded write FOnExpanded;
    property OnExpanding: TTreeViewNodeAllowEvent read FOnExpanding write FOnExpanding;

    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebTreeView = class(TTreeView);



implementation

uses
  WEBLib.Buttons, WEBLib.Forms, WEBLib.Utils;

type
  TTreeNodeEx = class(TTreeNode);
  TTreeNodesEx = class(TTreeNodes);

{ TCustomRichEdit }

procedure TCustomRichEdit.CreateInitialize;
begin
  inherited;
  FLines := TStringList.Create;
  TStringList(FLines).OnChange := DoLinesChange;
  FSelAttributes := TTextAttributes.Create(Self);
  FShowFocus := true;
  FWantImages := true;
  FInsertLineBreaks := false;
  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;
end;

procedure TCustomRichEdit.BindEvents;
begin
  inherited;
  Container.addEventListener('input',@DoHandleInput);
  Container.addEventListener('paste',@DoHandlePaste);
end;

function TCustomRichEdit.CanShowFocus: boolean;
begin
  Result := FShowFocus;
end;

function TCustomRichEdit.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
  TJSHTMLElement(Result).style.setProperty('white-space','normal');
end;

destructor TCustomRichEdit.Destroy;
begin
  FLines.Free;
  inherited;
end;

procedure TCustomRichEdit.DoLinesChange(Sender: TObject);
begin
  UpdateElement;
end;

procedure TCustomRichEdit.SelectText(StartPosition, EndPosition: integer);
begin
  DoSelectAction(StartPosition, EndPosition);
end;

procedure TCustomRichEdit.SetAutoSize(const Value: Boolean);
begin
  if FAutoSize <> Value then
  begin
    FAutoSize := Value;
    UpdateElement;
  end;
end;

procedure TCustomRichEdit.SetLines(ALines: TStrings);
begin
  FLines.Assign(ALines);
end;

procedure TCustomRichEdit.SetReadOnly(const Value: boolean);
begin
  if FReadOnly <> Value then
  begin
    FReadOnly := Value;
    if Assigned(ElementHandle) then
    begin
      if FReadOnly then
        ElementHandle.contentEditable := 'false'
      else
        ElementHandle.contentEditable := 'true';
    end;

  end;
end;

procedure TCustomRichEdit.SetText(const Value: String);
begin
  FLines.Text := Value;
  UpdateElement;
end;

//function TCustomRichEdit.ElementIFrameHandle: TJSWindow;
function TCustomRichEdit.ElementIFrameHandle: TJSDocument;
//var
//  iframe: TJSHTMLIFrameElement;
begin
  Result := nil;
  if Assigned(ElementHandle) then
  begin
//    iframe := TJSHTMLIFrameElement(document.getElementById(ElementID));
//    if Assigned(iframe) then
//      result := iframe.contentWindow.document;
    Result := document;
  end;
end;

procedure GetTextNodes(node: TJSNode; nodes: TList);
var
  children: TJSNodeList;
  i, j: integer;
  childlist: TList;
begin
  if (node.nodeType = 3) then
    nodes.Add(node)
  else
  begin
    children := node.childNodes;

    childlist := TList.Create;
    for i := 0 to children.length - 1 do
    begin
      GetTextNodes(children[i], childlist);
      for j := 0 to childlist.count - 1 do
        nodes.Add(childlist[j]);
    end;
    childlist.Free;
  end;
end;

procedure TCustomRichEdit.DoSelectAction(StartPosition, EndPosition: integer);
var
  s: string;
  sstart, send: integer;
  el: TJSElement;
  range: TJSRange;
  textNodes: TList;
  textNode: TJSNode;
  i: integer;
  foundStart: boolean;
  charCount, endCharCount: integer;
  sel: TJSSelection;
begin
  s := ElementID;
  sstart := StartPosition;
  send := EndPosition;

  el := document.getElementById(s);
  if Assigned(el) then
  begin
    range := document.createRange;
    range.selectNodeContents(el);
    textNodes := TList.Create;

    GetTextNodes(el, textNodes);
    foundStart := false;
    charCount := 0;
    endCharCount := 0;

    for i := 0 to textNodes.count - 1 do
    begin
      textNode := TJSNode(textNodes[i]);
      endCharCount := charCount + length(TJSNode(textNode).nodeValue);

      if (not foundStart) and (sstart >= charCount) and ((sstart < endCharCount) or ((sstart = endCharCount) and (i <= textNodes.count))) then
      begin
        range.setStart(textNode, sstart - charCount);
        foundStart := true;
      end;

      if (foundStart) and (send <= endCharCount) then
      begin
        range.setEnd(textNode, send - charCount);
      	break;
      end;
      charCount := endCharCount;
    end;

    sel := document.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textNodes.Free;
  end;
end;

procedure TCustomRichEdit.DoEditAction(ActionString: string);
begin
  if Assigned(ElementIFrameHandle) then
    ElementIFrameHandle.execCommand(ActionString, false);
end;

procedure TCustomRichEdit.DoChange;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TCustomRichEdit.DoEditAction(ActionString: string; Data: string);
begin
  if Assigned(ElementIFrameHandle) then
  begin
    ElementIFrameHandle.execCommand(ActionString, false, Data);
  end;
end;

procedure TCustomRichEdit.DoHandleInput(Event: TJSEvent);
begin
  DoChange;
end;

procedure TCustomRichEdit.DoHandlePaste(Event: TJSEvent);
begin
  if FWantImages then
    Exit;
  asm
    var pastedData = Event.clipboardData.getData('text');
    var regex = /^[a-zA-Z0-9@  `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (regex.test(pastedData) !== true) {
            Event.preventDefault();
    }
  end;
end;

procedure TCustomRichEdit.DoLinkAction;
var
  url: string;
begin
  if Assigned(ElementHandle) then
  begin
    url := window.prompt('Enter URL:', 'http://');
    if Assigned(url) then
    begin
      if (url <> '') and (url <> 'http://') and (url <> 'https://') then
        DoEditAction('createLink', url);
      end;
  end;
end;

procedure TCustomRichEdit.DoImageAction;
var
  url: string;
begin
  if Assigned(ElementHandle) then
  begin
    url := window.prompt('Enter Image URL:', 'http://');
    if Assigned(url) then
    begin
      if (url <> '') and (url <> 'http://') and (url <> 'https://') then
        DoEditAction('insertImage', url);
      end;
  end; 
end;

procedure TCustomRichEdit.UpdateElement;
var
  i: integer;
  s: string;
begin
  inherited;

  if Assigned(ElementIFrameHandle) then
  begin
//    ElementIFrameHandle.designMode := 'On';
//    ElementIFrameHandle.document.open;
//    ElementIFrameHandle.document.write(FLines.Text);
//    ElementIFrameHandle.document.close;
//    ElementIFrameHandle.document.contentEditable = true;
//    ElementIFrameHandle.body.innerHTML := FLines.Text;
  end;

  if Assigned(ElementHandle) then
  begin
    s := '';
    for i := 0 to FLines.Count - 1 do
    begin
      if i > 0 then
        s := s + '<br>';
      s := s + FLines[i];
    end;

    ElementHandle.innerHTML := s;

    if ReadOnly then
      ElementHandle.contentEditable := 'false'
    else
      ElementHandle.contentEditable := 'true';

    ElementHandle.style.setProperty('overflow', 'auto');
    ElementHandle.style.setProperty('padding', '3px');

    if BorderStyle = bsSingle then
      ElementHandle.style.setProperty('border', '1px solid silver')
    else
      ElementHandle.style.setProperty('border', '');
  end;
end;

function TCustomRichEdit.GetText: String;
begin
  if Assigned(ElementHandle) then
    FLines.Text := ElementHandle.innerHTML;
  Result := FLines.Text;
end;

function TCustomRichEdit.GetContent: string;
begin
  Result := self.GetText;
end;

function TCustomRichEdit.GetLines: TStrings;
begin
  Result := FLines;
end;

function TCustomRichEdit.GetPlainText: String;
begin
  Result := '';
  if Assigned(ElementHandle) then
    Result := ElementHandle.innerText;
end;

function TCustomRichEdit.GetSelection: string;
var
  res: string;
begin
  res := '';
  asm
    // window.getSelection() gives you a Selection object representing the range of text selected by the user or the current position of the caret.
    if (window.getSelection) {
      res = window.getSelection().toString();
    }
  end;

  Result := res;
end;

procedure TCustomRichEdit.GetSelectionAttributes(selnode: TJSNode);
var
  el: TJSElement;
  fn,fs,fc,fbk: string;
  found: boolean;
  Attr: TSelAttributes;
begin
  if Assigned(OnAttributesChanged) then
  begin
    asm
      el = null;
      if (selnode.focusNode) {
        el = selnode.focusNode.parentElement;
      }
    end;

    repeat
      found := false;
      if Assigned(el) then
      begin
        if el.tagName = 'B' then
        begin
          found := true;
          Attr.IsBold := true;
        end;
        if el.tagName = 'I' then
        begin
          found := true;
          Attr.IsItalic := true;
        end;
        if el.tagName = 'U' then
        begin
          found := true;
          Attr.IsUnderline := true;
        end;

        if el.tagName = 'STRIKE' then
        begin
          found := true;
          Attr.IsStrikeThrough := true;
        end;

        if el.tagName = 'SPAN' then
        begin
          fbk := TJSHTMLElement(el).style.getPropertyValue('background-color');

          asm
            if (fbk == null) {
              fbk = ''; }
          end;
          Attr.BkColor := fbk;

          found := true;
        end;

        if el.tagName = 'DIV' then
        begin
          fbk := TJSHTMLElement(el).style.getPropertyValue('text-align');

          asm
            if (fbk == null) {
              fbk = ''; }
          end;

          if fbk = 'center' then
            Attr.isCenter := true;

          if fbk = 'right' then
            Attr.isRight := true;

          if fbk = 'left' then
            Attr.isLeft := true;

          found := true;
        end;

        if el.tagName = 'FONT' then
        begin
          fn := el['face'];

          asm
            if (fn == null) {
              fn = ''; }
          end;

          fs := el['size'];

          asm
            if (fs == null) {
              fs = ''; }
          end;

          fc := el['color'];

          asm
            if (fc == null) {
              fc = ''; }
          end;

          fbk := TJSHTMLElement(el).style.getPropertyValue('background-color');

          asm
            if (fbk == null) {
              fbk = ''; }
          end;

          found := true;

          Attr.FontName := fn;
          Attr.FontColor := fc;

          if (fs <> '') then
          begin
            if fs = '7' then
              fs := '36';

            if fs = '6' then
              fs := '24';

            if fs = '5' then
              fs := '18';

            if fs = '4' then
              fs := '14';

            if fs = '3' then
              fs := '10';

            if fs = '2' then
              fs := '9';

            if fs = '1' then
              fs := '8';
          end;

          Attr.FontSize := fs;
          Attr.BkColor := fbk;
        end;

        el := TJSElement(el.parentElement);

        if (el = ElementHandle) then
          found := false;
      end;
    until not found;

    OnAttributesChanged(Self, Attr);
  end;
end;

{$HINTS OFF}

function TCustomRichEdit.GetSelStart: integer;
var
  res: integer;
  el: TJSHTMLElement;
begin
  el := TJSHTMLElement(ElementHandle);
  asm
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const preSelectionRange = range.cloneRange();
    preSelectionRange.selectNodeContents(el);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);
    res = preSelectionRange.toString().length;
  end;

  Result := res;
end;
{$HINTS ON}

procedure TCustomRichEdit.SetSelAttributes(Value: TTextAttributes);
begin
  SelAttributes.Assign(Value);
end;

procedure TCustomRichEdit.SetSelectionSpacing(ASpacing: double);
var
  sel: TJSSelection;
  range: TJSRange;
  node, pNode: TJSNode;
  i: integer;
  lh: string;

  function getNextNode(anode: TJSNode): TJSNode;
  begin
    if Assigned(anode.firstChild) then
        Result := anode.firstChild
    else
    while Assigned(anode) do
    begin
        if Assigned(anode.nextSibling) then
        begin
          Result := anode.nextSibling;
          break;
        end;
        anode := anode.parentNode;
    end;
  end;

begin
  sel := document.getSelection;

  if not Assigned(sel) then
    Exit;

  lh := FormatProp('%g',[ASpacing]);

  for i := 0 to sel.rangeCount - 1 do
  begin
    range := sel.getRangeAt(i);

    node := range.startContainer;

    if node.nodeName = '#text' then
    begin
      pNode := node.parentNode;
      if pNode.nodeName = 'DIV' then
        TJSHTMLElement(pNode).style.setProperty('line-height',lh);
    end;

    while node <> range.endContainer do
    begin
      node := getNextNode(node);
      if node.nodeName = 'DIV' then
        TJSHTMLElement(node).style.setProperty('line-height',lh);
    end;
  end;
end;

{$HINTS OFF}
procedure TCustomRichEdit.SetSelStart(const Value: integer);
var
  el: TJSHTMLElement;
begin
  el := TJSHTMLElement(ElementHandle);

  asm
    const range = document.createRange();
    const sel = window.getSelection();

    var i = 0;
    while (i < el.childNodes.length) {
      if (Value <=el.childNodes[i].textContent.length) {
        range.setStart(el.childNodes[i], Value);
      }
      else {
         Value = Value - el.childNodes[i].textContent.length;
      }
      i++;
    }
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
  end;
end;
{$HINTS ON}


procedure TCustomRichEdit.SetShowFocus(const Value: boolean);
begin
  if (FShowFocus <> Value) then
  begin
    FShowFocus := Value;
    UpdateElement;
  end;
end;

procedure TCustomRichEdit.Loaded;
var
  doc: TJSDocument;
  el: TJSHTMLElement;
begin
  inherited;

  if Assigned(ElementIFrameHandle) then
  begin
    doc := ElementIFrameHandle;
    el := ElementHandle;
    el.onclick := @HandleDoClick;
    el.ondblclick := @HandleDoDblClick;
    el.onmousedown := @HandleDoMouseDown;
    el.onmouseup := @HandleDoMouseUp;
    el.onmousemove := @HandleDoMouseMove;
    el.onkeydown := @HandleDoKeyDown;
    el.onkeyup := @HandleDoKeyUp;
    el.onkeypress := @HandleDoKeyPress;
    el.onfocus := @HandleDoEnter;
    el.onblur := @HandleDoExit;
    doc.onselectionchange := @HandleDoSelectionChange;
  end;
end;

procedure TCustomRichEdit.AppendHTML(HTML: string);
var
  sel: TJSSelection;
  range: TJSRange;
  el: TJSHTMLElement;
  frag: TJSDocumentFragment;
  node, lastNode: TJSNode;
begin
  sel := nil;

  if Focused then
  begin
    sel := document.getSelection;
    range := sel.getRangeAt(0);
    range.deleteContents;
  end
  else
  begin
    range := document.createRange();
    node := ElementHandle.firstChild;
    lastNode := node;
    while Assigned(node) do
    begin
      node := node.nextSibling;
      if Assigned(node) then
        lastNode := node;
    end;

    if Assigned(lastNode) then
    begin
      range.setStartAfter(lastNode);
      range.setEndAfter(lastNode);
    end
    else
    begin
      range.setStart(ElementHandle, 0);
      range.setEnd(ElementHandle,0);
    end;
  end;

  el := TJSHTMLElement(document.createElement('div'));
  el.innerHTML := HTML;
  frag := document.createDocumentFragment;
  node := el.firstChild;

  while (node = el.firstChild) do
  begin
    lastNode := frag.appendChild(node);
    range.insertNode(frag);
  end;

  if Assigned(lastNode) then
  begin
    range := range.cloneRange();
    range.setStartAfter(lastNode);
    range.collapse;
    if  Assigned(sel) then
    begin
      sel.removeAllRanges;
      sel.addRange(range);
    end;
  end;
end;

procedure TCustomRichEdit.AppendLinebreak;
begin
  AppendHTML('<BR>');
end;

procedure TCustomRichEdit.InsertHTML(HTML: String);
var
  sel: TJSSelection;
  range: TJSRange;
  el: TJSHTMLElement;
  frag: TJSDocumentFragment;
  node, lastNode: TJSNode;
begin
  sel := nil;
  if Focused then
  begin
    sel := document.getSelection;
    range := sel.getRangeAt(0);
    range.deleteContents;
  end
  else
  begin
    range := document.createRange();
    range.setStart(ElementHandle, 0);
    range.setEnd(ElementHandle, 0);
  end;

  el := TJSHTMLElement(document.createElement('div'));
  el.innerHTML := HTML;
  frag := document.createDocumentFragment;
  node := el.firstChild;


  while (node = el.firstChild) do
  begin
    lastNode := frag.appendChild(node);
    range.insertNode(frag);
  end;

  if Assigned(lastNode) then
  begin
    range := range.cloneRange();
    range.setStartAfter(lastNode);
    range.collapse;
    if  Assigned(sel) then
    begin
      sel.removeAllRanges;
      sel.addRange(range);
    end;
  end;
end;

procedure TCustomRichEdit.InsertImage(DataURL: string);
begin
  DoEditAction('insertImage', DataURL);
end;

procedure TCustomRichEdit.InsertLineBreak;
begin
  InsertHTML('<BR>');
end;

function TCustomRichEdit.HandleDoSelectionChange(Event: TEventListenerEvent): Boolean;
begin
  if Assigned(Owner) then
  begin
    //reset to a color close to black to avoid being unable to
    //select black as the new color
    (Owner as TRichEditToolBar).TextColor := $000001;
    (Owner as TRichEditToolBar).BackgroundColor := $000001;
  end;

  GetSelectionAttributes(TJSNode(document.getSelection()));

  if Assigned(OnSelectionChange) then
    OnSelectionChange(Self);
  Result := True;
end;

function TCustomRichEdit.HandleDoKeyDown(Event: TJSKeyBoardEvent): Boolean;
begin
  Result := false;

  if (Event.ctrlKey = true) and (Event.key = 'Control') and ReadOnly then
  begin
    ElementHandle.contentEditable := 'false';
  end;

  //Handle Return Key
  if (GetKeyCode(Event.Key) = 13) and InsertLineBreaks then
    InsertHTML('<br>')
  else
  //Handle Tab Key
  if (GetKeyCode(Event.Key) = 9) and WantTabs then
    InsertHTML('<span style=white-space:pre>&#9;</span>')
  else
  begin
    Result := True;
  end;

  inherited;
end;


function TCustomRichEdit.HandleDoKeyUp(Event: TJSKeyBoardEvent): Boolean;
begin
  Result := false;

  if (Event.ctrlKey = false) and (Event.key = 'Control') and not ReadOnly then
  begin
    ElementHandle.contentEditable := 'true';
  end;

  inherited;
end;

{ TTextAttributes }

constructor TTextAttributes.Create(AOwner: TCustomRichEdit);
begin
  inherited Create;
  RichEdit := AOwner;
  FAlignment := taLeftJustify;
  FIsBold := False;
  FIsItalic := False;
  FIsUnderline := False;
  FIsStrikeOut := False;
  FOrderedList := False;
  FUnOrderedList := False;
end;

procedure TTextAttributes.Cut;
begin
  RichEdit.DoEditAction('cut');
end;

function TTextAttributes.FontSizeToEM(const Value: integer): string;
begin
  Result :=  '0.63';
  case Value of
  1: Result := '0.63em';
  2: Result := '0.82em';
  3: Result := '1.0em';
  4: Result := '1.13em';
  5: Result := '1.5em';
  6: Result := '2em';
  7: Result := '3em';
  end;
end;

procedure TTextAttributes.Copy;
begin
  RichEdit.DoEditAction('copy');
end;

procedure TTextAttributes.Paste;
begin
  RichEdit.DoEditAction('paste');
end;

procedure TTextAttributes.Image;
begin
  RichEdit.DoImageAction;
end;

procedure TTextAttributes.Link;
begin
  RichEdit.DoLinkAction;
end;

procedure TTextAttributes.SetAlignment(Value: TAlignment);
begin
  FAlignment := Value;

  case FAlignment of
    taLeftJustify: RichEdit.DoEditAction('justifyleft');
    taCenter: RichEdit.DoEditAction('justifycenter');
    taRightJustify: RichEdit.DoEditAction('justifyright');
  end;
end;

procedure TTextAttributes.SetBackColor(Value: TColor);
begin
  FColor := Value;
  RichEdit.DoEditAction('hiliteColor', ColorToHtml(Value));
end;

procedure TTextAttributes.SetColor(Value: TColor);
begin
  FColor := Value;
  RichEdit.DoEditAction('foreColor', ColorToHtml(Value));
end;

procedure TTextAttributes.SetName(Value: string);
begin
  FName := Value;
  RichEdit.DoEditAction('fontName', Value);
end;

procedure TTextAttributes.SetOrderedList(Value: Boolean);
begin
  if (FOrderedLIst <> Value) then
    RichEdit.DoEditAction('insertorderedlist');

  FOrderedList := Value;
end;

procedure TTextAttributes.SetUnOrderedList(Value: Boolean);
begin
  if (FUnOrderedLIst <> Value) then
    RichEdit.DoEditAction('insertunorderedlist');

  FUnOrderedList := Value;
end;

procedure TTextAttributes.SetStyle(Value: TFontStyles);
begin
  FStyle := Value;

  if (fsBold in FStyle) and (not FIsBold) then
  begin
    RichEdit.DoEditAction('bold');
    FIsBold := True;
  end;

  if (not(fsBold in FStyle)) and (FIsBold) then
  begin
    RichEdit.DoEditAction('bold');
    FIsBold := False;
  end;
  
  if (fsItalic in FStyle) and (not FIsItalic) then
  begin
    RichEdit.DoEditAction('italic');
    FIsItalic := True;
  end;

  if (not(fsItalic in FStyle)) and (FIsItalic) then
  begin
    RichEdit.DoEditAction('italic');
    FIsItalic := False;
  end;
  
  if (fsUnderline in FStyle) and (not FIsUnderline) then
  begin
    RichEdit.DoEditAction('underline');
    FIsUnderline := True;
  end;

  if (not(fsUnderline in FStyle)) and (FIsUnderline) then
  begin
    RichEdit.DoEditAction('underline');
    FIsUnderline := False;
  end;

  if (fsStrikeOut in FStyle) and (not FIsStrikeOut) then
  begin
    RichEdit.DoEditAction('strikethrough');
    FIsStrikeOut := True;
  end;

  if (not(fsStrikeOut in FStyle)) and (FIsStrikeOut) then
  begin
    RichEdit.DoEditAction('strikethrough');
    FIsStrikeOut := False;
  end;

//  RichEdit.UpdateElement;
end;

procedure TTextAttributes.SetSize(Value: Integer);
begin
  if (Value > 0) and (Value < 8) then
  begin
    FSize := Value;
    FHeight := Value;
    RichEdit.DoEditAction('fontSize', IntToStr(Value));
    asm
      var selection = window.getSelection();
    end;
  end;
end;

{$HINTS OFF}
procedure TTextAttributes.SetHeight(Value: Integer);
var
  eh: TJSElement;
  fs: string;
begin
  if (Value > 0) and (Value < 8) then
  begin
    FSize := Value;
    FHeight := Value;
    fs := IntToStr(Value);
    RichEdit.DoEditAction('fontSize', fs);

    fs := FontSizeToEm(Value);

    eh := RichEdit.ElementHandle;

    // fix browser shortcoming that it doesn't apply font size changes to LI elements
    asm
      var selection = window.getSelection();
      if (selection.rangeCount > 0) {
        var range = selection.getRangeAt(0);
        const selectedElements = eh.querySelectorAll('*');
        selectedElements.forEach(element => {
          if (range.intersectsNode(element)) {
            if (element.tagName == "LI") {
              element.style.setProperty("font-size",fs);
            }
          }
        });
      }
    end;
  end;
end;
{$HINTS ON}

procedure TTextAttributes.Assign(Source: TPersistent);
begin
  if Source is TFont then
  begin
    Color := TFont(Source).Color;
    Name := TFont(Source).Name;
    Style := TFont(Source).Style;
    Size := TFont(Source).Size;
  end
  else if Source is TTextAttributes then
  begin
    Color := TTextAttributes(Source).Color;
    Name := TTextAttributes(Source).Name;
    Style := TTextAttributes(Source).Style;
    Size := TTextAttributes(Source).Size;
  end
  else inherited Assign(Source);
end;

procedure TTextAttributes.AssignTo(Dest: TPersistent);
begin
  if Dest is TFont then
  begin
    TFont(Dest).Color := Color;
    TFont(Dest).Name := Name;
    TFont(Dest).Style := Style;
    TFont(Dest).Size := Size;
  end
  else if Dest is TTextAttributes then
  begin
    TTextAttributes(Dest).Color := Color;
    TTextAttributes(Dest).Name := Name;
    TTextAttributes(Dest).Style := Style;
  end
  else inherited AssignTo(Dest);
end;

{ TTabSet }

procedure TTabSet.BindEvents;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    ElementHandle.onchange := @HandleDoChange;
  end;
end;

procedure TTabSet.Clear;
begin
  Items.Clear;
end;

procedure TTabSet.CreateInitialize;
begin
  inherited;
  FItems := TStringList.Create;
  FItems.OnChange := ItemsChanged;
  FItemIndex := 0;
  FSelectedColor := clSilver;
  FSelectedTextColor := clBlack;
end;

function TTabSet.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
end;

destructor TTabSet.Destroy;
begin
  FItems.Free;
  inherited;
end;

procedure TTabSet.DisableDrag;
  procedure RemoveDraggableAttribute(AElement: TJSElement);
  var
    I: Integer;
  begin
    for I := 0 to AElement.children.length - 1 do
    begin
      if TJSElement(AElement.children[I]).tagName = 'LI' then
      begin
        TJSElement(AElement.children[I]).removeAttribute('draggable');
        RemoveDraggableAttribute(TJSElement(AElement.children[I]));
      end
      else if TJSElement(AElement.children[I]).tagName = 'A' then
        TJSElement(AElement.children[I]).removeAttribute('draggable')
      else
        RemoveDraggableAttribute(TJSElement(AElement.children[I]));
    end;
  end;
begin
  if not Assigned(Container) then
    Exit;

  RemoveDraggableAttribute(Container);
end;

procedure TTabSet.SetItems(AItems: TStringList);
begin
  FItems.Assign(AItems);
end;

function TTabSet.HandleDoChange(Event: TEventListenerEvent): Boolean;
begin
  if Assigned(OnChange) then
    OnChange(Self);
  Result := True;
end;

procedure TTabSet.Loaded;
begin
  inherited;
  DoUpdateList;
end;

procedure TTabSet.SelectNextTab(GoForward: boolean);
begin
  if GoForward then
  begin
    if ItemIndex < Items.Count - 1 then
      ItemIndex := ItemIndex + 1
    else
      ItemIndex := 0;
  end
  else
  begin
    if ItemIndex > 0 then
      ItemIndex := ItemIndex - 1
    else
      ItemIndex := Items.Count - 1;
  end;

end;

procedure TTabSet.SetItemIndex(AIndex: integer);
begin
  if FItemIndex <> AIndex then
  begin
    FItemIndex := AIndex;
    DoUpdateList;
  end;
end;

procedure TTabSet.SetSelectedColor(const Value: TColor);
begin
  if FSelectedColor <> Value then
  begin
    FSelectedColor := Value;
    DoUpdateList;
  end;
end;

procedure TTabSet.SetSelectedTextColor(const Value: TColor);
begin
  if (FSelectedColor <> Value) then
  begin
    FSelectedTextColor := Value;
    DoUpdateList;
  end;
end;

procedure TTabSet.DoUpdateList;
var
  i: integer;
  s, cid: string;
  ul, li, a: TJSElement;
begin
  if not Assigned(Container) then
    Exit;

  while Assigned(Container.firstChild) do
    Container.removeChild(Container.firstChild);

  Container['class'] := '';

  ul := document.createElement('UL');
  ul['class'] := ElementClassName;

  if ElementClassName <> '' then
    ul['role'] := 'tablist'
  else
  begin
    (TJSHTMLElement(ul)).style.setProperty('list-style-type', 'none');
    (TJSHTMLElement(ul)).style.setProperty('margin', '0');
    (TJSHTMLElement(ul)).style.setProperty('padding', '0');
    (TJSHTMLElement(ul)).style.setProperty('display', 'inline-block');
  end;

  Container.appendChild(ul);

  for i := 0 to FItems.Count - 1 do
  begin
    s := FItems[i];

    li := document.createElement('LI');

    if ElementClassName <> '' then
    begin
      if ElementTabItemClassName = '' then
        li['class'] := 'nav-item'
      else
        li['class'] := ElementTabItemClassName;
    end
    else
    begin
      (TJSHTMLElement(li)).style.setProperty('float', 'left');
      (TJSHTMLElement(li)).style.setProperty('border-bottom', '1px solid lightgray');
    end;

    SetHTMLElementFont(TJSHTMLElement(li), Font, not ((ElementClassName = '') and (ElementFont = efProperty)));

    if DragMode = dmAutomatic then
      li['draggable'] := 'true';

    ul.appendChild(li);

    if not Enabled then
      a := document.createElement('SPAN')
    else
    begin
      a := document.createElement('A');
      a['href'] := '#' + s;
    end;

    a.innerHTML := s;

    cid := GetID;
    if cid = '' then
      cid := Name;

    a['id'] := cid + '_' + IntToStr(i);

    if ElementClassName <> '' then
    begin
      if ItemIndex = i then
      begin
        if ElementTabActiveClassName = '' then
          a['class'] := 'nav-link active'
        else
          a['class'] := ElementTabActiveClassName;
      end
      else
      begin
        if ElementTabClassName = '' then
          a['class'] := 'nav-link'
        else
          a['class'] := ElementTabClassName;
      end;
      a['role'] := 'tab';

      a['data-toggle'] := 'tab';
      a['aria-controls'] := s;

      if ItemIndex = i then
        a['aria-selected'] := 'true'
      else
        a['aria-selected'] := 'false';

      if DragMode = dmAutomatic then
        a['draggable'] := 'false';
    end
    else
    begin
      (TJSHTMLElement(a)).style.setProperty('display', 'block');
      (TJSHTMLElement(a)).style.setProperty('text-align', 'center');
      (TJSHTMLElement(a)).style.setProperty('padding', '3px 15px');
      (TJSHTMLElement(a)).style.setProperty('text-decoration', 'none');
      (TJSHTMLElement(a)).style.setProperty('min-width', '50px');

      (TJSHTMLElement(a)).style.setProperty('color', 'inherit');
      (TJSHTMLElement(a)).style.setProperty('font-family', 'inherit');
      (TJSHTMLElement(a)).style.setProperty('font-style', 'inherit');
      (TJSHTMLElement(a)).style.setProperty('font-size', 'inherit');

      if not Enabled then
        (TJSHTMLElement(a)).style.setProperty('pointer-events', 'none');

      if DragMode = dmAutomatic then
        a['draggable'] := 'false';

      if ItemIndex = i then
      begin
        if SelectedColor <> clNone then
          (TJSHTMLElement(a)).style.setProperty('background-color', ColorToHtml(SelectedColor));
        if SelectedTextColor <> clNone then
          (TJSHTMLElement(a)).style.setProperty('color', ColorToHtml(SelectedTextColor));
      end;
    end;

    li.appendChild(a);
  end;

  if not IsLinked and (csDesigning in ComponentState) then
    RenderDesigning('TWebTabSet', Container, Self, (FItems.Count = 0));

  UpdateElement;
end;

procedure TTabSet.EnableDrag;
  procedure AddDraggableAttribute(AElement: TJSElement);
  var
    I: Integer;
  begin
    for I := 0 to AElement.children.length - 1 do
    begin
      if TJSElement(AElement.children[I]).tagName = 'LI' then
      begin
        TJSElement(AElement.children[I]).Attrs['draggable'] := 'true';
        AddDraggableAttribute(TJSElement(AElement.children[I]));
      end
      else if TJSElement(AElement.children[I]).tagName = 'A' then
        TJSElement(AElement.children[I]).Attrs['draggable'] := 'false'
      else
        AddDraggableAttribute(TJSElement(AElement.children[I]));
    end;
  end;
begin
  if not Assigned(Container) then
    Exit;

  AddDraggableAttribute(Container);
end;

procedure TTabSet.EndUpdate;
begin
  inherited;
  DoUpdateList;
end;

function TTabSet.HandleDoClick(Event: TJSMouseEvent): Boolean;
var
  sid, cid: string;
  i: integer;
  el: TJSElement;
begin
  el := Event.targetElement;

  sid := '';
  if el.hasAttribute('id') then
    sid := el.getAttribute('id');

  if sid <> '' then
  begin
    cid := GetID;
    if cid = '' then
      cid := Name;

    sid := StringReplace(sid, cid + '_', '', []);
    if TryStrToInt(sid,i) then
    begin
      FItemIndex := i;
      DoUpdateList;
    end;
    inherited;
  end;

  Result := true;
end;

function TTabSet.HandleDoItemMouseOut(Event: TJSMouseEvent): Boolean;
var
  el: TJSHTMLElement;
begin
  Result := true;
  //Event.stopPropagation;

  el := TJSHTMLElement(Event.relatedTarget);
  if Assigned(el) then
  begin
    if el.nodeName = 'A' then
      el.style.setProperty('background-color', '');
  end
end;

function TTabSet.HandleDoItemMouseOver(Event: TJSMouseEvent): Boolean;
var
  el: TJSHTMLElement;
begin
  Result := true;
  //Event.stopPropagation;

  el := TJSHTMLElement(Event.relatedTarget);
  if Assigned(el) then
  begin
    if el.nodeName = 'A' then
      el.style.setProperty('background-color', 'lightgray');
  end
end;


procedure TTabSet.ItemsChanged(Sender: TObject);
begin
  DoUpdateList;
end;

{ TPageControl }

procedure TPageControl.BindEvents;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    //The ElementHandle doesn't have an onchange event
    //so the onclick is used instead.
    //HandleDoChange checks if a different tab is clicked
    ElementHandle.onclick := @HandleDoChange;
  end;
end;

function TPageControl.CanAcceptChild(AValue: TControl): TControl;
begin
  Result := inherited;

  if Assigned(AValue) and not (AValue is TTabSheet) then
  begin
    Result := Parent;
  end;
end;

procedure TPageControl.Clear;
begin
  Items.Clear;
end;

procedure TPageControl.CreateInitialize;
begin
  inherited;

  FDesignTime := (csDesigning in ComponentState) and not
    ((csReading in Owner.ComponentState) or (csLoading in Owner.ComponentState));

  ControlStyle := ControlStyle + [csAcceptsControls];
  BorderStyle := bsNone;
  FItems := TStringList.Create;
  FItems.OnChange := ItemsChanged;
  FTabIndex := 0;
  FPrevTabIndex := FTabIndex;
  FShowTabs := true;
  TabStop := false;
  FSelectedColor := clSilver;
  FSelectedTextColor := clBlack;
  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;
  EnablePropagation := true;
  FOldVisible := true;
end;

function TPageControl.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
end;

destructor TPageControl.Destroy;
begin
  FItems.Free;
  inherited;
end;

procedure TPageControl.DisableDrag;
  procedure RemoveDraggableAttribute(AElement: TJSElement);
  var
    I: Integer;
    tn: string;
  begin
    for I := 0 to AElement.children.length - 1 do
    begin
      tn := TJSElement(AElement.children[I]).tagName;
      if tn = 'LI' then
      begin
        TJSElement(AElement.children[I]).removeAttribute('draggable');
        RemoveDraggableAttribute(TJSElement(AElement.children[I]));
      end
      else if tn = 'A' then
        TJSElement(AElement.children[I]).removeAttribute('draggable')
      else if tn = 'UL' then
        RemoveDraggableAttribute(TJSElement(AElement.children[I]));
    end;
  end;
begin
  if not Assigned(Container) then
    Exit;

  RemoveDraggableAttribute(Container);
end;

procedure TPageControl.SelectNextPage(GoForward: boolean);
begin
  if GoForward then
  begin
    if TabIndex < PageCount - 1 then
      TabIndex := TabIndex + 1
    else
      TabIndex := 0;
  end
  else
  begin
    if TabIndex > 0 then
      TabIndex := TabIndex - 1
    else
      TabIndex := PageCount - 1;
  end;
end;

function TPageControl.PageCount: integer;
var
  i: integer;
begin
  Result := 0;

  for i := 0 to ControlCount - 1 do
  begin
    if (Controls[i] is TTabSheet) then
      inc(Result);
  end;
end;


function TPageControl.PageIndexFromElement(AElement: TJSElement): Integer;
var
  sid, cid: string;
  i: Integer;
begin
  Result := -1;

  sid := '';
  if AElement.tagName = 'LI' then
    AElement := TJSElement(AElement.firstChild);

  if AElement.hasAttribute('id') then
    sid := AElement.getAttribute('id');

  if (sid <> '') then
  begin
    cid := GetID;
    if cid = '' then
      cid := Name;

    sid := StringReplace(sid, cid + '_', '', []);
    if TryStrToInt(sid, i) then
      Result := i;
  end;
end;

function TPageControl.GetActivePage: TTabsheet;
var
  pi: integer;
begin
  Result := nil;

  pi := GetActivePageIndex;
  if (pi >= 0) then
    Result := Pages[pi];
end;

function TPageControl.GetActivePageIndex: integer;
begin
  Result := -1;
  if (PageCount >= 0) and (TabIndex >= 0) and (TabIndex < PageCount) then
    Result := TabIndex;
end;

procedure TPageControl.GetChildren(Proc: TGetChildProc; Root: TComponent);
var
  i: integer;
begin
  for i := 0 to ControlCount - 1 do
  begin
    if (Controls[i] is TTabSheet) then
    begin
      Proc(Controls[i]);
    end;
  end;
end;

function TPageControl.GetPage(index: integer): TTabSheet;
var
  c,i: integer;
begin
  c := -1;
  Result := nil;

  for i := 0 to ControlCount - 1 do
  begin
    if (Controls[i] is TTabSheet) then
      inc(c);

     if c = index then
     begin
       Result := Controls[i] as TTabSheet;
       break;
     end;
  end;
end;


function TPageControl.GetUniqueName: string;
var
  i,id: integer;
  found: boolean;
  AName: string;
begin
  id := 1;

  AName := Name + 'sheet';

  repeat
    Result := AName + inttostr(id);

    found := true;

    for i := 0 to Owner.ComponentCount - 1 do
    begin
      if Owner.Components[i].Name = Result then
      begin
        found := false;
        inc(id);
        break;
      end;
    end;

  until found;
end;

procedure TPageControl.SetActivePage(const Value: TTabsheet);
var
  i: integer;
begin
  for i := 0 to ControlCount - 1 do
  begin
    if Controls[i] = Value then
    begin
      ActivePageINdex := i;
      break;
    end;
  end;
end;

procedure TPageControl.SetActivePageIndex(const Value: integer);
begin
  if (Value >= 0) and (Value < PageCount) then
    TabIndex := Value;
end;


procedure TPageControl.SetItems(AItems: TStringList);
begin
  FItems.Assign(AItems);
end;

procedure TPageControl.SetParent(AValue: TControl);
var
  ts: TTabSheet;
  frm: TCustomForm;
begin
  inherited;

  if FDesignTime then
  begin
    frm := GetParentForm(Self);

    FDesignTime := false;
    ts := TWebTabSheet.Create(frm);
    ts.Parent := Self;
    ts.Caption := 'Page1';
    ts.Name := GetUniqueName;

    ts := TWebTabSheet.Create(frm);
    ts.Parent := Self;
    ts.Caption := 'Page2';
    ts.Name := GetUniqueName;
  end;

  DoUpdateList;
end;

function TPageControl.HandleDoChange(Event: TJSMouseEvent): Boolean;
begin
  if FPrevTabIndex <> FTabIndex then
  begin
    FPrevTabIndex := FTabIndex;
    if Assigned(OnChange) then
      OnChange(Self);
  end;
  Result := True;
end;

procedure TPageControl.Loaded;
begin
  inherited;
  DoUpdateList;
  TabIndex := FTabIndex;
  FOldVisible := Visible;
end;

procedure TPageControl.SetTabIndex(AIndex: integer);
begin
  if (csLoading in ComponentState) then
    FTabIndex := AIndex;

  if (FTabIndex <> AIndex) and (AIndex >= 0) and (AIndex < PageCount) then
  begin
    FTabIndex := AIndex;
    FPrevTabIndex := FTabIndex;
    DoUpdateList;
  end;
end;

procedure TPageControl.UpdateElement;
begin
  inherited;
end;

procedure TPageControl.VisibleChanged;
begin
  inherited;
  if FOldVisible <> Visible then
  begin
    DoUpdateList;
    FOldVisible := Visible;
  end;
end;

procedure TPageControl.SetSelectedColor(const AValue: TColor);
begin
  if FSelectedColor <> AValue then
  begin
    FSelectedColor := AValue;
    DoUpdateList;
  end;
end;

procedure TPageControl.SetSelectedTextColor(const Value: TColor);
begin
  if FSelectedTextColor <> Value then
  begin
    FSelectedTextColor := Value;
    DoUpdateList;
  end;
end;

procedure TPageControl.SetShowTabs(const Value: boolean);
begin
  if FShowTabs <> Value then
  begin
    FShowTabs := Value;
    DoUpdateList;
  end;
end;

procedure TPageControl.DoUpdateList;
var
  i, vistabindex: integer;
  s, cid: string;
  shidden: string;
  n: TJSNode;
  ul, li, a: TJSElement;
  tabs: TStringList;
  tab: TTabSheet;
  LLabel: TJSHTMLElement;
  LHasLabel: boolean;
  matclr,icn,noptr,inactive: string;
  th: integer;

begin
  if not Assigned(Container) then
    Exit;

  if not Assigned(ElementHandle) or IsUpdating then
    Exit;

  tab := nil;

  if ControlCount > 0 then
  begin
    BorderStyle := bsNone;
    LLabel := TJSHTMLElement(ElementHandle.firstChild);
    LHasLabel := Assigned(LLabel) and (LLabel.tagName = 'DIV') and (LLabel.getAttribute('data-design') = '1');
    if LHasLabel then
    begin
      ElementHandle.removeChild(ElementHandle.firstChild);
      ElementHandle.style.removeProperty('border');
    end;

    tabs := TStringList.Create;

    shidden := '{#hidden#}';

    vistabindex := TabIndex;

    for i := 0 to ControlCount - 1 do
    begin
      if (csDestroying in Controls[i].ComponentState) and (i <= TabIndex) then
        inc(vistabindex);

      if (Controls[i] is TTabSheet) and not (csDestroying in Controls[i].ComponentState) then
      begin
        tab := TTabSheet(Controls[i]);

        icn := '';
        if tab.TabVisible then
        begin
          if tab.MaterialGlyph <> '' then
          begin
            matclr := '';
            inactive := '';

            if not tab.Enabled then
              inactive := ' md-light md-inactive';

            if tab.FMaterialGlyphColor <> clNone then
              matclr := ';color:'+ColorToHTML(tab.MaterialGlyphColor);
            noptr := 'pointer-events:none';

            icn := '<i class="' + GetMaterialClass(tab.MaterialGlyphType) + inactive+'" style="'+noptr+';font-size:'+ IntToStr(tab.MaterialGlyphSize)+'px' + matclr+'">' + tab.MaterialGlyph + '</i>&nbsp;';
          end;
          tabs.Add(icn + tab.Caption);
        end
        else
          tabs.Add(shidden);

        tab.BeginUpdate;

        if (vistabindex = i) then
          tab.Align := alClient
        else
          tab.Align := alNone;

        tab.AlignWithMargins := True;
        tab.Margins.Left := 0;
        tab.Margins.Right := 0;
        tab.Margins.Bottom := 0;
        tab.BorderStyle := bsSingle;
        tab.Visible := (vistabindex = i);

        tab.ResetUpdate;

        // change order of tabs to bring visible tab to front
        if (vistabindex = i) then
          tab.ElementHandle.parentElement.insertBefore(tab.ElementHandle,  nil {tab.ElementHandle.parentElement.firstChild});

        tab.GetElementHandle.style.setProperty('background-color', ColorToHtml(tab.Color));
      end;
    end;

    cid := GetID;
    if cid = '' then
      cid := Name;

    for i := 0 to Container.childNodes.length - 1 do
    begin
      n := Container.childNodes[i];
      if Assigned(n) and (TJSElement(n)['id'] = cid + '_UL') then
        Container.removeChild(n);
    end;

    Container['class'] := '';

    if not Enabled then
      ElementHandle.style.setProperty('pointer-events', 'none')
    else
      ElementHandle.style.setProperty('pointer-events', 'auto');

    ul := document.createElement('UL');
    ul['class'] := ElementClassName;
    ul['id'] := cid + '_UL';

    if ElementClassName <> '' then
      ul['role'] := 'tablist'
    else
    begin
      (TJSHTMLElement(ul)).style.setProperty('list-style-type', 'none');
      (TJSHTMLElement(ul)).style.setProperty('margin', '0');
      (TJSHTMLElement(ul)).style.setProperty('padding', '0');
      (TJSHTMLElement(ul)).style.setProperty('display', 'inline-block')
    end;

    if not FShowTabs then
      (TJSHTMLElement(ul)).style.setProperty('display', 'none');

    Container.appendChild(ul);

    for i := 0 to tabs.Count - 1 do
    begin
      s := tabs[i];
      if s <> shidden then
      begin
        if s = '' then
          s := 'tab' + IntToStr(i);

        li := document.createElement('LI');

        if ElementClassName <> '' then
        begin
          if ElementTabItemClassName = '' then
            li['class'] := 'nav-item'
          else
            li['class'] := ElementTabItemClassName;
        end
        else
        begin
          (TJSHTMLElement(li)).style.setProperty('float', 'left');
          if BorderStyle <> bsNone then
            (TJSHTMLElement(li)).style.setProperty('border-bottom', '1px solid lightgray');
        end;

        if DragMode = dmAutomatic then
          li['draggable'] := 'true';

        SetHTMLElementFont(TJSHTMLElement(li), Font, not ((ElementClassName = '') and (ElementFont = efProperty)));

        ul.appendChild(li);

        if not Enabled then
          a := document.createElement('SPAN')
        else
        begin
          a := document.createElement('A');
          a['href'] := '#' + s;
        end;

        if DragMode = dmAutomatic then
          a['draggable'] := 'false';

        a.innerHTML := s;
        a['id'] := cid + '_' + IntToStr(i);

        if ElementClassName <> '' then
        begin
          if TabIndex = i then
          begin
            if ElementTabActiveClassName = '' then
              a['class'] := 'nav-link active'
            else
              a['class'] := ElementTabActiveClassName;
          end
          else
          begin
            if ElementTabClassName = '' then
              a['class'] := 'nav-link'
            else
              a['class'] := ElementTabClassName;
          end;
          a['role'] := 'tab';

          a['data-toggle'] := 'tab';
          a['aria-controls'] := s;

          if TabIndex = i then
            a['aria-selected'] := 'true'
          else
            a['aria-selected'] := 'false';
        end
        else
        begin
          (TJSHTMLElement(a)).style.setProperty('display', 'block');
          (TJSHTMLElement(a)).style.setProperty('text-align', 'center');
          (TJSHTMLElement(a)).style.setProperty('padding', '3px 15px');
          (TJSHTMLElement(a)).style.setProperty('text-decoration', 'none');
          (TJSHTMLElement(a)).style.setProperty('min-width', '50px');

          (TJSHTMLElement(a)).style.setProperty('color', 'inherit');
          (TJSHTMLElement(a)).style.setProperty('font-family', 'inherit');
          (TJSHTMLElement(a)).style.setProperty('font-style', 'inherit');
          (TJSHTMLElement(a)).style.setProperty('font-size', 'inherit');

          if not enabled then
            (TJSHTMLElement(a)).style.setProperty('pointer-events', 'none');

          if TabIndex = i then
          begin
            if SelectedColor <> clNone then
              (TJSHTMLElement(a)).style.setProperty('background-color', ColorToHtml(SelectedColor));
            if SelectedTextColor <> clNone then
              (TJSHTMLElement(a)).style.setProperty('color', ColorToHtml(SelectedTextColor));
          end;
        end;

        li.appendChild(a);
      end;
    end;

    for i := 0 to ControlCount - 1 do
    begin
      if Controls[i] is TTabSheet then
      begin
        th := Round((TJSHTMLElement(ul)).offsetHeight) - 1;
        TTabSheet(Controls[i]).Margins.Top := th;
        TTabSheet(Controls[i]).ElementHandle.style.setProperty('white-space', '');
      end;
    end;

    tabs.Free;
  end
  else
  begin
    if not IsLinked then
    begin
      ClearChildElements;
      if (csDesigning in ComponentState) then
      begin
        RenderDesigning('TWebPageControl', Container, Self, True);
      end;
    end;
  end;

  DoUpdateResize;

  if Assigned(tab) then
  begin
    tab.Align := alNone;
    tab.Align := alClient;
  end;

  UpdateElement;

  if not IsLinked and (ControlCount = 0) then
  begin
    ElementHandle.style.setProperty('border','1px solid lightgray');
  end;
end;

procedure TPageControl.DoUpdateResize;
var
  i, vistabindex: integer;
begin
  // hide all
  for i := 0 to ControlCount - 1 do
  begin
    if Controls[i] is TTabSheet then
       Controls[i].Visible := false;
  end;

  // cause client alignment for all
  for i := 0 to ControlCount - 1 do
  begin
    if Controls[i] is TTabSheet then
    begin
      Controls[i].Visible := true;
      Controls[i].Align := alNone;
      Controls[i].Align := alClient;
      Controls[i].Visible := false;
    end;
  end;

  vistabindex := TabIndex;

  // make active tab visible
  for i := 0 to ControlCount - 1 do
  begin
    if (csDestroying in Controls[i].ComponentState) and (i <= TabIndex) then
      inc(vistabindex);

    if Controls[i] is TTabSheet then
    begin
      Controls[i].Visible := (vistabindex = i);
    end;
  end;
end;

procedure TPageControl.EnableDrag;
  procedure AddDraggableAttribute(AElement: TJSElement);
  var
    I: Integer;
    tn: string;
  begin
    for I := 0 to AElement.children.length - 1 do
    begin
      tn := TJSElement(AElement.children[I]).tagName;
      if tn = 'LI' then
      begin
        TJSElement(AElement.children[I]).Attrs['draggable'] := 'true';
        AddDraggableAttribute(TJSElement(AElement.children[I]));
      end
      else if tn = 'A' then
        TJSElement(AElement.children[I]).Attrs['draggable'] := 'false'
      else if tn = 'UL' then
        AddDraggableAttribute(TJSElement(AElement.children[I]));
    end;
  end;
begin
  if not Assigned(Container) then
    Exit;

  AddDraggableAttribute(Container);
end;

procedure TPageControl.EndUpdate;
begin
  inherited;
  DoUpdateList;
end;

function TPageControl.HandleDoClick(Event: TJSMouseEvent): Boolean;
var
  sid, cid: string;
  i: integer;
  el: TJSElement;
  ts: TTabSheet;

begin
  el := Event.targetElement;

  sid := '';
  if el.hasAttribute('id') then
    sid := el.getAttribute('id');

  if (sid <> '') then
  begin
    cid := GetID;
    if cid = '' then
      cid := Name;

    sid := StringReplace(sid, cid + '_', '', []);
    if TryStrToInt(sid,i) then
    begin
      if FTabIndex <> -1 then
      begin
        ts := GetPage(FTabIndex);
        if Assigned(ts) and Assigned(ts.OnHide) then
          ts.OnHide(ts);
      end;

      FTabIndex := i;

      DoUpdateList;

      if FTabIndex <> -1 then
      begin
        ts := GetPage(FTabIndex);
        if Assigned(ts) and Assigned(ts.OnShow) then
          ts.OnShow(ts);
      end;

    end;
    inherited;
  end;

  Result := true;
end;

function TPageControl.HandleDoItemMouseOut(Event: TJSMouseEvent): Boolean;
var
  el: TJSHTMLElement;
begin
  Result := true;
  //Event.stopPropagation;

  el := TJSHTMLElement(Event.relatedTarget);
  if Assigned(el) then
  begin
    if el.nodeName = 'A' then
      el.style.setProperty('background-color', '');
  end
end;

function TPageControl.HandleDoItemMouseOver(Event: TJSMouseEvent): Boolean;
var
  el: TJSHTMLElement;
begin
  Result := true;
  //Event.stopPropagation;

  el := TJSHTMLElement(Event.relatedTarget);
  if Assigned(el) then
  begin
    if el.nodeName = 'A' then
      el.style.setProperty('background-color', 'lightgray');
  end
end;

procedure TPageControl.ItemsChanged(Sender: TObject);
var
  i: integer;
  ts: TTabSheet;

begin
  if (PageCount = 0) and (Items.Count > 0) and (ElementHandle.childElementCount > 0) then
  begin
    ElementHandle.removeChild(ElementHandle.firstChild);
  end;

  for i := 0 to FItems.Count - 1 do
  begin
    if PageCount <= i then
    begin
      ts := TTabSheet.Create(Owner);
      ts.Parent := Self;
      ts.Name := Name + 'sheet' + inttostr(i + 1);
    end;
    Pages[i].Caption := FItems[i];
  end;

  for i := PageCount - 1  downto 0 do
  begin
    if i >= Items.Count then
    begin
      ts := Pages[i];
      ts.Free;
    end;
  end;

  DoUpdateList;
end;

{ TTabSheet }

procedure TTabSheet.CreateInitialize;
begin
  inherited;
  EnablePropagation := true;
  ControlStyle := ControlStyle + [csAcceptsControls];
  Color := clWhite;
  TabVisible := True;
  ShowCaption := False;
  FPosUpdating := False;
  FMaterialGlyphSize := 18;
  FMaterialGlyphType := mgNormal;
  FMaterialGlyphColor := clNone;
end;

destructor TTabSheet.Destroy;
var
  p: TPageControl;
begin
  p := nil;

  if Assigned(Parent) then
  begin
    if Parent is TPageControl then
    begin
      p := (Parent as TPageControl);
    end;
  end;

  inherited;

  if Assigned(p) then
  begin
    p.DoUpdateList;
    p.TabIndex := 0;
  end;
end;

procedure TTabSheet.EndUpdate;
begin
  inherited;
  if Assigned(Parent) then
  begin
    if Parent is TPageControl then
      (Parent as TPageControl).DoUpdateList;
  end;
end;

function TTabSheet.GetPageIndex: integer;
var
  i,j: integer;
  FPageControl: TPageControl;
begin
  Result := -1;

  if (Parent is TPageControl) then
  begin
    FPageControl := (Parent as TPageControl);

    j := 0;
    for i := 0 to FPageControl.ControlCount - 1 do
      begin
        if FPageControl.Controls[i] is TTabSheet then
        begin
          if FPageControl.Controls[i] = Self then
          begin
            Result := j;
          end
          else
            inc(j);
        end;
      end;
  end;
end;

procedure TTabSheet.ResetUpdate;
begin
  inherited EndUpdate;
end;

procedure TTabSheet.SetBounds(X, Y, AWidth, AHeight: Integer);
begin
  inherited;

  if (Parent is TPageControl) and not FPosUpdating then
  begin
    FPosupdating := true;
    (Parent as TPageControl).DoUpdateList;
    FPosupdating := false;
  end;
end;

procedure TTabSheet.SetCaption(const AValue: string);
begin
  inherited;

  if Assigned(Parent) then
  begin
    if Parent is TPageControl then
      (Parent as TPageControl).DoUpdateList;
  end;
end;

procedure TTabSheet.SetMaterialGlyph(const Value: TMaterialGlyph);
begin
  if (FMaterialGlyph <> Value) then
  begin
    FMaterialGlyph := Value;
    if Parent is TPageControl then
      (Parent as TPageControl).DoUpdateList;
  end;
end;

procedure TTabSheet.SetMaterialGlyphColor(const Value: TColor);
begin
  if (FMaterialGlyphColor <> Value) then
  begin
    FMaterialGlyphColor := Value;
    if Parent is TPageControl then
      (Parent as TPageControl).DoUpdateList;
  end;
end;

procedure TTabSheet.SetMaterialGlyphSize(const Value: integer);
begin
  if (FMaterialGlyphSize <> Value) then
  begin
    FMaterialGlyphSize := Value;
    if Parent is TPageControl then
      (Parent as TPageControl).DoUpdateList;
  end;
end;

procedure TTabSheet.SetMaterialGlyphType(const Value: TMaterialGlyphType);
begin
  if (FMaterialGlyphType <> Value) then
  begin
    FMaterialGlyphType := Value;
    if Parent is TPageControl then
      (Parent as TPageControl).DoUpdateList;
  end;
end;

procedure TTabSheet.SetPageIndex(const Value: integer);
begin
  //
end;

procedure TTabSheet.SetParent(AValue: TControl);
begin
  if (AValue is TTabSheet) then
  begin
    if (AValue as TTabSheet).Parent is TPageControl then
      AValue := (AValue as TTabSheet).Parent;
  end;

  inherited SetParent(AValue);

  if Assigned(Parent) then
  begin
    if Parent is TPageControl then
    begin
      (Parent as TPageControl).DoUpdateList;
    end;
  end;
end;

procedure TTabSheet.SetTabVisible(const Value: Boolean);
begin
  if FTabVisible <> Value then
  begin
    FTabVisible := Value;

    if Assigned(Parent) then
    begin
      if Parent is TPageControl then
        (Parent as TPageControl).DoUpdateList;
    end;
  end;
end;

{ TProgressBar }

procedure TProgressBar.CreateInitialize;
begin
  inherited;
  FMax := 100;
  FMin := 0;
  FPosition := 0;
  FStyle := pbstNormal;
  FValueColor := clNone;
end;

procedure TProgressBar.CreateProgressElement(AElement: TJSElement);
var
  d: TJSElement;
begin
  while Assigned(AElement.firstChild) do
    AElement.removeChild(AElement.firstChild);

  if FStyle = pbstDiv then
  begin
    d := document.createElement('DIV');
    d.appendChild(document.createElement('DIV'));
  end
  else
    d := document.createElement('PROGRESS');

  AElement.appendChild(d);
end;

procedure TProgressBar.AfterLoadDFMValues;
begin
  inherited;
  DoUpdate;
end;

function TProgressBar.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
  CreateProgressElement(Result);
end;

procedure TProgressBar.SetElementBarClassName(const Value: string);
begin
  FElementBarClassName := Value;
  DoUpdate;
end;

procedure TProgressBar.SetMax(AValue: integer);
begin
  FMax := AValue;
  DoUpdate;
end;

procedure TProgressBar.SetMin(AValue: integer);
begin
  FMin := AValue;
  DoUpdate;
end;

procedure TProgressBar.DoUpdate;
var
  pvalue: integer;
  maxvalue: integer;
  el: TJSHTMLElement;
  pos: integer;
begin
  if not Assigned(Container) then
    Exit;

  if FMax <> FMin then
    pos := Round((FPosition - Min) / (Max - Min) * 100)
  else
    pos := 0;

  //
  if isLinked then
  begin
    if ElementHandle.TagName= 'DIV' then
    begin
      if ElementHandle['role'] = 'progressbar' then
        ElementHandle.style.setProperty('width',inttostr(pos)+'%')
      else
        ElementHandle.innerHTML := inttostr(pos)
    end
    else
      ElementHandle.setAttribute('value',inttostr(pos));

    Exit;
  end;


  if Style = pbstDiv then
  begin
    el := TJSHTMLElement(ElementHandle.firstChild);

    if ElementClassName <> '' then
    begin
      el.setAttribute('class',ElementClassName);
      ElementHandle.removeAttribute('class');
    end;

    el.style.setProperty('height',inttostr(Height)+'px');

    if WidthStyle = ssAbsolute then
      el.style.setProperty('width',inttostr(Width)+'px');

    el := TJSHTMLElement(el.firstChild);
    el.style.setProperty('background-color','#3397FD');

    if ElementBarClassName <> '' then
      el.setAttribute('class',ElementBarClassName);

    el.setAttribute('role','progressbar');
    el.style.setProperty('width',inttostr(pos)+'%');

    case Value of
    pbvPercentage: el.innerHTML := inttostr(pos)+'%';
    pbvAbsolute: el.innerHTML := inttostr(Position);
    pbvNone: el.innerHTML := '&nbsp;';
    end;
  end
  else
  begin
    el := TJSHTMLElement(ElementHandle.firstChild);
    el.style.setProperty('width','100%');
    el.style.setProperty('height','100%');
    pvalue := FPosition - FMin;
    maxvalue := FMax - FMin;
    el['max'] := IntToStr(maxvalue);

    if Style = pbstNormal then
      el['value'] := IntToStr(pvalue)
    else
      el.removeAttribute('value');
  end;
end;

procedure TProgressBar.SetPosition(AValue: integer);
begin
  if (AValue >= Min) and (AValue <= Max) then
  begin
    FPosition := AValue;
    DoUpdate;
  end;
end;

procedure TProgressBar.SetStyle(const Value: TProgressBarStyle);
begin
  if FStyle <> Value then
  begin
    FStyle := Value;

    if Assigned(ElementHandle) then
    begin
      CreateProgressElement(ElementHandle);
      DoUpdate;
    end;
  end;
end;

procedure TProgressBar.SetValue(const Value: TProgressBarValue);
begin
  FValue := Value;
  DoUpdate;
end;

procedure TProgressBar.SetValueColor(const Value: TColor);
begin
  if (FValueColor <> Value) then
  begin
    FValueColor := Value;
    UpdateElementVisual;
  end;
end;

procedure TProgressBar.UpdateElementVisual;
var
  el: TJSHTMLElement;

begin
  inherited;
  if (ValueColor <> clNone) then
  begin
    if Style = pbstDiv then
    begin
      el := TJSHTMLElement(ElementHandle.firstChild);
      el := TJSHTMLElement(el.firstChild);
      el.style.setProperty('background-color',ColorToHTML(ValueColor));
    end
    else
    begin
      el := TJSHTMLElement(ElementHandle.firstChild);
      el.style.setProperty('accent-color',ColorToHTML(ValueColor));
    end;
  end;
end;

{ TWebTreeView }

procedure TTreeView.BeginUpdate;
begin
  inherited;
end;

procedure TTreeView.ClickCheck(ANode: TTreeNode; AState: boolean);
begin
  if Assigned(OnClickCheckBox) then
    OnClickCheckBox(Self, ANode, AState);
end;

procedure TTreeView.ClickRadio(ANode: TTreeNode; AState: boolean);
begin
  if Assigned(OnClickRadio) then
    OnClickRadio(Self, ANode, AState);
end;

function TTreeView.CreateElement: TJSElement;
var
  tn: TTreeNode;
begin
  if (csDesigning in ComponentState) then
  begin
    BeginUpdate;

    tn := Items.Add('<font color="#E5413C" style="font-size:10pt">&#x25FC;</font> Item 1');
    Items.AddChild(tn,'Child Item 1');

    tn := Items.Add('<font color="#F39527" style="font-size:10pt">&#x25FC;</font> Item 2');
    Items.AddChild(tn,'Child Item 2');

    tn := Items.Add('<font color="#BDC6CB" style="font-size:10pt">&#x25FC;</font> Item 3');
    Items.AddChild(tn,'Child Item 3');

    EndUpdate;
  end;

  Result := document.createElement('DIV');
end;

procedure TTreeView.CreateInitialize;
var
  css: string;
begin
  inherited;
  FSelected := nil;
  FItems := CreateNodes;
  FItems.OnChange := DoNodesChanged;
  FItems.OnExpandedChange := DoExpandedChanged;
  TTreeNodesEx(FItems).OnSelectionChange := DoSelectionChanged;

  // remove default bullets
  css := '.TVUL {' +
    ' list-style-type: none;' +
    ' margin: 0;' +
    ' padding-left: 15px;' +
    '}' + #13#10 +

    ' .TVCARET {' +
    '  cursor: pointer;' +
    '  user-select: none;' +
    '}' + #13#10 +

    '.TVCARET::before {' +
    'content: "\25B6";' +
//    'color: black;' +
    'display: inline-block;' +
    'margin-right: 6px;' +
    '}' + #13#10 +

    '.TVCARET-DOWN::before {'+
    'transform: rotate(90deg);'+
    '}'+ #13#10 +

    '.TVNESTED {' +
    '  display: none;' +
    '}' + #13#10 +

    '.TVACTIVE {' +
    '  display: block;' +
    '}' + #13#10 +

    '.TVNODE {' +
    ' padding: 3px;'+
    ' display: inline-block;' +
    '}' + #13#10 +

    '.TVNOCURSOR {' +
    ' cursor: initial;'+
    '}' + #13#10 +

    '.TVSELECTED {' +
    '  background-color: #0000ff;' +
    '  color: #ffffff;' +
    '}';

  AddControlStyle(css);

  if (csDesigning in ComponentState) then
  begin
    Width := 200;
    Height := 200;
  end;
end;

function TTreeView.CreateNodes: TTreeNodes;
begin
  Result := TTreeNodes.Create(Self);
end;

destructor TTreeView.Destroy;
begin
  FItems.Free;
  inherited;
end;

procedure TTreeView.DisableDrag;
  procedure RemoveDraggableAttribute(AElement: TJSElement);
  var
    I: Integer;
  begin
    for I := 0 to AElement.children.length - 1 do
    begin
      if TJSElement(AElement.children[I]).tagName = 'LI' then
      begin
        TJSElement(AElement.children[I]).removeAttribute('draggable');
        RemoveDraggableAttribute(TJSElement(AElement.children[I]));
      end
      else
        RemoveDraggableAttribute(TJSElement(AElement.children[I]));
    end;
  end;
begin
  if not Assigned(Container) then
    Exit;

  RemoveDraggableAttribute(Container);
end;

procedure TTreeView.DoChangeSelection(NewNode: TTreeNode);
var
  Allow: boolean;
begin
  Allow := true;

  if Assigned(OnChanging) then
    OnChanging(Self, NewNode, Allow);

  if Allow then
  begin
    Selected := NewNode;
    if Assigned(OnChange) then
      OnChange(Self, NewNode);
  end;
end;

procedure TTreeView.DoExpandedChanged(Sender: TObject; ANode: TTreeNode);
var
  el, su: TJSElement;
  i: integer;
begin
  if ANode.Count > 0 then
  begin
    el := GetNodeElement(ANode);

    if Assigned(el) and Assigned(el.parentElement) and Assigned(el.parentElement.parentElement) then
    begin
      su := el.parentElement.parentElement.querySelector('.TVNESTED');
      if Assigned(su) then
      begin
        if ANode.Expanded then
          su.classList.add('TVACTIVE')
        else
          su.classList.remove('TVACTIVE');


//        su.classList.toggle('TVACTIVE');
        el.parentElement.classList.toggle('TVCARET-DOWN');
      end;
    end;

    if AutoExpand then
    begin
      for i := 0 to ANode.Count - 1 do
      begin
        if ANode.Item[i].Count > 0 then
        begin
          ANode.Item[i].Expanded := ANode.Expanded;
        end;
      end;
    end;
  end;
end;

procedure TTreeView.DoNodesChanged(Sender: TObject);
begin
  //
end;

procedure TTreeView.DoRenderNode(ANode: TTreeNode; AElement: TJSHTMLElement);
var
  LElementRec: TJSHTMLElementRecord;
begin
  if Assigned(OnRenderNode) then
  begin
    LElementRec.element := AElement;
    OnRenderNode(Self, ANode, LElementRec);
  end;
end;

procedure TTreeView.DoSelectionChanged(Sender: TObject; ANode: TTreeNode;
  IsSelected: boolean);
begin
  if IsSelected then
    Selected := ANode
  else
    Selected := nil;
end;

procedure TTreeView.EnableDrag;
  procedure AddDraggableAttribute(AElement: TJSElement);
  var
    I: Integer;
  begin
    for I := 0 to AElement.children.length - 1 do
    begin
      if TJSElement(AElement.children[I]).tagName = 'LI' then
      begin
        TJSElement(AElement.children[I]).Attrs['draggable'] := 'true';
        AddDraggableAttribute(TJSElement(AElement.children[I]));
      end
      else
        AddDraggableAttribute(TJSElement(AElement.children[I]));
    end;
  end;
begin
  if not Assigned(Container) then
    Exit;

  AddDraggableAttribute(Container);
end;

procedure TTreeView.EndUpdate;
begin
  inherited;

  if not IsUpdating then
  begin
    Selected := nil;
    RenderNodes;
  end;
end;

function TTreeView.FormPrefix: string;
var
  frm: TCustomForm;
begin
  Result := '';
  frm := GetParentForm(Self);
  if Assigned(frm) then
    Result := frm.ClassName;
end;

procedure TTreeView.FullExpand;
var
  i: integer;
begin
  for i := 0 to Items.SiblingCount - 1 do
    Items.Sibling[i].Expand(true);
end;

procedure TTreeView.FullCollapse;
var
  i: integer;
begin
  for i := 0 to Items.SiblingCount - 1 do
    Items.Sibling[i].Collapse(true);
end;

function TTreeView.GetNodeElement(ANode: TTreeNode): TJSHTMLElement;
var
  s: string;
  tn: TTreeNode;
begin
  Result := nil;
  tn := ANode;

  if not Assigned(tn) then
    Exit;

  s := '';

  // generate HTML element ID
  repeat
    if s = '' then
      s := IntToStr(tn.Index)
    else
      s := IntTostr(tn.Index) + '_' + s;

    tn := tn.Parent;

//  until (tn = nil);
  until (not Assigned(tn));

  s := FormPrefix + Name + '_' + s;

  Result := TJSHTMLElement(document.getElementById(s));

  if Assigned(Result) then
  begin
    if Result.classlist.contains('TVCARET') then
      Result := TJSHTMLElement(Result.firstChild);
  end;
end;

function TTreeView.GetNodeFromID(AId: string): TTreeNode;
var
  s: string;
  sl: TStringList;
  n: TTreeNode;
  i,j,e: integer;

begin
  Result := nil;

  s := AID;
  s := Copy(s, Length(FormPrefix + Name) + 2, Length(s));

  if (s <> '') then
  begin
    sl := TStringList.Create;
    sl.Delimiter := '_';
    sl.StrictDelimiter := true;
    sl.DelimitedText := s;

    n := nil;
    for i := 0 to sl.Count - 1 do
    begin
      val(sl.Strings[i], j, e);

      if (i = 0) then
        n  := Items.Sibling[j]
      else
        n := n.Item[j];
    end;
    sl.Free;

    Result := n;
  end;
end;

function TTreeView.GetSelected: TTreeNode;
begin
  Result := FSelected;
end;

function TTreeView.GetTopItem: TTreeNode;
var
  el: TJSHTMLElement;
  tn: TTreeNode;
  h: single;

begin
  Result := nil;

  if Items.SiblingCount = 0 then
    Exit;

  h := ElementHandle.getBoundingClientRect().top;

  tn := Items.Sibling[0];

  while Assigned(tn) do
  begin
    el := GetNodeElement(tn);
    if el.getBoundingClientRect().top >= h then
    begin
      Result := tn;
      break;
    end;

    tn := tn.GetNext;
  end;
end;

function TTreeView.HandleClick(Event: TJSEvent): Boolean;
var
  n: TTreeNode;
  el,chk: TJSHTMLElement;
begin
  Result := true;
  asm
    el = Event.srcElement;
  end;

  while Assigned(el) and (el.tagName <> 'SPAN') do
     el := TJSHTMLElement(el.parentElement);

  if Assigned(el) then
  begin
    if el.hasAttribute('id') then
    begin
      n := GetNodeFromID(el['id']);

      if (n.NodeType in [ntCheckBox, ntRadioButton]) then
      begin
        chk := TJSHTMLElement(el.firstChild);
        if chk.TagName = 'INPUT' then
        begin
          if chk['type'] = 'radio' then
            ClickRadio(n, TJSHTMLInputElement(chk).checked)
          else
            ClickCheck(n, TJSHTMLInputElement(chk).checked);

          n.Checked := TJSHTMLInputElement(chk).checked;
        end;
      end;

      if Assigned(OnClick) then
        OnClick(Self);

      if Assigned(n) then
      begin
        DoChangeSelection(n);

        if Assigned(OnClickNode) then
          OnClickNode(Self, n);
      end;
    end;

  end;
end;

function TTreeView.HandleDblClick(Event: TJSEvent): Boolean;
var
  n: TTreeNode;
  el: TJSElement;
begin
  Result := true;

  asm
    el = Event.srcElement;
  end;

  while Assigned(el) and (el.tagName <> 'SPAN') do
     el := TJSHTMLElement(el.parentElement);

  if Assigned(el) then
  begin
    if not el.hasAttribute('id') then
      el := el.parentElement;

    if el.hasAttribute('id') then
    begin
      n := GetNodeFromID(el['id']);

      if Assigned(OnDblClick) then
        OnDblClick(Self);

      if Assigned(n) and Assigned(OnDblClickNode) then
        OnDblClickNode(Self, n);

      // toggle expanded state on dbl click
      n.Expanded := not n.Expanded;
    end;
  end;

  Event.StopPropagation;
  Result := true;
end;

function TTreeView.HandleNode(Event: TJSEvent): Boolean;
var
  el: TJSElement;
  n: TTreeNode;
  Allow: boolean;
begin
  asm
    el = Event.srcElement;
  end;

  if Assigned(el) and (el.hasAttribute('id')) then
  begin
    n := GetNodeFromID(el['id']);

    Allow := true;

    if n.Expanded then
    begin
      if Assigned(OnCollapsing) then
        OnCollapsing(Self, n, Allow);
    end
    else
    begin
      if Assigned(OnExpanding) then
        OnExpanding(Self, n, Allow);
    end;

    if Allow then
    begin
      n.Expanded := not n.Expanded;

      if n.Expanded then
      begin
        if Assigned(OnExpanded) then
          OnExpanded(Self, n);
      end
      else
      begin
        if Assigned(OnCollapsed) then
          OnCollapsed(Self, n);
      end;
    end;

    DoChangeSelection(n);

    if Assigned(OnClickNode) then
      OnClickNode(Self, n);

    Result := true;
    Event.preventDefault;
  end
  else
  begin
    if Assigned(el) then
    begin
      el := el.parentElement;
      if el.hasAttribute('id') then
      begin
        n := GetNodeFromID(el['id']);
        DoChangeSelection(n);

        if Assigned(OnClickNode) then
          OnClickNode(Self, n);

        Result := true;
        Event.preventDefault;
      end;
    end;
  end;
end;

procedure TTreeView.KeyDown(var Key: Word; Shift: TShiftState);
var
  tn,sn,ln: TTreeNode;
begin
  tn := Selected;

  if (Key = VK_HOME) then
  begin
    if Assigned(Items.Sibling[0]) then
      DoChangeSelection(Items.Sibling[0]);
  end;

  if (Key = VK_END) and (Items.SiblingCount > 0) then
  begin
    tn := Items.Sibling[Items.SiblingCount - 1];

    while tn.Expanded do
    begin
      if tn.Count > 0 then
        tn := tn.Item[tn.Count -1];
    end;

    DoChangeSelection(tn);
  end;

  if (Key = VK_DOWN) then
  begin
    if Assigned(tn) then
    begin
      if tn.Expanded and (tn.Count > 0) then
      begin
        tn := tn.GetFirstChild;
      end
      else
        tn := tn.GetNextSibling;

      if not Assigned(tn) then
      begin
        tn := Selected;
        sn := tn;

        while not Assigned(sn.GetNextSibling) and Assigned(sn.Parent) do
        begin
          sn := sn.Parent;
          if Assigned(sn) then
            ln := sn.GetNextSibling;

          if Assigned(ln) then
          begin
            tn := ln;
          end;
        end;
      end;

      if Assigned(tn) then
        DoChangeSelection(tn);
    end;
  end;

  if (Key = VK_UP) then
  begin
    if Assigned(tn) then
    begin
      tn := tn.GetPrevSibling;
      if Assigned(tn) then
      begin
        sn := tn;
        while Assigned(sn) and sn.Expanded and (sn.Count > 0) do
        begin
          sn := sn.Item[tn.Count - 1];
          if Assigned(sn) then
            tn := sn;
        end;

        DoChangeSelection(tn);
      end
      else
      begin
        tn := Selected.Parent;
        if Assigned(tn) then
          DoChangeSelection(tn);
      end;
    end;
  end;

  if (Key = VK_RIGHT) then
  begin
    if Assigned(tn) then
      Selected.Expanded := true;
  end;

  if (Key = VK_LEFT) then
  begin
    if Assigned(tn) then
      Selected.Expanded := false;
  end;

  if (Key in [VK_LEFT, VK_RIGHT, VK_DOWN, VK_UP]) then
  begin
    Key := 0;
    Exit;
  end;

  inherited;
end;

function TTreeView.RenderControl(ANode: TTreeNode;
  AElementName: string): string;
var
  chk, rname: string;
  l: integer;
begin
  chk := '';
  if ANode.Checked then
    chk := ' checked';

  rname := AElementName;

  l := Length(rname);
  if pos('_',rname)> 0 then
  begin
    while (rname[l] <> '_') do
    begin
      dec(l);
    end;

    rname := Copy(rname,1,l-1);
  end;

  case ANode.NodeType of
  ntCheckBox: Result := '<input type="checkbox"'+ chk +'></input>&nbsp;' + ANode.Text;
  ntRadioButton: Result := '<input type="radio"'+ chk + ' name="'+ rname + '"></input>&nbsp;' + ANode.Text;
  ntText: Result := ANode.Text;
  end;
end;

function TTreeView.RenderNode(ANode: TTreeNode; AElementName: string): TJSHTMLElement;
begin
  Result := TJSHTMLElement(document.createElement('SPAN'));
  if AElementName <> '' then
    Result.setAttribute('id',AElementName);

  Result.innerHTML := RenderControl(ANode, AElementName);

  Result.classlist.add('TVNODE');

  if ElementNodeClassName <> '' then
    Result.classlist.add(ElementNodeClassName);

  if Assigned(Selected) then
  begin
    if Selected = ANode then
    begin
      Result.classlist.add('TVSELECTED');
      if ElementNodeSelectedClassName <> '' then
        Result.classlist.add(ElementNodeSelectedClassName);
    end;
  end;

//  if not Assigned(OnClickNode) and not Assigned(OnDblClickNode) then
//    Result.style.setProperty('pointer-events','none');

  if ANode.Hint <> '' then
    Result.setAttribute('title', ANode.Hint);
end;

procedure TTreeView.RenderNodes;

  procedure RenderChildNode(LinkElement: TJSHTMLElement; ANode: TTreeNode; AElementName: string);
  var
    sp,nd: TJSHTMLElement;
  begin
    sp := TJSHTMLElement(document.createElement('SPAN'));
    if AElementName <> '' then
      sp.setAttribute('id', AElementName);

    sp.addEventListener('click',@HandleNode);
    sp.addEventListener('dblclick',@HandleDblClick);

    nd := TJSHTMLElement(document.createElement('SPAN'));

    if AutoExpand then
      nd.style.setProperty('pointer-events', 'none');

    nd.innerHTML := RenderControl(ANode, AElementName);

    nd.classlist.add('TVNODE');

    if ElementNodeClassName <> '' then
      nd.classlist.add(ElementNodeClassName);

    if not AutoExpand then
      nd.classlist.add('TVNOCURSOR');

    if Assigned(Selected) then
    begin
      if Selected = ANode then
      begin
        nd.classlist.add('TVSELECTED');
        if ElementNodeSelectedClassName <> '' then
          nd.classlist.add(ElementNodeSelectedClassName);
      end;
    end;

    if ANode.Hint <> '' then
      nd.setAttribute('title', ANode.Hint);

    LinkElement.appendChild(sp);
    sp.appendChild(nd);
    if ANode.Expanded then
      sp.setAttribute('class', 'TVCARET TVCARET-DOWN')
    else
      sp.setAttribute('class', 'TVCARET');

    DoRenderNode(ANode, nd);
  end;

  procedure FillChilds(ParentElement: TJSElement; ParentName: string; ANode: TTreeNode);
  var
    i: integer;
    ul: TJSElement;
    nd,li: TJSHTMLElement;
    nn: string;
  begin
    ul := document.createElement('UL');
    ul.setAttribute('class','TVUL');
    ul.setAttribute('id',ParentName);
    if DragMode = dmAutomatic then
      ul.setAttribute('draggable', 'true');
    ParentElement.appendChild(ul);

    if not Assigned(ANode) then
    begin
      // root items
      for i := 0 to FItems.SiblingCount - 1 do
      begin
        nn := ParentName + '_' + inttostr(i);

        li := TJSHTMLElement(document.createElement('LI'));
        if DragMode = dmAutomatic then
          li.setAttribute('draggable', 'true');
        ul.appendChild(li);

        if FItems.Sibling[i].Count > 0 then
        begin
          RenderChildNode(li, FItems.Sibling[i], nn);
          FillChilds(li, nn, FItems.Sibling[i]);
        end
        else
        begin
          nd := RenderNode(FItems.Sibling[i], nn);
          li.appendChild(nd);
          DoRenderNode(FItems.Sibling[i], nd);

          nd.addEventListener('click', @HandleClick);
          nd.addEventListener('dblclick', @HandleDblClick);
        end;
      end;
    end
    else
    begin
      // child items
      if ANode.Expanded then
        ul.setAttribute('class','TVNESTED TVUL TVACTIVE')
      else
        ul.setAttribute('class','TVNESTED TVUL');

      for i := 0 to ANode.Count - 1 do
      begin
        nn := ParentName + '_' + inttostr(i);

        li := TJSHTMLElement(document.createElement('LI'));
        if DragMode = dmAutomatic then
          li.setAttribute('draggable', 'true');
        ul.appendChild(li);

        if ANode.Item[i].Count > 0 then
        begin
          RenderChildNode(li, ANode.Item[i], nn);
          FillChilds(li, nn, ANode.Item[i]);
        end
        else
        begin
          nd := RenderNode(ANode.Item[i], nn);
          li.appendChild(nd);

          DoRenderNode(ANode.Item[i], nd);

          nd.addEventListener('click', @HandleClick);
          nd.addEventListener('dblclick', @HandleDblClick);
        end;
      end;
    end;
  end;

begin
  if not Assigned(ElementHandle) then
    Exit;

  // remove previous tree
  while ElementHandle.childNodes.length > 0 do
    ElementHandle.removeChild(ElementHandle.FirstChild);

  FillChilds(ElementHandle, FormPrefix + Name, nil);
  ElementHandle.style.setProperty('overflow','auto');
end;

procedure TTreeView.Select(const ANode: TTreeNode);
begin
  SetSelected(ANode);
end;

procedure TTreeView.SetElementNodeClassName(const Value: TElementClassName);
begin
  if (FElementNodeClassName <> Value) then
  begin
    FElementNodeClassName := Value;
    UpdateElement;
  end;
end;

procedure TTreeView.SetElementNodeSelectedClassName(
  const Value: TElementClassName);
begin
  if (FElementNodeSelectedClassName <> Value) then
  begin
    FElementNodeSelectedClassName := Value;
    UpdateElement;
  end;
end;

procedure TTreeView.SetItems(const Value: TTreeNodes);
begin
  FItems.Assign(Value);
end;

procedure TTreeView.SetSelected(const Value: TTreeNode);
var
  el: TJSHTMLElement;
begin
  if Assigned(FSelected) then
  begin
    TTreeNodeEx(FSelected).UnSelect;

    el := GetNodeElement(FSelected);
    if Assigned(el) then
    begin
      el.classlist.toggle('TVSELECTED');
      if ElementNodeSelectedClassName <> '' then
        el.classlist.toggle(ElementNodeSelectedClassName);
    end;
  end;

  FSelected := Value;

  if Assigned(FSelected) then
    TTreeNodeEx(FSelected).Select;

  el := GetNodeElement(FSelected);
  if Assigned(el) then
  begin
    el.classlist.toggle('TVSELECTED');
    if ElementNodeSelectedClassName <> '' then
      el.classlist.toggle(ElementNodeSelectedClassName);
  end;
end;

procedure TTreeView.SetTopItem(const Value: TTreeNode);
var
  el: TJSHTMLElement;
  h,ch: single;

begin
  if not Assigned(Value) then
    raise Exception.Create('Value is not assigned');

  el := GetNodeElement(Value);
  h := el.getBoundingClientRect().top + ElementHandle.scrollTop;

  ch := ElementHandle.getBoundingClientRect().top;

  ElementHandle.scrollTop := Round(h - ch);
end;

end.