unit UModuloDatos;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Modules, Data.DB, WEBLib.CDS,
  WEBLib.REST,
   WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, UBase,  WEBLib.DB;

type
  TModuloDatos = class(TDataModule)
      CDUsuario: TClientDataSet;
    CDEntidadEscuela: TClientDataSet;
    CDEntidad: TClientDataSet;
    CDUsuarioID_USUARIOWEB: TIntegerField;
    CDUsuarioID_PERSONA: TLargeintField;
    CDUsuarioDOCUMENTO: TIntegerField;
    CDUsuarioNOMBRE: TStringField;
    CDUsuarioEMAIL: TStringField;
    CDUsuarioAPELLIDOS: TStringField;
    CDUsuarioNOMBRES: TStringField;
    CDUsuarioCELULAR: TStringField;
    CDUsuarioUSUARIO: TStringField;
    CDUsuarioFOTO: TBlobField;
    CDListaAsociados: TClientDataSet;
    CDListaAsociadosDenominacion: TStringField;
    CDListaAsociadosDocumento: TIntegerField;
    CDListaAsociadosIDS: TStringField;
    CDListaAsociadosid: TIntegerField;
    CDListaAsociadoside: TIntegerField;
    CDP: TClientDataSet;
    CDPid: TIntegerField;
    CDPide: TIntegerField;
    CDPidp: TLargeintField;
    CDPids: TStringField;
    ConexionListaAsociados: THttpRequest;
    ConexionRoles: THttpRequest;
    CDRoles: TClientDataSet;
    ConexionEntidad: THttpRequest;
    procedure DataModuleCreate(Sender: TObject);
    procedure ConexionListaAsociadosError(Sender: TObject;
      ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord;
      var Handled: Boolean);
    procedure ConexionListaAsociadosResponse(Sender: TObject;
      AResponse: string);
    procedure ConexionEntidadError(Sender: TObject;
      ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord;
      var Handled: Boolean);
    procedure ConexionRolesError(Sender: TObject;
      ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord;
      var Handled: Boolean);
  private
    FApellidos: string;
    FIDUsuario: integer;
    FNombres: string;
    FIDPersona: int64;
    FNombre: string;
    FUsuario: string;
    FEmail: string;
    FDocumento: string;
    FCelular: string;
    FIDEntidad: integer;
    FHTTP: String;
    FHost: string;
    FPuerto: string;
    FIDS: integer;
    FID: integer;
    FIDE: integer;
    FTrayendoAsociados: boolean;
    FURL: string;
    FESC: integer;
    FJWT: string;
    FIDGrupo: integer;
    FNivelRol: integer;
    FNombrePersona: string;
    FNombrePersonaBuscada: string;
    FIDPersonaBuscada: Int64;
    FIDTipoEntidad: integer;
    FIDRol: integer;
    FNombreRol: string;
    FDireccionWeb: string;
    { Private declarations }
  public
    { Public declarations }
    pa:string;
    property Host:string read FHost write FHost;
    property Puerto:string read FPuerto write FPuerto;
    property HTTP:String read FHTTP write FHTTP;

    property IDUsuario:integer read FIDUsuario write FIDUsuario;
    property Usuario:string read FUsuario write FUsuario;
    property IDPersona:int64 read FIDPersona write FIDPersona;
    property Nombre:string read FNombre write FNombre;
    property Apellidos: string read FApellidos write FApellidos;
    property Nombres: string read FNombres write FNombres;
    property EMail: string read FEmail write FEmail;
    property Documento:string read FDocumento write FDocumento;
    property Celular: string read FCelular write FCelular;

    property IDEntidad : integer read FIDEntidad write FIDEntidad;
    property ID: integer read FID write FID;
    property IDE: integer read FIDE write FIDE;
    property ESC: integer read FESC write FESC;
    property IDS: integer read FIDS write FIDS;
    property JWT: string read FJWT write FJWT;
    property IDGrupo : integer read FIDGrupo write FIDGrupo;
    property NivelRol : integer read FNivelRol write FNivelRol;
    property IDTipoEntidad : integer read FIDTipoEntidad write FIDTipoEntidad;
    property IDRol : integer read FIDRol write FIDRol;
    property NombreRol : string read FNombreRol write FNombreRol;


    property TrayendoAsociados:boolean read FTrayendoAsociados write FTrayendoAsociados;
    procedure TraerAsociados;

    property URL:string read FURL write FURL;

    procedure Conectar(Conexion: THttpRequest; Nivel: integer; Metodo, Data: string);
    function NoLogin(Mensaje: string): boolean;

    property IDPersonaBuscada:Int64 read FIDPersonaBuscada write FIDPersonaBuscada;
    property NombrePersonaBuscada:string read FNombrePersonaBuscada write FNombrePersonaBuscada;

    procedure ConfigurarConexion(Conexion: THttpRequest; Nivel: integer; Metodo, Data: string);

    property DireccionWeb:string read FDireccionWeb write FDireccionWeb;

  protected procedure LoadDFMValues; override; end;

var
  ModuloDatos: TModuloDatos;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

{$R *.dfm}

uses UUtilesWEB;


procedure TModuloDatos.ConfigurarConexion(Conexion: THttpRequest; Nivel: integer; Metodo, Data: string);
var
    Cad:String;
    Mensaje, Mensajej:String;
begin

      if pos('/',Metodo)=1 then
          Conexion.url:=http+host+puerto+Metodo
          else
          Conexion.url:=http+host+puerto+'/'+Metodo;

      Conexion.Command:=httpPOST;

      MensajeJ:=PackMensaje(FJWT,IntToStr(FIDS),IntToStr(FIDE),IntToStr(FIDENTIDAD),IntToStr(FIDEntidad),Data) ;
      Mensaje:=SetVersion(Nivel,MensajeJ);

      Conexion.Headers.Clear;
      Conexion.Headers.AddPair('Content-Type', 'application/json');
      Conexion.postdata:= Mensaje;


end;

procedure TModuloDatos.Conectar(Conexion: THttpRequest; Nivel: integer; Metodo, Data: string);
var
    Cad:String;
    Mensaje, Mensajej:String;
begin

      if pos('/',Metodo)=1 then
          Conexion.url:=http+host+puerto+Metodo
          else
          Conexion.url:=http+host+puerto+'/'+Metodo;

      Conexion.Command:=httpPOST;

      MensajeJ:=PackMensaje(FJWT,IntToStr(FIDS),IntToStr(FIDE),IntToStr(FIDENTIDAD),IntToStr(FIDEntidad),Data) ;
      Mensaje:=SetVersion(Nivel,MensajeJ);

      Conexion.Headers.Clear;
      Conexion.Headers.AddPair('Content-Type', 'application/json');
      Conexion.postdata:= Mensaje;
      Conexion.execute;
end;

procedure TModuloDatos.ConexionEntidadError(Sender: TObject;
  ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord;
  var Handled: Boolean);
begin
  HANDLED:=TRUE;
end;

procedure TModuloDatos.ConexionListaAsociadosError(Sender: TObject;
  ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord;
  var Handled: Boolean);
begin
  HANDLED:=TRUE;
end;

procedure TModuloDatos.ConexionListaAsociadosResponse(Sender: TObject;
  AResponse: string);
begin
           FTrayendoAsociados:=false;
{     if Aresponse='WWW' THEN
          application.navigate('http://localhost:8000/PAdmin/index.html')
          else
}
//             if Aresponse='NOEXISE' then mostrarinfo('No hay asociados')
//               else
//                 if Aresponse='ERROR' then mostrarinfo('Hubo problemas para traer la lista')  else
                  begin
                     if GetClienteFromRequestMVC(ModuloDatos.CDListaASociados, Aresponse) then
                      begin
                      end;
                  end;

end;

procedure TModuloDatos.ConexionRolesError(Sender: TObject;
  ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord;
  var Handled: Boolean);
begin
  HANDLED:=TRUE;

end;

procedure TModuloDatos.DataModuleCreate(Sender: TObject);
begin
    FIDEntidad:=-1;
    FIDUsuario:=-1;
    FJWT:='W';
end;



procedure TModuloDatos.TraerAsociados;
begin

             if FTrayendoAsociados then exit;
             if not cdp.active then cdp.active:=true else cdp.emptydataset;
             cdp.append;
             cdp.fieldbyname('id').asinteger:=ID;
             cdp.fieldbyname('idE').asinteger:=IDE;
             cdp.fieldbyname('idS').asinteger:=modulodatos.IDS;
             cdp.fieldbyname('idP').asinteger:=modulodatos.idpersona;

             cdp.post;


             FTrayendoAsociados:=true;
{             ConexionListaAsociados.url:=http+host+puerto+'/getlistaasociados';
             ConexionListaAsociados.postdata:= packzip(DataSetToJSON(CDP,True,True,False)) ;
             ConexionListaAsociados.execute;
}
             Conectar(ConexionListaAsociados,10,'api/getlistaasociados',DataSetToJSON(CDP,True,1));


end;

function TModuloDatos.NoLogin(Mensaje: string): boolean;
begin
    Result:=False;
    if UpperCase(Mensaje)= 'NOLOGIN' then
      begin
          Result:=True;
      end;
end;

procedure TModuloDatos.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CDUsuario := TClientDataSet.Create(Self);
  CDUsuarioID_USUARIOWEB := TIntegerField.Create(Self);
  CDUsuarioID_PERSONA := TLargeintField.Create(Self);
  CDUsuarioDOCUMENTO := TIntegerField.Create(Self);
  CDUsuarioNOMBRE := TStringField.Create(Self);
  CDUsuarioEMAIL := TStringField.Create(Self);
  CDUsuarioAPELLIDOS := TStringField.Create(Self);
  CDUsuarioNOMBRES := TStringField.Create(Self);
  CDUsuarioCELULAR := TStringField.Create(Self);
  CDUsuarioUSUARIO := TStringField.Create(Self);
  CDUsuarioFOTO := TBlobField.Create(Self);
  CDEntidadEscuela := TClientDataSet.Create(Self);
  CDEntidad := TClientDataSet.Create(Self);
  CDListaAsociados := TClientDataSet.Create(Self);
  CDListaAsociadosDenominacion := TStringField.Create(Self);
  CDListaAsociadosDocumento := TIntegerField.Create(Self);
  CDListaAsociadosIDS := TStringField.Create(Self);
  CDListaAsociadosid := TIntegerField.Create(Self);
  CDListaAsociadoside := TIntegerField.Create(Self);
  CDP := TClientDataSet.Create(Self);
  CDPid := TIntegerField.Create(Self);
  CDPide := TIntegerField.Create(Self);
  CDPidp := TLargeintField.Create(Self);
  CDPids := TStringField.Create(Self);
  ConexionListaAsociados := THttpRequest.Create(Self);
  ConexionRoles := THttpRequest.Create(Self);
  CDRoles := TClientDataSet.Create(Self);
  ConexionEntidad := THttpRequest.Create(Self);

  CDUsuario.BeforeLoadDFMValues;
  CDUsuarioID_USUARIOWEB.BeforeLoadDFMValues;
  CDUsuarioID_PERSONA.BeforeLoadDFMValues;
  CDUsuarioDOCUMENTO.BeforeLoadDFMValues;
  CDUsuarioNOMBRE.BeforeLoadDFMValues;
  CDUsuarioEMAIL.BeforeLoadDFMValues;
  CDUsuarioAPELLIDOS.BeforeLoadDFMValues;
  CDUsuarioNOMBRES.BeforeLoadDFMValues;
  CDUsuarioCELULAR.BeforeLoadDFMValues;
  CDUsuarioUSUARIO.BeforeLoadDFMValues;
  CDUsuarioFOTO.BeforeLoadDFMValues;
  CDEntidadEscuela.BeforeLoadDFMValues;
  CDEntidad.BeforeLoadDFMValues;
  CDListaAsociados.BeforeLoadDFMValues;
  CDListaAsociadosDenominacion.BeforeLoadDFMValues;
  CDListaAsociadosDocumento.BeforeLoadDFMValues;
  CDListaAsociadosIDS.BeforeLoadDFMValues;
  CDListaAsociadosid.BeforeLoadDFMValues;
  CDListaAsociadoside.BeforeLoadDFMValues;
  CDP.BeforeLoadDFMValues;
  CDPid.BeforeLoadDFMValues;
  CDPide.BeforeLoadDFMValues;
  CDPidp.BeforeLoadDFMValues;
  CDPids.BeforeLoadDFMValues;
  ConexionListaAsociados.BeforeLoadDFMValues;
  ConexionRoles.BeforeLoadDFMValues;
  CDRoles.BeforeLoadDFMValues;
  ConexionEntidad.BeforeLoadDFMValues;
  try
    Name := 'ModuloDatos';
    SetEvent(Self, 'OnCreate', 'DataModuleCreate');
    Height := 466;
    Width := 835;
    CDUsuario.SetParentComponent(Self);
    CDUsuario.Name := 'CDUsuario';
    CDUsuario.Left := 78;
    CDUsuario.Top := 36;
    CDUsuarioID_USUARIOWEB.SetParentComponent(CDUsuario);
    CDUsuarioID_USUARIOWEB.Name := 'CDUsuarioID_USUARIOWEB';
    CDUsuarioID_USUARIOWEB.FieldName := 'ID_USUARIOWEB';
    CDUsuarioID_PERSONA.SetParentComponent(CDUsuario);
    CDUsuarioID_PERSONA.Name := 'CDUsuarioID_PERSONA';
    CDUsuarioID_PERSONA.FieldName := 'ID_PERSONA';
    CDUsuarioDOCUMENTO.SetParentComponent(CDUsuario);
    CDUsuarioDOCUMENTO.Name := 'CDUsuarioDOCUMENTO';
    CDUsuarioDOCUMENTO.FieldName := 'DOCUMENTO';
    CDUsuarioNOMBRE.SetParentComponent(CDUsuario);
    CDUsuarioNOMBRE.Name := 'CDUsuarioNOMBRE';
    CDUsuarioNOMBRE.FieldName := 'NOMBRE';
    CDUsuarioNOMBRE.Size := 50;
    CDUsuarioEMAIL.SetParentComponent(CDUsuario);
    CDUsuarioEMAIL.Name := 'CDUsuarioEMAIL';
    CDUsuarioEMAIL.FieldName := 'EMAIL';
    CDUsuarioEMAIL.Size := 50;
    CDUsuarioAPELLIDOS.SetParentComponent(CDUsuario);
    CDUsuarioAPELLIDOS.Name := 'CDUsuarioAPELLIDOS';
    CDUsuarioAPELLIDOS.FieldName := 'APELLIDOS';
    CDUsuarioAPELLIDOS.Size := 50;
    CDUsuarioNOMBRES.SetParentComponent(CDUsuario);
    CDUsuarioNOMBRES.Name := 'CDUsuarioNOMBRES';
    CDUsuarioNOMBRES.FieldName := 'NOMBRES';
    CDUsuarioNOMBRES.Size := 50;
    CDUsuarioCELULAR.SetParentComponent(CDUsuario);
    CDUsuarioCELULAR.Name := 'CDUsuarioCELULAR';
    CDUsuarioCELULAR.FieldName := 'CELULAR';
    CDUsuarioCELULAR.Size := 30;
    CDUsuarioUSUARIO.SetParentComponent(CDUsuario);
    CDUsuarioUSUARIO.Name := 'CDUsuarioUSUARIO';
    CDUsuarioUSUARIO.FieldName := 'USUARIO';
    CDUsuarioUSUARIO.Size := 30;
    CDUsuarioFOTO.SetParentComponent(CDUsuario);
    CDUsuarioFOTO.Name := 'CDUsuarioFOTO';
    CDUsuarioFOTO.FieldName := 'FOTO';
    CDEntidadEscuela.SetParentComponent(Self);
    CDEntidadEscuela.Name := 'CDEntidadEscuela';
    CDEntidadEscuela.Left := 204;
    CDEntidadEscuela.Top := 42;
    CDEntidad.SetParentComponent(Self);
    CDEntidad.Name := 'CDEntidad';
    CDEntidad.Left := 306;
    CDEntidad.Top := 42;
    CDListaAsociados.SetParentComponent(Self);
    CDListaAsociados.Name := 'CDListaAsociados';
    CDListaAsociados.Left := 96;
    CDListaAsociados.Top := 148;
    CDListaAsociadosDenominacion.SetParentComponent(CDListaAsociados);
    CDListaAsociadosDenominacion.Name := 'CDListaAsociadosDenominacion';
    CDListaAsociadosDenominacion.FieldName := 'Denominacion';
    CDListaAsociadosDenominacion.Size := 50;
    CDListaAsociadosDocumento.SetParentComponent(CDListaAsociados);
    CDListaAsociadosDocumento.Name := 'CDListaAsociadosDocumento';
    CDListaAsociadosDocumento.FieldName := 'Documento';
    CDListaAsociadosIDS.SetParentComponent(CDListaAsociados);
    CDListaAsociadosIDS.Name := 'CDListaAsociadosIDS';
    CDListaAsociadosIDS.FieldName := 'IDS';
    CDListaAsociadosIDS.Size := 50;
    CDListaAsociadosid.SetParentComponent(CDListaAsociados);
    CDListaAsociadosid.Name := 'CDListaAsociadosid';
    CDListaAsociadosid.FieldName := 'id';
    CDListaAsociadoside.SetParentComponent(CDListaAsociados);
    CDListaAsociadoside.Name := 'CDListaAsociadoside';
    CDListaAsociadoside.FieldName := 'ide';
    CDP.SetParentComponent(Self);
    CDP.Name := 'CDP';
    CDP.Left := 380;
    CDP.Top := 156;
    CDPid.SetParentComponent(CDP);
    CDPid.Name := 'CDPid';
    CDPid.FieldName := 'id';
    CDPide.SetParentComponent(CDP);
    CDPide.Name := 'CDPide';
    CDPide.FieldName := 'ide';
    CDPidp.SetParentComponent(CDP);
    CDPidp.Name := 'CDPidp';
    CDPidp.FieldName := 'idp';
    CDPids.SetParentComponent(CDP);
    CDPids.Name := 'CDPids';
    CDPids.FieldName := 'ids';
    CDPids.Size := 50;
    ConexionListaAsociados.SetParentComponent(Self);
    ConexionListaAsociados.Name := 'ConexionListaAsociados';
    ConexionListaAsociados.Command := httpPOST;
    ConexionListaAsociados.Headers.BeginUpdate;
    try
      ConexionListaAsociados.Headers.Clear;
      ConexionListaAsociados.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      ConexionListaAsociados.Headers.EndUpdate;
    end;
    SetEvent(ConexionListaAsociados, Self, 'OnError', 'ConexionListaAsociadosError');
    SetEvent(ConexionListaAsociados, Self, 'OnResponse', 'ConexionListaAsociadosResponse');
    ConexionListaAsociados.Left := 566;
    ConexionListaAsociados.Top := 30;
    ConexionRoles.SetParentComponent(Self);
    ConexionRoles.Name := 'ConexionRoles';
    ConexionRoles.Command := httpPOST;
    ConexionRoles.Headers.BeginUpdate;
    try
      ConexionRoles.Headers.Clear;
      ConexionRoles.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      ConexionRoles.Headers.EndUpdate;
    end;
    SetEvent(ConexionRoles, Self, 'OnError', 'ConexionRolesError');
    ConexionRoles.Left := 638;
    ConexionRoles.Top := 154;
    CDRoles.SetParentComponent(Self);
    CDRoles.Name := 'CDRoles';
    CDRoles.Left := 528;
    CDRoles.Top := 152;
    ConexionEntidad.SetParentComponent(Self);
    ConexionEntidad.Name := 'ConexionEntidad';
    ConexionEntidad.Command := httpPOST;
    ConexionEntidad.Headers.BeginUpdate;
    try
      ConexionEntidad.Headers.Clear;
      ConexionEntidad.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      ConexionEntidad.Headers.EndUpdate;
    end;
    SetEvent(ConexionEntidad, Self, 'OnError', 'ConexionEntidadError');
    ConexionEntidad.Left := 312;
    ConexionEntidad.Top := 322;
  finally
    CDUsuario.AfterLoadDFMValues;
    CDUsuarioID_USUARIOWEB.AfterLoadDFMValues;
    CDUsuarioID_PERSONA.AfterLoadDFMValues;
    CDUsuarioDOCUMENTO.AfterLoadDFMValues;
    CDUsuarioNOMBRE.AfterLoadDFMValues;
    CDUsuarioEMAIL.AfterLoadDFMValues;
    CDUsuarioAPELLIDOS.AfterLoadDFMValues;
    CDUsuarioNOMBRES.AfterLoadDFMValues;
    CDUsuarioCELULAR.AfterLoadDFMValues;
    CDUsuarioUSUARIO.AfterLoadDFMValues;
    CDUsuarioFOTO.AfterLoadDFMValues;
    CDEntidadEscuela.AfterLoadDFMValues;
    CDEntidad.AfterLoadDFMValues;
    CDListaAsociados.AfterLoadDFMValues;
    CDListaAsociadosDenominacion.AfterLoadDFMValues;
    CDListaAsociadosDocumento.AfterLoadDFMValues;
    CDListaAsociadosIDS.AfterLoadDFMValues;
    CDListaAsociadosid.AfterLoadDFMValues;
    CDListaAsociadoside.AfterLoadDFMValues;
    CDP.AfterLoadDFMValues;
    CDPid.AfterLoadDFMValues;
    CDPide.AfterLoadDFMValues;
    CDPidp.AfterLoadDFMValues;
    CDPids.AfterLoadDFMValues;
    ConexionListaAsociados.AfterLoadDFMValues;
    ConexionRoles.AfterLoadDFMValues;
    CDRoles.AfterLoadDFMValues;
    ConexionEntidad.AfterLoadDFMValues;
  end;
end;

end.


