unit UEstaSeguro;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, UBase, WEBLib.REST, Data.DB, WEBLib.DB,
  WEBLib.CDS, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  Vcl.Imaging.GIFImg, Vcl.Controls, Vcl.Forms;

type
  TEstaSeguro = class(TBase)
    BotonAceptar: TButton;
    BotonCancelar: TButton;
    Titulo: TLabel;
    Mensaje: TLabel;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  EstaSeguro: TEstaSeguro;

implementation

{$R *.dfm}

procedure TEstaSeguro.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Titulo := TLabel.Create('IDTitulo');
  Mensaje := TLabel.Create('Texto');
  BotonAceptar := TButton.Create('BotonAceptar');
  BotonCancelar := TButton.Create('BotonCancelar');

  Titulo.BeforeLoadDFMValues;
  Mensaje.BeforeLoadDFMValues;
  MensajeEspera.BeforeLoadDFMValues;
  BotonEfectos.BeforeLoadDFMValues;
  BotonAceptar.BeforeLoadDFMValues;
  BotonCancelar.BeforeLoadDFMValues;
  try
    Titulo.SetParentComponent(Self);
    Titulo.Name := 'Titulo';
    Titulo.Left := 456;
    Titulo.Top := 360;
    Titulo.Width := 65;
    Titulo.Height := 15;
    Titulo.Caption := 'Informaci'#243'n';
    Titulo.HeightPercent := 100.000000000000000000;
    Titulo.WidthPercent := 100.000000000000000000;
    Mensaje.SetParentComponent(Self);
    Mensaje.Name := 'Mensaje';
    Mensaje.Left := 456;
    Mensaje.Top := 392;
    Mensaje.Width := 44;
    Mensaje.Height := 15;
    Mensaje.Caption := 'Mensaje';
    Mensaje.HeightPercent := 100.000000000000000000;
    Mensaje.WidthPercent := 100.000000000000000000;
    MensajeEspera.Picture.LoadFromFile('UEstaSeguro.MensajeEspera.Picture.gif');
    BotonEfectos.Left := 320;
    BotonEfectos.Top := 356;
    BotonAceptar.SetParentComponent(Self);
    BotonAceptar.Name := 'BotonAceptar';
    BotonAceptar.Left := 416;
    BotonAceptar.Top := 480;
    BotonAceptar.Width := 96;
    BotonAceptar.Height := 25;
    BotonAceptar.Caption := 'Aceptar';
    BotonAceptar.ChildOrder := 4;
    BotonAceptar.HeightPercent := 100.000000000000000000;
    BotonAceptar.WidthPercent := 100.000000000000000000;
    BotonCancelar.SetParentComponent(Self);
    BotonCancelar.Name := 'BotonCancelar';
    BotonCancelar.Left := 584;
    BotonCancelar.Top := 480;
    BotonCancelar.Width := 96;
    BotonCancelar.Height := 25;
    BotonCancelar.Caption := 'Cancelar';
    BotonCancelar.ChildOrder := 4;
    BotonCancelar.HeightPercent := 100.000000000000000000;
    BotonCancelar.WidthPercent := 100.000000000000000000;
  finally
    Titulo.AfterLoadDFMValues;
    Mensaje.AfterLoadDFMValues;
    MensajeEspera.AfterLoadDFMValues;
    BotonEfectos.AfterLoadDFMValues;
    BotonAceptar.AfterLoadDFMValues;
    BotonCancelar.AfterLoadDFMValues;
  end;
end;

end.
